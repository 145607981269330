<template>
  <div class="ranking__table">
    <div class="ranking__head">
      <div>Coppia</div>
      <div>Sconto</div>
      <div>
        Buono<br />
        acquisto
      </div>
      <div>
        Totale<br />
        ommepoint
      </div>
    </div>

    <div class="ranking__body" v-if="torneoSogno">
      <div
        v-for="posizione in torneoSogno"
        :key="posizione"
        class="ranking__row"
      >
        <div class="ranking__position">
          {{ torneoSogno.indexOf(posizione) + 1 }}
        </div>
        <div class="ranking__couple">
          <img class="ranking__couple--thumb" :src="posizione.fotoProfilo" />

          <h3 class="ranking__couple--name">
            {{ posizione.nomeUtente }}
          </h3>
        </div>
        <div class="ranking__discount">
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 1">300€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 2">200€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 3">150€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 4">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 5">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 6">70€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 7">70€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 8">50€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 9">50€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 10">50€</div>
        </div>
        <div class="ranking__tag">
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 1">200€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 2">200€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 3">180€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 4">180€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 5">160€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 6">16€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 7">140€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 8">140€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 9">120€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 10">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 11">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 12">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 13">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 14">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 15">100€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 16">50€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 17">50€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 18">50€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 19">50€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 20">50€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 21">30€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 22">30€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 23">30€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 24">30€</div>
          <div v-if="torneoSogno.indexOf(posizione) + 1 === 25">30€</div>
        </div>
        <div class="ranking__ommepoint">
          <div class="ommepoints">{{ posizione.opTotal }}</div>
        </div>
      </div>

      <div class="ranking__row ranking__row--current">
        <div class="ranking__position">
          <span v-for="posizione in torneoSogno" :key="posizione">
            <span v-if="posizione.id === utenteId">
              {{ torneoSogno.indexOf(posizione) + 1 }}
            </span>
          </span>
        </div>
        <div class="ranking__couple">
          <img class="ranking__couple--thumb" :src="utente.fotoProfilo" />

          <h3 class="ranking__couple--name">
            {{ utente.nomeUtente }}
          </h3>
        </div>
        <span v-for="posizione in torneoSogno" :key="posizione">
          <span v-if="posizione.id === utenteId">
            <div class="ranking__discount">
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 1">300€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 2">200€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 3">150€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 4">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 5">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 6">70€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 7">70€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 8">50€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 9">50€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 10">50€</div>
            </div>
            <div class="ranking__tag">
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 1">200€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 2">200€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 3">180€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 4">180€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 5">160€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 6">160€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 7">140€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 8">140€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 9">120€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 10">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 11">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 12">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 13">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 14">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 15">100€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 16">50€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 17">50€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 18">50€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 19">50€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 20">50€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 21">30€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 22">30€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 23">30€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 24">30€</div>
              <div v-if="torneoSogno.indexOf(posizione) + 1 === 25">30€</div>
            </div>
          </span>
        </span>
        <div class="ranking__ommepoint">
          <div class="ommepoints">{{ utente.opTotal }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'

export default {
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { documents: torneoSogno } = getCollection(
      'utenti',
      ['torneo', '==', "sogno d'estate"],
      'opTotal',
      'desc'
    )

    const utenteId = user.value.uid

    return { torneoSogno, utente, utenteId }
  },
}
</script>

<style></style>
