<template>
  <div>
    <Bg />

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <div v-if="utente">
      <!-- PIGGYBANK BUTTON -->
      <section class="piggybank-link">
        <router-link :to="{ name: 'Piggybank' }" class="piggybank-btn">
          <div class="piggybank-btn__left">
            <img src="@/assets/images/icons/icon-piggybank.svg" />
          </div>

          <div class="piggybank-btn__center">{{ utente.opTotal }}</div>

          <div class="piggybank-btn__right">
            <i class="bx bx-right-arrow-alt"></i>
          </div>
        </router-link>
      </section>

      <!-- BOX -->
      <section class="box">
        <h2 class="box__heading">Servizi acquistati da contratto</h2>

        <div class="box__content">
          <ul class="box__list">
            <li
              class="box__service"
              v-for="servizio in utente.contratto"
              :key="servizio"
            >
              <h3 class="box__service--name">{{ servizio }}</h3>
            </li>
          </ul>
          <div v-if="!utente.contratto.length">Dati in aggiornamento</div>
        </div>
      </section>

      <!-- BOX -->
      <section class="box">
        <h2 class="box__heading">Servizi aggiunti con lo shopping</h2>

        <div class="box__content">
          <ul class="box__list">
            <li
              class="box__service"
              v-for="servizio in utente.shop"
              :key="servizio"
            >
              <h3 class="box__service--name">{{ servizio }}</h3>
            </li>
          </ul>
          <div v-if="!utente.shop.length">Nessun servizio aggiunto</div>
        </div>
      </section>

      <!-- BOX -->
      <section class="box">
        <h2 class="box__heading">Buoni acquisto accumulati <span>*</span></h2>

        <div class="box__content">
          <div class="tickets">
            <div
              class="ticket ticket--red"
              v-for="buono in utente.buoni"
              :key="buono"
            >
              <div class="ticket__value">{{ buono }}€</div>

              <div class="ticket__desc">Utilizzabile su un solo servizio</div>
            </div>
          </div>

          <div v-if="!utente.buoni.length">
            Non hai ancora accumulato buoni d'acquisto<br /><br />
          </div>

          <div class="box__legend">
            <span>*</span>
            Ciascun buono è valido per l’acquisto di un solo servizio e
            applicabile sui prodotti OMME Music, Ampliando.it o MediaPics.it. Il
            valore dei buoni utilizzati non potrà superare il 50% del valore del
            servizio che deciderete di acquistare.
          </div>
        </div>
      </section>

      <!-- BOX -->
      <section class="box">
        <h2 class="box__heading">Sconti accumulati <span>*</span></h2>

        <div class="box__content">
          <div class="tags">
            <div
              class="tag tag--blue"
              v-for="sconto in utente.sconti"
              :key="sconto"
            >
              <div class="tag__value">{{ sconto }}€</div>
            </div>
          </div>

          <div v-if="!utente.sconti.length">
            Non hai ancora accumulato sconti<br /><br />
          </div>

          <div class="box__legend">
            <span>*</span>
            La somma degli sconti sarà applicata sul totale dei servizi OMME
            Music, Ampliando.it o MediaPics.it acquistati.
          </div>
        </div>
      </section>

      <!-- BOX -->
      <section class="box">
        <h2 class="box__heading">Forzieri acquistati</h2>

        <div class="box__content">
          <strong v-if="utente.forzieri.length"
            >Forzieri acquistati nel puzzle bonus</strong
          >
          <ul class="forzieri" v-if="utente">
            <span v-for="forziere in forzieri" :key="forziere">
              <span v-for="coffer in utente.forzieri" :key="coffer">
                <li class="forziere" v-if="coffer == forziere.id">
                  <span
                    class="forziere__ommepoints"
                    v-for="fascia in fasce"
                    :key="fascia"
                  >
                    <span
                      v-if="
                        utente.opStart >= fascia.opStart.min &&
                          utente.opStart <= fascia.opStart.max
                      "
                    >
                      <span
                        class="ommepoints"
                        ref="forziereOp"
                        v-if="
                          forziere.id === 'bronzo-4' ||
                            forziere.id === 'argento-10' ||
                            forziere.id === 'oro-10'
                        "
                      >
                        {{ fascia.forzieri[0] }}
                      </span>

                      <span
                        class="ommepoints"
                        ref="forziereOp"
                        v-if="
                          forziere.id === 'bronzo-8' ||
                            forziere.id === 'argento-12' ||
                            forziere.id === 'oro-15'
                        "
                      >
                        {{ fascia.forzieri[1] }}
                      </span>

                      <span
                        class="ommepoints"
                        ref="forziereOp"
                        v-if="
                          forziere.id === 'bronzo-10' ||
                            forziere.id === 'argento-15' ||
                            forziere.id === 'oro-20'
                        "
                      >
                        {{ fascia.forzieri[2] }}
                      </span>
                    </span>
                  </span>

                  <img
                    v-if="
                      forziere.id === 'bronzo-4' ||
                        forziere.id === 'bronzo-8' ||
                        forziere.id === 'bronzo-10'
                    "
                    class="forziere__icon"
                    src="@/assets/images/icons/icon-coffer-bronze.svg"
                  />

                  <img
                    v-if="
                      forziere.id === 'argento-10' ||
                        forziere.id === 'argento-12' ||
                        forziere.id === 'argento-15'
                    "
                    class="forziere__icon"
                    src="@/assets/images/icons/icon-coffer-silver.svg"
                  />

                  <img
                    v-if="
                      forziere.id === 'oro-10' ||
                        forziere.id === 'oro-15' ||
                        forziere.id === 'oro-20'
                    "
                    class="forziere__icon"
                    src="@/assets/images/icons/icon-coffer-gold.svg"
                  />

                  <span class="forziere__prezzo">{{ forziere.prezzo }}€</span>
                </li>
              </span>
            </span>
          </ul>

          <strong v-if="utente.forzieriShop.length"
            >Forzieri acquistati nel shop dei forzieri</strong
          >
          <ul class="forzieri" v-if="utente">
            <span v-for="forziere in forzieriShop" :key="forziere">
              <span v-for="coffer in utente.forzieriShop" :key="coffer">
                <li class="forziere" v-if="coffer == forziere.id">
                  <span class="forziere__ommepoints">
                    <span class="ommepoints">{{ forziere.op }}</span>
                  </span>

                  <img
                    class="forziere__icon"
                    src="@/assets/images/icons/icon-coffer-bronze.svg"
                  />

                  <span class="forziere__prezzo">{{ forziere.prezzo }}€</span>
                </li>
              </span>
            </span>
          </ul>

          <div v-if="!utente.forzieri.length">
            <p>Non hai ancora acquistato forzieri</p>

            <router-link :to="{ name: 'CoffersShop' }" class="btn btn--primary"
              >Acquista ora!</router-link
            >
            <br />
          </div>

          <div class="box__legend">
            N. B: I pagamenti dei forzieri saranno effettuati a saldo, nel corso
            dell’appuntamento scaletta con il vostro artista di riferimento.
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { err, document: tutorial } = getDocument(
      'tutorial',
      'servizi-sconti'
    )

    const { documents: forzieri } = getCollection('forzieri', '', 'prezzo')
    const { documents: forzieriShop } = getCollection(
      'forzieri-shop',
      '',
      'prezzo'
    )

    const { documents: fasce } = getCollection(
      'fasce-prezzo',
      '',
      'opStart.min'
    )

    return { utente, error, tutorial, forzieri, forzieriShop, fasce }
  },
}
</script>

<style lang="scss" scoped>
.forzieri {
  display: flex;
  justify-content: space-between;
  list-style: none;
  flex-wrap: wrap;

  span span:empty {
    display: none;
  }
}

.forziere {
  align-items: center;
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  min-width: 137px;
  padding-top: 3rem;

  &__ommepoints {
    margin-bottom: 1.5rem;
  }

  &__icon {
    height: 6rem;
    margin-bottom: 3rem;
  }

  &__prezzo {
    background-color: #0cb;
    border-radius: 0 0 2px 2px;
    display: flex;
    font-weight: bold;
    line-height: 1;
    justify-content: center;
    padding: 0.7rem;
    width: 100%;
  }
}
</style>
