<template>
  <div>
    <Bg />

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <div class="panel">
      <div class="conditions">
        <div>
          <p class="c1 c9"><span class="c13"></span></p>
        </div>

        <p class="c0">
          <span class="c2"
            >Le presenti condizioni gestiscono l&#39;utilizzo dell&rsquo;Area
            Sposi di ommemusic.it (di seguito indicata come Area Sposi)&nbsp;e
            l&rsquo;erogazione dei servizi forniti attraverso il portale stesso.
            Utilizzando&nbsp;l&rsquo;Area Sposi sia come visitatore che come
            utente registrato o come iscritto, il Cliente accetta completamente
            queste condizioni d&#39;uso senza riserve.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >L&#39;operatore ed il gestore del sito&nbsp;ommemusic.it ed il
            fornitore di servizi &egrave; OMME Music di Mastrapasqua Leonardo
            (di seguito indicata come OMME Music), i cui dati sono contenuti
            nelle note legali di questo sito web.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Acconsentendo alla creazione del proprio profilo nell&rsquo;Area
            Sposi (effettuata a carico di OMME Music) ed all&rsquo;utilizzo i
            suoi servizi, il Cliente acconsente al trattamento dei dati nei
            sensi e nei limiti del D.L. 193/03 e del Reg.EU 679/16 in materia
            della protezione dei dati.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >L&#39;utilizzo dell&rsquo;Area Sposi &egrave; gratuito.</span
          >
        </p>

        <p class="c0"><span class="c4">1. Oggetto del servizio</span></p>
        <p class="c0">
          <span class="c2"
            >L&rsquo;Area Sposi di OMME Music offre una piattaforma in cui ogni
            Cliente potr&agrave;:</span
          >
        </p>
        <ul class="c7 lst-kix_list_2-0 start">
          <li class="c0 c14 li-bullet-0">
            <span class="c2"
              >Accedere ad un sunto del contratto stipulato con OMME Music</span
            >
          </li>
          <li class="c0 c14 li-bullet-0">
            <span class="c2"
              >Compilare il proprio Repertorio Musicale del giorno del proprio
              matrimonio</span
            >
          </li>
          <li class="c0 c14 li-bullet-0">
            <span class="c2"
              >Prenotare servizi aggiuntivi da aggiungere al proprio
              Contratto</span
            >
          </li>
          <li class="c0 c14 li-bullet-0">
            <span class="c2"
              >Vincere OmmePoint (v. Punto 1.1) tramite Giochi e Missioni (v.
              Punto 1.2.1)</span
            >
          </li>
          <li class="c0 c14 li-bullet-0">
            <span class="c2"
              >Partecipare al Torneo stagionale (v. Punto 1.3)</span
            >
          </li>
        </ul>

        <p class="c0"><span class="c10">1.1 OmmePoint</span></p>
        <p class="c0">
          <span class="c2"
            >L&rsquo;Area Sposi permetter&agrave; ai Clienti di poter svolgere
            delle missioni, dei tornei e di accumulare punti definiti come
            OmmePoint.<br />Ogni Cliente, all&rsquo;apertura del proprio profilo
            avr&agrave; diritto ad un numero di OmmePoint iniziale. Il saldo di
            OmmePoint iniziale verr&agrave; definito in base al valore del
            contratto firmato con OMME Music relativo ai servizi di
            Musica/Intrattenimento e/o ai servizi di Foto/Video.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Per ogni Euro definito e concordato in fase di contratto, verranno
            forniti 2 (due) OmmePoint. Questo significa, per esempio, che se il
            Cliente ha firmato per il proprio matrimonio un contratto di servizi
            di Musica/Intrattenimento e/o di Foto/Video del valore di
            1000,00&euro; (mille euro), il saldo iniziale di OmmePoint
            sar&agrave; pari a 2000 (duemila).</span
          >
        </p>

        <p class="c0">
          <span class="c10"
            >1.2 Incremento del proprio numero di OmmePoint</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >L&rsquo;Area Sposi d&agrave; ad ogni Cliente la possibilit&agrave;
            di poter incrementare il proprio ammontare di OmmePoint</span
          >
        </p>
        <ul class="c7 lst-kix_list_3-0 start">
          <li class="c0 c6 li-bullet-1">
            <span class="c2"
              >tramite lo svolgimento di Missioni (punto 1.2.1)</span
            >
          </li>
          <li class="c0 c6 li-bullet-1">
            <span class="c2"
              >tramite lo svolgimento del Puzzle dei Bonus (punto 1.2.2)</span
            >
          </li>
          <li class="c0 c6 li-bullet-1">
            <span class="c2"
              >tramite l&rsquo;acquisto di Forzieri (punto 1.2.3)</span
            >
          </li>
          <li class="c0 c6 li-bullet-2">
            <span class="c2"
              >acquistando servizi aggiuntivi per il proprio matrimonio nella
              sezione OMME Shop (punto 2)</span
            >
          </li>
        </ul>

        <p class="c0"><span class="c3">1.2.1 Missioni</span></p>
        <p class="c0">
          <span class="c2 c5"
            >La sezione Missioni d&agrave; al Cliente la possibilit&agrave; di
            incrementare il proprio &ldquo;saldo&rdquo; di OmmePoint. Ogni
            missione avr&agrave; un valore in OmmePoint. Per conquistare ogni
            singola missione sar&agrave; necessario completare 3 (tre) step
            definiti come &ldquo;Carte&rdquo;:</span
          >
        </p>
        <p class="c0">
          <span class="c2 c5"
            >- Una carta SOCIAL, che pu&ograve; essere conquistata tramite
            azioni social con Facebook o Instagram.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c5"
            >- Una carta ACTION, che pu&ograve; essere conquistata con
            un&#39;azione specifica come, per esempio, la compilazione del
            repertorio musicale, o l&#39;invio delle Gift Card o dei biglietti
            da visita, e cos&igrave; via.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c5"
            >- Una carta SHOPPING, che pu&ograve; essere conquistata aggiungendo
            al contratto un servizio di Musica/Intrattenimento non ancora
            compreso nel proprio Contratto iniziale.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >Quando tutte e tre le carte saranno conquistate, il Cliente
            guadagner&agrave; il corrispettivo di OmmePoint della missione
            appena compiuta e che andr&agrave; a sommarsi al proprio saldo
            punti.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >Ogni Carta (che sia Social, Action o Shopping) detiene un proprio
            valore di OmmePoint che, se il Cliente la conquister&agrave;,
            sar&agrave; comunque guadagnato a prescindere dal completamento
            della Missione.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >Esempio:<br />Valore missione: 1000 OmmePoint<br />Valore Carte:
            900 OmmePoint di cui</span
          >
        </p>
        <ul class="c7 lst-kix_list_3-0">
          <li class="c0 c6 li-bullet-1">
            <span class="c2 c5">100 OmmePoint per la Carta Social</span>
          </li>
          <li class="c0 c6 li-bullet-1">
            <span class="c2 c5">200 OmmePoint per la Carta Action</span>
          </li>
          <li class="c0 c6 li-bullet-1">
            <span class="c2 c5">300 OmmePoint per la Carta Shopping</span>
          </li>
        </ul>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >Conquistando ogni carta il Cliente ne guadagner&agrave; il singolo
            valore (per esempio &nbsp;100 / 200 / 300 secondo il tipo di carta
            conquistata).</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >Se il Cliente guadagner&agrave; tutte e tre le Carte relativa alla
            Missione, guadagner&agrave; il valore di ogni singola Carta
            (100+200+300) e, in aggiunta, il valore della Missione stessa
            (1000), per un totale di 1600 OmmePoint.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >(I valori indicati nell&rsquo;esempio sopra riportato sono
            puramente indicativi e non rispecchiano i valori effettivi presenti
            nell&rsquo;Area Riservata)</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0"><span class="c3 c5">1.2.2 Puzzle dei Bonus</span></p>
        <p class="c0">
          <span class="c2 c5"
            >Il Cliente avr&agrave; la possibilit&agrave; di accedere
            all&rsquo;Area &ldquo;Puzzle dei Bonus&rdquo; in cui potr&agrave;
            collezionare pezzi di puzzle.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c5"
            >Ogni pezzo di puzzle verr&agrave; conquistato tramite il
            raggiungimento di un obiettivo specifico (come la conquista di tutte
            le Carte Social, o di tutte le Carte Action, &nbsp;tramite
            l&rsquo;acquisto di un Forziere (v. Punto 1.2.3), o tramite il
            completamento di una missione.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c5"
            >Alcuni pezzi di puzzle faranno guadagnare degli sconti, che
            andranno a scalare dal saldo finale del Contratto firmato.<br />Se
            il Cliente conquister&agrave; tutti i pezzi di puzzle, si
            aggiudicher&agrave; il premio (composto da un dato numero di
            OmmePoint che si aggiunger&agrave; al suo saldo), da un ulteriore
            buono sconto e dall&rsquo;ingrandimento di una fotografia della
            coppia (a scelta dagli sposi) e che OMME Music regaler&agrave; e
            spedir&agrave; al Cliente.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0"><span class="c3 c5">1.2.3 Forzieri</span></p>
        <p class="c0">
          <span class="c2 c5"
            >Il Cliente avr&agrave; la possibilit&agrave; di acquistare un
            determinato numero di OmmePoint a sua scelta tramite la sezione
            &ldquo;Forzieri&rdquo;.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c5"
            >Ogni Forziere incrementer&agrave; il numero di OmmePoint del
            Cliente immediatamente al momento della conferma di acquisto.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >L&rsquo;acquisto dei forzieri non avverr&agrave; online, ma
            &nbsp;il loro costo verr&agrave; aggiunto al Contratto e il
            pagamento verr&agrave; perfezionato al saldo.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >L&rsquo;acquisto del forziere pu&ograve; essere effettuato in
            qualsiasi momento da parte del Cliente oppure pu&ograve; essere
            vincolato alla conquista di un obiettivo della sezione Puzzle dei
            Bonus.</span
          >
        </p>
        <p class="c0 c1"><span class="c3 c5"></span></p>
        <p class="c0"><span class="c10">1.3 Tornei stagionali</span></p>
        <p class="c0">
          <span class="c2"
            >Nell&rsquo;Area Sposi, verr&agrave; istituito un Torneo specifico
            in cui parteciperanno le coppie di sposi che hanno sottoscritto un
            contratto con OMME Music.<br
          /></span>
        </p>
        <p class="c0">
          <span class="c2"
            >Il torneo sar&agrave; trimestrale, e cos&igrave; suddiviso:</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Favola di Primavera: in cui parteciperanno tutti le coppie che si
            sposeranno nei mesi di Maggio, Giugno e Luglio 2021. Inizio torneo:
            alla creazione del profilo nell&rsquo;Area Sposi. Termine torneo: 30
            Aprile 2021.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Vento d&rsquo;Autunno: in cui parteciperanno tutte le coppie che si
            sposeranno nei mesi di Agosto, Settembre e Ottobre 2021. Inizio
            torneo: alla creazione del profilo nell&rsquo;Area Sposi. Termine
            torneo: 31 Luglio 2021.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c2 c5"
            >Grazie agli OmmePoint accumulati durante il torneo e tramite
            l&rsquo;utilizzo dell&rsquo;Area Sposi, il Cliente potr&agrave;
            scalare le posizioni in classifica.<br />Alla data di chiusura del
            torneo verr&agrave; stilata la classifica definitiva di tutte le
            coppie partecipanti ed a cui verranno assegnati premi (sconti e
            buoni d&rsquo;acquisto) in base alla posizione in cui il Cliente si
            colloca.</span
          >
        </p>
        <p class="c0 c1"><span class="c2 c5"></span></p>
        <p class="c0">
          <span class="c10 c5">1.4 Piramide degli Obiettivi</span>
        </p>
        <p class="c0">
          <span class="c2"
            >Ogni Cliente avr&agrave; la possibilit&agrave; di accumulare sconti
            e buoni di acquisto anche raggiungendo gli obiettivi presenti nella
            sezione &ldquo;Piramide degli Obiettivi&rdquo;.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Ogni Cliente avr&agrave; la possibilit&agrave; di scalare la
            piramide raggiungendo tutti gli obiettivi presenti e che saranno
            calibrati sulla base del valore in OmmePoint del suo contratto
            iniziale di Musica/Intrattenimento.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Al raggiungimento di uno specifico obiettivo, il Cliente
            guadagner&agrave; anche il premio corrispondente (costituito da
            sconti o buoni di acquisto).</span
          >
        </p>

        <p class="c0"><span class="c4">2. OMME Shop</span></p>
        <p class="c0">
          <span class="c2"
            >Oltre alle Carte Shopping, il Cliente avr&agrave; la
            possibilit&agrave; di aggiungere servizi di Musica/Intrattenimento
            tramite la sezione OMME Shop e in cui saranno presenti tutti i
            servizi di Musica/Intrattenimento del listino OMME Music.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Il Cliente potr&agrave; selezionare uno o pi&ugrave; servizi che si
            andranno ad aggiungere al Contratto ed al saldo finale da
            corrispondere nei tempi e nei termini concordati in fase di
            stipula.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Ad ogni servizio aggiunto tramite la sezione OMME Shop
            corrisponder&agrave; anche un dato valore di OmmePoint che si
            andr&agrave; aggiungere al saldo punti del Cliente.</span
          >
        </p>

        <p class="c0"><span class="c4">3. Sconti</span></p>
        <p class="c0">
          <span class="c2"
            >Tutti gli sconti conquistati tramite l&rsquo;Area Sposi saranno
            detratti dal saldo finale del Contratto che il Cliente dovr&agrave;
            corrispondere a OMME Music.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Gli sconti non potranno essere convertiti in denaro e potranno
            essere applicati sul Contratto di Musica/Intrattenimento stipulato o
            su altri servizi offerti da OMME Music, &nbsp;MediaPics e
            Ampliando.it e avranno una validit&agrave; di 18 mesi dalla data di
            emissione.</span
          >
        </p>

        <p class="c0"><span class="c4">4. Buoni di acquisto</span></p>
        <p class="c0">
          <span class="c2"
            >Tutti gli buoni di acquisto conquistati tramite l&rsquo;Area Sposi
            saranno detratti dal saldo finale del Contratto che il Cliente
            dovr&agrave; corrispondere a OMME Music.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Gli sconti non potranno essere convertiti in denaro e potranno
            essere applicati sul Contratto di Musica/Intrattenimento stipulato o
            su altri servizi offerti da OMME Music, &nbsp;MediaPics e
            Ampliando.it e avranno una validit&agrave; di 18 mesi dalla data di
            emissione.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Il Cliente potr&agrave; scegliere di applicare i buoni di acquisto
            per l&rsquo;aggiunta di un servizio da inserire nel proprio
            Contratto. Il valore dei buoni utilizzati non potr&agrave;
            per&ograve; superare il 50% del valore del servizio stesso.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Se, per esempio, il Cliente conquistasse buoni di acquisto dal
            valore totale di 200&euro; potr&agrave; applicare tali buoni a
            prodotti il cui valore &egrave; pari o superiore a 400&euro;.</span
          >
        </p>

        <p class="c0"><span class="c4">5. Gift Card</span></p>
        <p class="c0">
          <span class="c2"
            >Il Cliente avr&agrave; a disposizione delle Gift Card del valore di
            100&euro; che potr&agrave; regalare ad altre nuove coppie di sposi
            conoscenti.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Se la nuova coppia di sposi che riceve la Gift Card decider&agrave;
            di utilizzarla per l&rsquo;acquisto di uno dei servizi offerti da
            OMME Music godendo di uno sconto di 100&euro;, in egual modo il
            Cliente guadagner&agrave; 100&euro; di sconto che andr&agrave; a
            scalare dal proprio saldo finale.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Le Gift Card non hanno data di scadenza e potranno essere
            utilizzate anche dopo il giorno del matrimonio del Cliente (e quindi
            dopo la data di decadenza del contratto stipulato con OMME
            Music).</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Qualsiasi nuova coppia di sposi che decider&agrave; di utilizzarla
            per l&rsquo;acquisto di uno dei servizi offerti da OMME Music
            godr&agrave; di uno sconto di 100&euro; mentre il Cliente
            guadagner&agrave; 100&euro; di sconto su successivi nuove stipule di
            Contratti con OMME Music per qualsiasi servizio acquistato:
            musica/intrattenimento, foto/video, servizi web e
            comunicazione.</span
          >
        </p>

        <p class="c0">
          <span class="c4">6. Registrazione e creazione profilo</span>
        </p>
        <p class="c0">
          <span class="c2"
            >La registrazione e la creazione del profilo sar&agrave; effettuata
            a carico di OMME Music.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Il Cliente avr&agrave; la possibilit&agrave; di integrare e
            modificare i propri dati inseriti in totale autonomia o tramite il
            Servizio Clienti raggiungibile all&rsquo;indirizzo mail
            info@ommemusic.it.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Il Cliente ha sempre il diritto di richiedere la rettifica,
            modifica o cancellazione del proprio profilo e/o dei dati ivi
            compresi tramite la richiesta formale al Servizio Clienti
            aggiungibile all&rsquo;indirizzo mail info@ommemusic.it.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >In caso di cancellazione verranno azzerati tutti gli OmmePoint, i
            premi, gli sconti e i buoni di acquisto accumulati e non saranno
            pi&ugrave; recuperabili.</span
          >
        </p>

        <p class="c0">
          <span class="c4">8. Contenuti forniti dal Cliente</span>
        </p>
        <p class="c0">
          <span class="c2"
            >L&rsquo;inserimento di testi, musica, immagini, disegni o grafiche,
            espressioni artistiche e intellettuali fornite dal Cliente saranno
            sotto la sola sua responsabilit&agrave;.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >L&#39;utente riconosce espressamente che OMME Music fornisce
            soltanto uno strumento nel quale gli elementi introdotti dal Cliente
            saranno di sua assoluta e completa responsabilit&agrave;.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >A tal fine, il Cliente accetta di esimersi dall&#39;introdurre nel
            portale o nelle comunicazioni i seguenti contenuti:</span
          >
        </p>

        <p class="c0"><span class="c2">- Contenuti&nbsp;illegali</span></p>
        <p class="c0">
          <span class="c2"
            >- Contenuti&nbsp;considerati&nbsp;pornografici, volgari&nbsp;o
            osceni, molesti&nbsp;o che possano essere considerati&nbsp;in
            qualsiasi maniera offensiva&nbsp;anche se non violano la
            legge.</span
          >
        </p>
        <p class="c0">
          <span class="c2">- Descrizioni irrilevanti o poco chiare</span>
        </p>
        <p class="c0">
          <span class="c2"
            >- Contenuti&nbsp;estremisti,&nbsp;razzisti&nbsp;o xenofobici&nbsp;o
            contenuti&nbsp;postati&nbsp;da gruppi vietati</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >- Contenuti&nbsp;che interferiscono&nbsp;con i diritti di terze
            parti (in particolare, i diritti di una persona, diritti di
            propriet&agrave; intellettuale, diritti di marchio, diritti di
            brevetto o ogni altro diritto di terze parti)</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >- Contenuti&nbsp;che possono essere
            considerati&nbsp;pubblicit&agrave; commerciale o spam</span
          >
        </p>
        <ul class="c7 lst-kix_list_3-0">
          <li class="c0 c6 li-bullet-2">
            <span class="c2"
              >Contenuti&nbsp;che potrebbero danneggiare l&#39;immagine
              pubblica, la reputazione o il marchio di OMME Music, dei suoi
              servizi offerti, e dei suoi partner, dipendenti, altri utenti del
              servizio o terze parti.</span
            >
          </li>
        </ul>

        <p class="c0">
          <span class="c2"
            >OMME Music si riserva il diritto di sospendere o annullare
            l&#39;iscrizione dell&#39;utente che utilizza o introduce uno
            qualsiasi dei contenuti sopracitati. Ogni parte rimanente del
            Contratto stipulato e/o integrato tramite l&rsquo;utilizzo
            dell&rsquo;Area Sposi rester&agrave; effettivo e dovr&agrave; essere
            risolto nei tempi e secondo le modalit&agrave; accordate in fase di
            stipula del contratto.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >La sospensione e l&#39;esclusione dal servizio sono reversibili, se
            dimostrato che il sospetto era privo di fondamento.</span
          >
        </p>
        <p class="c0"><span class="c2">&nbsp;&nbsp;</span></p>
        <p class="c0">
          <span class="c4">9. Propriet&agrave; intellettuale</span>
        </p>
        <p class="c0">
          <span class="c2"
            >OMME Music &egrave; titolare dei diritti intellettuali e della
            propriet&agrave; industriale del sito ommemusic.it e della relativa
            Area Sposi, inoltre possiede la licenza dei diritti intellettuali e
            propriet&agrave; industriale e delle immagini di tutti i contenuti
            disponibili nel sito stesso.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Di conseguenza, al Cliente non &egrave; consentito ignorare,
            evadere o manipolare il copyright ed ogni altro dato che identifichi
            i diritti di OMME Music, cos&igrave; come la protezione tecnica dei
            dispositivi, tracce digitali o ogni altro meccanismo di informazione
            o identificazione che possa essere compreso nel contenuto.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >E&#39; inoltre vietato modificare, copiare, riutilizzare,
            sfruttare, riprodurre, comunicare pubblicamente, creare copie e
            backup, caricare file, mail, tramettere, usare, trattare o
            distribuire in qualsiasi maniera tutti o parte dei contenuti inclusi
            nel sito, al di fuori dell&#39;utilizzo specifico per i servizi
            forniti da OMME Music, se non esiste autorizzazione scritta di
            espresso consenso da parte della OMME Music stessa.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >L&#39;utilizzo dei servizi non genera propriet&agrave;
            intellettuale o qualsiasi diritto industriale su di essi da parte
            del Cliente.</span
          >
        </p>

        <p class="c0"><span class="c4">10. Utilizzo del sito</span></p>
        <p class="c0">
          <span class="c2">E&#39; espressamente proibito quanto segue:</span>
        </p>
        <ul class="c7 lst-kix_list_5-0 start">
          <li class="c0 c12 li-bullet-3">
            <span class="c2"
              >La pubblicazione di qualsiasi materiale contenuto in questo sito
              web con qualsiasi mezzo, cos&igrave; come la vendita, affitto,
              esposizione, riproduzione, copia o qualsiasi altra forma di
              sfruttamento del materiale contenuto nel sito, a scopi
              commerciali.<br
            /></span>
          </li>
          <li class="c0 c12 li-bullet-4">
            <span class="c2"
              >Montaggio o altra modifica di qualsiasi materiale contenuto nel
              sito;<br
            /></span>
          </li>
          <li class="c0 c12 li-bullet-5">
            <span class="c2"
              >Utilizzo del sito in un modo che comprometta, o possa
              compromettere la disponibilit&agrave; o
              l&#39;accessibilit&agrave;.<br
            /></span>
          </li>
          <li class="c0 c12 li-bullet-3">
            <span class="c2"
              >Utilizzare il sito o i suoi servizi come mezzo per copiare,
              archiviare, conservare, tramettere, inviare, usare pubblicare o
              distribuire qualsiasi materiali che consista in, o sia connesso
              con, qualsiasi tipo di virus o codice maligno.<br
            /></span>
          </li>
          <li class="c0 c12 li-bullet-5">
            <span class="c2"
              >Portare avanti qualsiasi attivit&agrave; di raccolta dati
              automatizzata o sistematica sul o in relazione al portale senza il
              consenso di OMME Music che deve essere espresso e scritto.<br
            /></span>
          </li>
        </ul>
        <p class="c0">
          <span class="c4"
            >11. Permessi di OMME Music sui dati degli utenti</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >OMME Music ha la facolt&agrave; di visualizzare e/o oscurare tutti
            i contenuti di ciascun profilo iscritto all&rsquo;Area Sposi ad
            eccezione della password reimpostata dal Cliente e delle credenziali
            di accesso ai social network collegati al profilo
            dell&rsquo;utente.</span
          >
        </p>

        <p class="c0">
          <span class="c4">12. Disponibilit&agrave; del sito</span>
        </p>
        <p class="c0">
          <span class="c2"
            >OMME Music non pu&ograve; garantire che il sito o i servizi saranno
            costantemente disponibili, o del tutto disponibili, o che la
            trasmissione dei dati da e per il sito abbia sempre una
            velocit&agrave; minima garantita.</span
          >
        </p>

        <p class="c0">
          <span class="c4">12. Responsabilit&agrave;&nbsp;di OMME Music</span>
        </p>
        <p class="c0">
          <span class="c2"
            >Entrambe le parti riconoscono espressamente che
            l&#39;attivit&agrave; e la responsabilit&agrave; di OMME Music
            &egrave; limitata alla corretta fornitura dei servizi offerti.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Nel caso in cui il Cliente percepisca che gli standard forniti da
            OMME Music siano venuti a mancare, il Cliente, prima di completare
            qualsiasi tipo di lamentela, accetta di indirizzare a OMME Music il
            corrispondente reclamo scritto inviato tramite posta elettronica
            all&rsquo;indirizzo info@ommemusic.it, fornendo dati obiettivi e
            prove disponibili.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >L&#39;utilizzo dell&rsquo;Area Sposi o dei servizi forniti da OMME
            Music &egrave; sotto la responsabilit&agrave; del Cliente.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Nonostante OMME Music abbia preso tutte le misure ragionevoli a
            riguardo, non pu&ograve; completamente garantire che il contenuto
            associato al servizio sia scevro da errore.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >OMME Music si riserva il diritto di correggere qualsiasi errore sul
            proprio sito web.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Se l&#39;utilizzo del sito in maniera errata da parte del Cliente
            dovesse portare alla necessit&agrave; di riparare o sostituire
            propriet&agrave;, materiali, strumenti o dati, o causi pregiudizio a
            persone o danni a propriet&agrave;, OMME Music non si riterr&agrave;
            responsabile dei costi generati.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >Allo stesso tempo il Cliente dovr&agrave; adottare quelle misure
            precauzionali per il proprio dispositivo che vengono considerate
            generalmente appropriate.</span
          >
        </p>

        <p class="c0">
          <span class="c4">13. Modifiche nelle condizioni d&#39;uso</span>
        </p>
        <p class="c0">
          <span class="c2"
            >OMME Music si riserva il diritto di modificare o ampliare le
            presenti condizioni in futuro senza alcun preavviso.</span
          >
        </p>

        <p class="c0">
          <span class="c4"
            >14. Sanzioni, sospensione e revoca dell&rsquo;account</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Nel caso in cui ci fossero indicazioni di qualsiasi violazione
            della legge in vigore, dei diritti di terze parti o la violazione
            degli obblighi derivanti da queste condizioni d&#39;uso da parte del
            Cliente, OMME Music si riserva il diritto di avvertire il/i
            Cliente/i interessato, affinch&eacute; cessino le attivit&agrave;
            dannose e/o far cessare in via straordinaria o chiudere
            l&#39;account del Cliente stesso.</span
          >
        </p>

        <p class="c0">
          <span class="c2"
            >La cancellazione dell&#39;account Utente nei termini indicati in
            questo paragrafo NON esula dagli obblighi contrattuali relativi ai
            servizi di Musica/Intrattenimento e/o Foto/Video.</span
          >
        </p>
        <p class="c0">
          <span class="c2"
            >Al contrario, tutti gli OmmePoint, premi, sconti e buoni di
            acquisto accumulati dal Cliente durante il periodo di utilizzo
            dell&rsquo;Area Sposi verranno azzerati e saranno considerati come
            non pi&ugrave; recuperabili (v. Punto 6).</span
          >
        </p>

        <p class="c0">
          <span class="c4">15. Legge applicabile e giurisdizione</span>
        </p>
        <p class="c0">
          <span class="c2"
            >Le condizioni contenute in queste condizioni d&#39;uso &egrave;
            soggetto alle normative italiane. Per qualsiasi controversia ne
            &egrave; competente il Foro di Bergamo.</span
          >
        </p>
        <div>
          <p class="c9 c1"><span class="c13"></span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'

import { projectFirestore } from '@/firebase/config'
import firebase from 'firebase/app'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

import { ref } from 'vue'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { documents: servizi } = getCollection('servizi', '', 'prezzo')

    const { error, document: utente } = getDocument('utenti', user.value.uid)
    const { updateDoc } = useDocument('utenti', user.value.uid)

    const service = ref(null)

    const { err, document: tutorial } = getDocument('tutorial', 'profilo')

    const shopCategories = [
      'Tutti',
      'Cerimonia',
      'Aperitivo',
      'Giochi',
      'Animazione bimbi',
      'Scenografia',
    ]

    const activeCat = ref('Tutti')

    const getImgUrl = (img) => {
      const images = require.context(
        '@/assets/images/services/',
        false,
        /\.jpg$/
      )

      return images('./' + img + '.jpg')
    }

    const upDoc = async () => {
      const newMissions = {
        500: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 500,
          stato: 'attiva',
        },
        1000: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 1000,
          stato: 'attiva',
        },
        1500: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 1500,
          stato: 'attiva',
        },
        2000: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 2000,
          stato: 'attiva',
        },
        2500: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 2500,
          stato: 'attiva',
        },
        3000: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 3000,
          stato: 'attiva',
        },
      }

      const newCerimonia = {
        indirizzo: '',
        ora: '',
        tipologia: '',
      }

      const newGift300 = {
        nome: 'gift-300',
        op: 300,
        quantita: 0,
        stato: 'disponibile',
        total: 0,
      }

      const newGift600 = {
        nome: 'gift-600',
        op: 600,
        quantita: 0,
        stato: 'disponibile',
        total: 0,
      }

      const newGift1000 = {
        nome: 'gift-1000',
        op: 1000,
        quantita: 0,
        stato: 'disponibile',
        total: 0,
      }
      const res = await updateDoc({
        buoni: [],
        cerimonia: newCerimonia,
        contratto: [],
        durataMatrimonio: '',
        forzieri: [],
        gift: [newGift300, newGift600, newGift1000],
        missioni: newMissions,
        repertorioCivile: {
          entrataSposo: '',
          entrataSposa: '',
          intro: '',
          promesse: '',
          rito: '',
          articoli: '',
          atto: '',
          firme: '',
          uscita: '',
          altro: '',
        },
        repertorioReligioso: {
          alleluia: '',
          comunione: '',
          entrataSposa: '',
          entrataSposo: '',
          firme: '',
          offertorio: '',
          rito: '',
        },
        repertorioRicevimento: {
          altro: '',
          arrivo: '',
          artistiNo: '',
          artistiSi: '',
          ballo: '',
          ingresso: '',
          torta: '',
        },
        ricevimento: {
          indirizzo: '',
          location: '',
        },
        sconti: [],
        shop: [],
        sposo1: {
          email: '',
          indirizzo: '',
          nome: '',
          telefono: '',
        },
        sposo2: {
          email: '',
          indirizzo: '',
          nome: '',
          telefono: '',
        },
      })
    }

    const handleSubmit = async (servizio, op) => {
      const res = await updateDoc({
        shop: firebase.firestore.FieldValue.arrayUnion(servizio),
        opTotal: firebase.firestore.FieldValue.increment(op),
      })
    }

    const handleDelete = async (servizio, op) => {
      const res = await updateDoc({
        shop: firebase.firestore.FieldValue.arrayRemove(servizio),
        opTotal: firebase.firestore.FieldValue.increment(-op),
      })
    }

    return {
      shopCategories,
      activeCat,
      servizi,
      getImgUrl,
      utente,
      error,
      handleSubmit,
      handleDelete,
      upDoc,
      tutorial,
    }
  },
}
</script>

<style scoped>
.panel {
  margin-top: 8rem;
  padding-bottom: 10rem;
}

.conditions {
  background-color: white;
  color: #333;
  max-height: 65vh;
  overflow-y: scroll;
  padding: 2rem;
}

ul {
  padding-left: 1rem;
}

.c4,
.c10 {
  font-family: 'Rift', sans-serif;
  font-weight: bold;
}

.c4 {
  font-size: 2.4rem;
}

.c10 {
  padding-top: 2rem;
  display: inline-block;
}
</style>
