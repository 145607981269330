<template>
  <div>
    <Bg />

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <div class="missions">
      <div v-for="missione in missi" :key="missione">
        <router-link
          :to="{ name: 'SingleMission', params: { id: missione.op } }"
        >
          <article
            class="mission mission--attiva"
            :class="{ 'mission--completata': missione.carteConquistate === 3 }"
          >
            <div v-if="missione.carteConquistate === 3" class="mission__icon">
              <i class="bx bx-check"></i>
            </div>

            <div class="mission__action">
              <span v-if="missione.carteConquistate !== 3">Conquista</span>
              <span v-if="missione.carteConquistate === 3"
                >Missione compiuta!</span
              >
            </div>

            <h2 class="mission__pride">
              <div class="ommepoints">{{ missione.op }}</div>
            </h2>

            <div
              v-if="
                missione.carteConquistate === 1 ||
                  missione.carteConquistate === 2
              "
              class="mission__status"
            >
              <div class="progress-bar">
                <div
                  class="progress-bar__slide"
                  :style="
                    'width: calc(80px * ' + missione.carteConquistate + '/3);'
                  "
                ></div>
              </div>
              <div class="mission__cards">
                <div class="mission__cards--title">Carte</div>
                <div class="mission__cards--fraction">
                  {{ missione.carteConquistate }}/3
                </div>
              </div>
            </div>

            <div
              v-if="missione.carteConquistate === 3"
              class="mission__btn mission__btn--white"
            >
              Conquistati!
            </div>
            <div
              v-if="missione.carteConquistate === 0"
              class="mission__btn mission__btn--secondary"
            >
              Start!
            </div>
          </article>
        </router-link>
      </div>
    </div>

    <div v-if="showModal === true" class="modal modal--tutorial">
      <div class="modal__overlay" @click.self="showModal = false">
        <div class="modal__card">
          <button class="modal__close" @click="showModal = false">
            <i class="bx bx-x"></i>
          </button>

          <div class="modal__body">
            <h3 class="modal__title">
              {{ tutorial.title }}
            </h3>

            <p class="modal__paragraph">
              {{ tutorial.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import getSubcollection from '@/composables/getSubcollection'
import getDocument from '@/composables/getDocument'
import { ref, computed } from 'vue'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  components: { Bg, Topbar },
  props: ['id'],
  setup(props) {
    const { documents: missioni } = getCollection('missioni', '', 'op')
    const { document: tutorial } = getDocument('tutorial', 'missioni')

    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    // const sortedMission = computed((arr) => {
    //   return arr.sort((a, b) => { a.op - b.op
    //   }))
    // }
    const missionId = props.id

    const { documents: missi } = getSubcollection(
      'utenti',
      user.value.uid,
      'missioni',
      '',
      'op'
    )

    const sortedMission = computed((arr) => {
      return arr.slice().sort((a, b) => {
        a.op - b.op
      })
    })

    const showModal = ref(false)

    const toggleModal = () => {
      showModal.value = !showModal.value
    }

    return {
      missioni,
      missi,
      sortedMission,
      utente,
      error,
      showModal,
      toggleModal,
      tutorial,
    }
  },
}
</script>
<style scoped>
h2.text-center {
  margin-top: 6rem;
}

.badge {
  position: fixed;
  top: 3rem;
  right: 2rem;
  z-index: 99999999 !important;
}
</style>
