<template>
  <div>
    <Bg />

    <div class="settings settings--profile">
      <h2 class="settings__heading">1.Crea utente</h2>
      <small>
        <p>
          Per creare un nuovo utente devi prima creare le sue credenziali nel
          pannello Authentication di Firebase.
        </p>
      </small>

      <br />

      <h2 class="settings__heading">
        2. Crea scheda utente
      </h2>

      <small>
        <p>
          Una volta create le credenziali dell'utente otterrai anche l'ID.
          Copialo e inseriscilo nel campo sottostante e clicca sul bottone per
          creare la scheda utente
        </p>
      </small>

      <br />

      <form class="form">
        <div class="form__row">
          <label>ID utente</label>
          <input type="text" v-model="id" />
        </div>

        <button @click.prevent="createUser(id)" class="btn btn--primary">
          Crea scheda utente
        </button>
      </form>

      <small v-if="docCreated">Scheda utente creata correttamente!</small>

      <br />

      <h2 class="settings__heading">
        3. Aggiungi i dati dell'utente
      </h2>

      <form
        class="form form--grid"
        @submit.prevent="insertUserData"
        v-if="utente"
      >
        <div>
          <h3>Info generali</h3>
        </div>
        <div>&nbsp;</div>

        <!-- Nome utente -->
        <div class="form__row">
          <label>Nome Utente</label>
          <input
            type="text"
            v-model="nomeUtente"
            :placeholder="utente.nomeUtente"
          />
        </div>

        <!-- Hashtag -->
        <div class="form__row">
          <label>Hashtag</label>
          <input type="text" v-model="hashtag" :placeholder="utente.hashtag" />
        </div>

        <!-- Foto Profilo -->
        <div class="form__row">
          <label>Foto Profilo</label>
          <input
            type="text"
            v-model="fotoProfilo"
            :placeholder="utente.fotoProfilo"
          />
        </div>

        <!-- Foto Sfondo -->
        <div class="form__row">
          <label>Foto Sfondo</label>
          <input type="text" v-model="bg" :placeholder="utente.bg" />
        </div>

        <div>
          <h3>Cerimonia</h3>
        </div>
        <div>&nbsp;</div>

        <!-- Data Matrimonio -->
        <div class="form__row">
          <label>Data matrimonio</label>
          <input
            type="datetime-local"
            :placeholder="utente.dataMatrimonio"
            v-model="dataMatrimonio"
          />
        </div>

        <!-- Durata Matrimonio -->
        <div class="form__row">
          <label>Durata matrimonio</label>
          <input
            type="text"
            v-model="durataMatrimonio"
            :placeholder="utente.durataMatrimonio"
          />
        </div>

        <!-- Cerimonia -->
        <div class="form__row form__row--checkbox">
          <label>Cerimonia - Tipologia</label>
          <input
            type="text"
            v-model="cerimoniaTipologia"
            :placeholder="utente.cerimonia.tipologia"
          />
        </div>

        <div class="form__row">
          <label>Cerimonia - indirizzo</label>
          <input
            type="text"
            v-model="cerimoniaIndirizzo"
            :placeholder="utente.cerimonia.indirizzo"
          />
        </div>

        <div class="form__row">
          <label>Cerimonia - ora</label>
          <input
            type="text"
            v-model="cerimoniaOra"
            :placeholder="utente.cerimonia.ora"
          />
        </div>

        <div>&nbsp;</div>

        <!-- Torneo -->
        <div class="form__row">
          <label>Torneo</label>
          <input type="text" v-model="torneo" :placeholder="utente.torneo" />
        </div>

        <!-- opStart -->
        <div class="form__row">
          <label>OP Start</label>
          <input
            type="number"
            v-model="opStart"
            :placeholder="utente.opStart"
          />
        </div>

        <!-- Sposo1 -->
        <div class="form__row">
          <label>Sposo 1 - nome</label>
          <input
            type="text"
            v-model="sposo1nome"
            :placeholder="utente.sposo1.nome"
          />
        </div>

        <div class="form__row">
          <label>Sposo 1 - email</label>
          <input
            type="text"
            v-model="sposo1email"
            :placeholder="utente.sposo1.email"
          />
        </div>

        <div class="form__row">
          <label>Sposo 1 - indirizzo</label>
          <input
            type="text"
            v-model="sposo1indirizzo"
            :placeholder="utente.sposo1.indirizzo"
          />
        </div>

        <div class="form__row">
          <label>Sposo 1 - telefono</label>
          <input
            type="text"
            v-model="sposo1telefono"
            :placeholder="utente.sposo1.telefono"
          />
        </div>

        <!-- Sposo2 -->
        <div class="form__row">
          <label>Sposo 2 - nome</label>
          <input
            type="text"
            v-model="sposo2nome"
            :placeholder="utente.sposo2.nome"
          />
        </div>

        <div class="form__row">
          <label>Sposo 2 - email</label>
          <input
            type="text"
            v-model="sposo2email"
            :placeholder="utente.sposo2.email"
          />
        </div>

        <div class="form__row">
          <label>Sposo 2 - indirizzo</label>
          <input
            type="text"
            v-model="sposo2indirizzo"
            :placeholder="utente.sposo2.indirizzo"
          />
        </div>

        <div class="form__row">
          <label>Sposo 2 - telefono</label>
          <input
            type="text"
            v-model="sposo2telefono"
            :placeholder="utente.sposo2.telefono"
          />
        </div>

        <!-- Ricevimento -->
        <div class="form__row">
          <label>Ricevimento - location</label>
          <input
            type="text"
            v-model="ricevimentoLocation"
            :placeholder="utente.ricevimento.location"
          />
        </div>

        <div class="form__row">
          <label>Ricevimento - indirizzo</label>
          <input
            type="text"
            v-model="ricevimentoIndirizzo"
            :placeholder="utente.ricevimento.indirizzo"
          />
        </div>

        <button @click.prevent="insertUserData(id)" class="btn btn--primary">
          Inserisci dati utente
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'

import Bg from '@/components/Bg.vue'
import { ref } from 'vue'

export default {
  components: { Bg },
  setup() {
    const id = ref(id)
    const docCreated = ref(false)
    const bg = ref('')
    const cerimoniaIndirizzo = ref('')
    const cerimoniaOra = ref('')
    const cerimoniaTipologia = ref('')
    const dataMatrimonio = ref('')
    const durataMatrimonio = ref('')
    const fotoProfilo = ref('')
    const hashtag = ref('')
    const nomeUtente = ref('')
    const opStart = ref(0)
    const ricevimentoIndirizzo = ref('')
    const ricevimentoLocation = ref('')
    const sposo1email = ref('')
    const sposo1indirizzo = ref('')
    const sposo1nome = ref('')
    const sposo1telefono = ref('')
    const sposo2email = ref('')
    const sposo2indirizzo = ref('')
    const sposo2nome = ref('')
    const sposo2telefono = ref('')
    const torneo = ref('')

    console.log(id.value)

    const { user } = getUser()
    const { error, document: utente } = getDocument(
      'users',
      'XfnFG2tMoCgH36IeMgqAIO97ofn1'
    )
    const { updateDoc } = useDocument('users', 'XfnFG2tMoCgH36IeMgqAIO97ofn1')

    const createUser = (id) => {
      projectFirestore
        .collection('users')
        .doc(id)
        .set({
          bg: '',
          buoni: [],
          cerimonia: {
            indirizzo: '',
            ora: '',
            tipologia: '',
          },
          contratto: [],
          dataMatrimonio: '',
          durataMatrimonio: '',
          forzieri: [],
          forzieriArgento: 0,
          forzieriBronzo: 0,
          forzieriOro: 0,
          forzieriTotal: 0,
          fotoProfilo: '',
          giftNum: 0,
          giftTotal: 0,
          hashtag: '',
          nomeUtente: '',
          obiettiviActionStandard: 0,
          obiettiviSocialStandard: 0,
          opStart: 0,
          opTotal: 0,
          primoAccesso: true,
          repertorioCivile: {
            altro: '',
            articoli: '',
            atto: '',
            entrataSposa: '',
            entrataSposo: '',
            firme: '',
            intro: '',
            promesse: '',
            rito: '',
            uscita: '',
          },
          repertorioReligioso: {
            alleluia: '',
            comunione: '',
            entrataSposa: '',
            entrataSposo: '',
            firme: '',
            offertorio: '',
            rito: '',
          },
          repertorioRicevimento: {
            altro: '',
            arrivo: '',
            artistiNo: '',
            artistiSi: '',
            ballo: '',
            entrataSposo: '',
            ingresso: '',
            rito: '',
            torta: '',
          },
          ricevimento: {
            indirizzo: '',
            location: '',
          },
          sconti: [],
          shop: [],
          shopTotal: 0,
          sposo1: {
            email: '',
            indirizzo: '',
            nome: '',
            telefono: '',
          },
          sposo2: {
            email: '',
            indirizzo: '',
            nome: '',
            telefono: '',
          },
          torneo: '',
        })

      docCreated.value = true
    }

    const insertUserData = async () => {
      let res = null

      //bg
      if (bg.value) {
        res = await updateDoc({
          bg: bg.value,
        })
      }

      //cerimonia
      if (cerimoniaIndirizzo.value) {
        res = await updateDoc({
          'cerimonia.indirizzo': cerimoniaIndirizzo.value,
        })
      }

      if (cerimoniaOra.value) {
        res = await updateDoc({
          'cerimonia.ora': cerimoniaOra.value,
        })
      }

      if (cerimoniaTipologia.value) {
        res = await updateDoc({
          'cerimonia.tipologia': cerimoniaTipologia.value,
        })
      }

      //data matrimonio
      if (dataMatrimonio.value) {
        res = await updateDoc({
          dataMatrimonio: new Date(dataMatrimonio.value),
        })
      }

      //durata matrimonio
      if (durataMatrimonio.value) {
        res = await updateDoc({
          durataMatrimonio: durataMatrimonio.value,
        })
      }

      //foto profilo
      if (fotoProfilo.value) {
        res = await updateDoc({
          fotoProfilo: fotoProfilo.value,
        })
      }

      //hashtag
      if (hashtag.value) {
        res = await updateDoc({
          hashtag: hashtag.value,
        })
      }

      //nome utente
      if (nomeUtente.value) {
        res = await updateDoc({
          nomeUtente: nomeUtente.value,
        })
      }

      //op Start
      if (opStart.value) {
        res = await updateDoc({
          opStart: opStart.value,
          opTotal: opStart.value,
        })
      }

      //Ricevimento
      if (ricevimentoIndirizzo.value) {
        res = await updateDoc({
          'ricevimento.indirizzo': ricevimentoIndirizzo.value,
        })
      }
      if (ricevimentoLocation.value) {
        res = await updateDoc({
          'ricevimento.location': ricevimentoLocation.value,
        })
      }

      //Sposo1
      if (sposo1email.value) {
        res = await updateDoc({
          'sposo1.email': sposo1email.value,
        })
      }
      if (sposo1indirizzo.value) {
        res = await updateDoc({
          'sposo1.indirizzo': sposo1indirizzo.value,
        })
      }
      if (sposo1nome.value) {
        res = await updateDoc({
          'sposo1.nome': sposo1nome.value,
        })
      }
      if (sposo1telefono.value) {
        res = await updateDoc({
          'sposo1.telefono': sposo1telefono.value,
        })
      }

      //Sposo2
      if (sposo2email.value) {
        res = await updateDoc({
          'sposo2.email': sposo2email.value,
        })
      }
      if (sposo2indirizzo.value) {
        res = await updateDoc({
          'sposo2.indirizzo': sposo2indirizzo.value,
        })
      }
      if (sposo1nome.value) {
        res = await updateDoc({
          'sposo2.nome': sposo2nome.value,
        })
      }
      if (sposo2telefono.value) {
        res = await updateDoc({
          'sposo2.telefono': sposo2telefono.value,
        })
      }

      //Torneo
      if (torneo.value) {
        res = await updateDoc({
          torneo: torneo.value,
        })
      }
    }

    return {
      id,
      createUser,
      docCreated,
      bg,
      cerimoniaIndirizzo,
      cerimoniaOra,
      cerimoniaTipologia,
      dataMatrimonio,
      durataMatrimonio,
      fotoProfilo,
      hashtag,
      nomeUtente,
      opStart,
      ricevimentoIndirizzo,
      ricevimentoLocation,
      sposo1email,
      sposo1indirizzo,
      sposo1nome,
      sposo1telefono,
      sposo2email,
      sposo2indirizzo,
      sposo2nome,
      sposo2telefono,
      torneo,
      insertUserData,
      utente,
    }
  },
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

@media (min-width: 1024px) {
  .form--grid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }

  .subgrid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;

    div {
      align-items: center;
      display: flex;
    }
  }
}

input[type='datetime-local'] {
  width: 100%;
}
</style>
