<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />

    <div v-if="utente" class="panel">
      <!-- COFFERS GRID-->
      <ul class="grid grid--coffers">
        <li
          class="coffer coffer--gold"
          v-for="forziere in forzieri"
          :key="forziere"
        >
          <div class="coffer__content">
            <div class="coffer__points ommepoints">
              <span v-for="fascia in fasce" :key="fascia">
                <span
                  v-if="
                    utente.opStart >= fascia.opStart.min &&
                      utente.opStart <= fascia.opStart.max
                  "
                >
                  <span ref="forziereOp" v-if="forziere.id === 'oro-10'">
                    {{ (forziere.op = fascia.forzieri[0]) }}
                  </span>

                  <span ref="forziereOp" v-if="forziere.id === 'oro-15'">
                    {{ (forziere.op = fascia.forzieri[1]) }}
                  </span>

                  <span ref="forziereOp" v-if="forziere.id === 'oro-20'">
                    {{ (forziere.op = fascia.forzieri[2]) }}
                  </span>
                </span>
              </span>
            </div>

            <img
              class="coffer__icon"
              src="@/assets/images/icons/icon-coffer-gold.svg"
            />
          </div>
          <div class="coffer__price">
            <div>{{ forziere.prezzo }}€</div>
            <div>
              <button
                class="coffer__cart"
                @click="handleSubmit(forziere.id, forziere.op)"
              >
                <i class="bx bx-cart-alt"></i>
              </button>
            </div>
          </div>

          <div v-if="showModal" class="modal modal--gift">
            <div class="modal__overlay">
              <div class="modal__card">
                <div class="modal__body">
                  <h3 class="modal__title">Forziere acquistato!</h3>

                  <p class="modal__paragraph">
                    Regalami a una coppia di amici che sta per sposarsi e
                    portali in OMME MUSIC. Per te
                  </p>

                  <img
                    class="coffer__icon"
                    src="@/assets/images/icons/icon-coffer-gold.svg"
                  />

                  <div class="modal__link">
                    <router-link
                      :to="{ name: 'Services' }"
                      class="btn btn--secondary"
                    >
                      I vostri forzieri <i class="bx bx-right-arrow-alt"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- COFFERS CART -->
    <section class="shop-total">
      <div class="shop-total__counter">
        Totale
        <div>
          <i class="bx bx-cart-alt"></i>
          <span v-if="utente">{{ utente.forzieri.length }}</span>
        </div>
      </div>
      <div class="shop-total__btn">
        <router-link :to="{ name: 'Services' }" class="btn btn--primary"
          >I vostri servizi</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'

import { projectFirestore } from '@/firebase/config'
import firebase from 'firebase/app'
import { ref } from 'vue'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { document: tutorial } = getDocument('tutorial', 'forzieri')

    const { documents: forzieri } = getCollection('forzieri-oro', '', 'prezzo')

    const { documents: fasce } = getCollection(
      'fasce-prezzo',
      '',
      'opStart.min'
    )

    const forziereOp = ref(null)

    const { updateDoc } = useDocument('utenti', user.value.uid)

    const handleSubmit = async (forziere, op) => {
      let res = await updateDoc({
        forzieri: firebase.firestore.FieldValue.arrayUnion(forziere),
        opTotal: firebase.firestore.FieldValue.increment(op),
        forzieriTotal: firebase.firestore.FieldValue.increment(op),
        //sconti: firebase.firestore.FieldValue.arrayUnion(30),
        forzieriOro: firebase.firestore.FieldValue.increment(1),
      })

      showModal.value = true
    }

    const showModal = ref(false)

    return {
      forzieri,
      utente,
      fasce,
      handleSubmit,
      forziereOp,
      tutorial,
      showModal,
    }
  },
}
</script>

<style scoped>
.panel {
  margin-top: 8rem;
}
</style>
