<template>
  <nav class="navbar" v-if="user">
    <router-link class="navbar__item" :to="{ name: 'Account' }"
      ><i class="bx bx-home-heart"></i
    ></router-link>

    <router-link class="navbar__item" :to="{ name: 'Missions' }"
      ><i class="bx bx-bullseye"></i
    ></router-link>

    <router-link class="navbar__item" :to="{ name: 'Ranking' }"
      ><i class="bx bx-trophy"></i
    ></router-link>

    <router-link class="navbar__item" :to="{ name: 'Shop' }"
      ><i class="bx bx-shopping-bag"></i
    ></router-link>

    <router-link class="navbar__item" :to="{ name: 'PuzzleBonus' }"
      ><i class="bx bx-trip"></i
    ></router-link>

    <div class="navbar__item" @click="showDropdown = !showDropdown">
      <img v-if="utente && utente.fotoProfilo" :src="utente.fotoProfilo" />
      <img v-else src="@/assets/images/avatar-placeholder.png" />
    </div>

    <div
      class="submenu"
      :class="{ submenuOpen: showDropdown }"
      @click="showDropdown = false"
    >
      <ul>
        <li>
          <router-link :to="{ name: 'Profile' }"
            ><i class="bx bx-user-circle"></i>Profilo</router-link
          >
        </li>

        <li>
          <router-link :to="{ name: 'Settings' }"
            ><i class="bx bx-cog"></i>Impostazioni</router-link
          >
        </li>

        <li>
          <router-link :to="{ name: 'Conditions' }"
            ><i class="bx bx-info-circle"></i>Termini e condizioni</router-link
          >
        </li>

        <li>
          <button @click.passive="handleClick">
            <i class="bx bx-log-out-circle"></i>Esci
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import getDocument from '@/composables/getDocument'

export default {
  setup() {
    const { user } = getUser()
    const { logout } = useLogout()
    const router = useRouter()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const showDropdown = ref(false)

    const handleClick = async () => {
      await logout()
      console.log('Utente disconnesso')
      router.push({ name: 'Login' })
    }

    return { handleClick, user, showDropdown, utente }
  },
}
</script>

<style></style>
