<template>
  <div>
    <Bg />

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <!-- AVATAR -->
    <Avatar />

    <div class="text-center">
      <router-link class="avatar__edit" :to="{ name: 'Settings' }">
        Modifica
      </router-link>
    </div>

    <div class="panel">
      <Tabs />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import Topbar from '@/components/Topbar.vue'
import Bg from '@/components/Bg.vue'
import Tabs from '@/components/Tabs.vue'

import getDocument from '@/composables/getDocument'
export default {
  components: { Avatar, Bg, Tabs, Topbar },

  setup() {
    const { err, document: tutorial } = getDocument('tutorial', 'profilo')

    return { tutorial }
  },
}
</script>

<style></style>
