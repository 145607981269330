<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />

    <h2 class="text-center">
      Scegli uno tra i seguenti obiettivi e conquista la carta social
    </h2>

    <div class="goals">
      <li
        class="goals__item"
        v-for="obiettivo in obiettiviSocial"
        :key="obiettivo"
      >
        <GoalSocial
          :name="obiettivo.nome"
          :description="obiettivo.descrizione"
          :social="obiettivo.social"
          :paragraph="obiettivo.paragrafo"
          :btn="obiettivo.btn"
          :opStart="utente.opStart"
          :id="obiettivo.id"
          :missionId="missionId"
          :extra="obiettivo.extra"
          :gold="obiettivo.gold"
          :silver="obiettivo.silver"
          :azione="obiettivo.azione"
        />
      </li>
    </div>
  </div>
</template>

<script>
//components
import Bg from '@/components/Bg.vue'
import GoalSocial from '@/components/GoalSocial.vue'
import Topbar from '@/components/Topbar.vue'

//composables
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import { ref } from 'vue'

export default {
  components: { Bg, GoalSocial, Topbar },
  props: ['id'],
  setup(props) {
    //user
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { document: tutorial } = getDocument('tutorial', 'missioni')

    //obiettivi
    const { documents: obiettiviSocial } = getCollection('obiettivi', [
      'categoria',
      '==',
      'social',
    ])

    const missionId = ref(props.id)

    console.log(missionId.value)

    return { obiettiviSocial, utente, missionId, tutorial }
  },
}
</script>

<style>
h2.text-center {
  margin-top: 10rem;
}
</style>
