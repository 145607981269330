<template>
  <nav class="nav-main">
    <router-link class="nav-main__item item" :to="{ name: 'Ranking' }">
      <div class="item__left">
        <img class="item__icon" src="@/assets/images/icons/icon-trophy.svg" />
      </div>
      <div class="item__center">
        <h2 class="item__title">Classifica generale</h2>
        <p class="item__paragraph">
          Accumula Ommepoint e scala la Classifica. Tanti premi in palio!
        </p>
      </div>
      <div class="item__right">
        <i class="bx bx-right-arrow-alt"></i>
      </div>
    </router-link>

    <router-link class="nav-main__item item" :to="{ name: 'Missions' }">
      <div class="item__left">
        <img
          class="item__icon"
          src="@/assets/images/icons/icon-dart-cards.svg"
        />
      </div>
      <div class="item__center">
        <h2 class="item__title">Missioni</h2>
        <p class="item__paragraph">
          Accumula Ommepoint conquistando le carte e completa le missioni
        </p>
      </div>
      <div class="item__right">
        <i class="bx bx-right-arrow-alt"></i>
      </div>
    </router-link>

    <router-link class="nav-main__item item" :to="{ name: 'PuzzleBonus' }">
      <div class="item__left">
        <img
          class="item__icon"
          src="@/assets/images/icons/icon-puzzle-bonus.svg"
        />
      </div>
      <div class="item__center">
        <h2 class="item__title">Il puzzle dei bonus</h2>
        <p class="item__paragraph">
          Completa il puzzle e vinci 2000 ommepoint e il poster della tua foto
        </p>
      </div>
      <div class="item__right">
        <i class="bx bx-right-arrow-alt"></i>
      </div>
    </router-link>

    <router-link class="nav-main__item item" :to="{ name: 'PyramidGoals' }">
      <div class="item__left">
        <img class="item__icon" src="@/assets/images/icons/icon-pyramid.svg" />
      </div>
      <div class="item__center">
        <h2 class="item__title">La piramide degli obiettivi</h2>
        <p class="item__paragraph">
          Raggiungi gli obiettivi e scala la piramide. Tanti premi per te!
        </p>
      </div>
      <div class="item__right">
        <i class="bx bx-right-arrow-alt"></i>
      </div>
    </router-link>

    <router-link class="nav-main__item item" :to="{ name: 'Shop' }">
      <div class="item__left">
        <img
          class="item__icon"
          src="@/assets/images/icons/icon-online-shop.svg"
        />
      </div>
      <div class="item__center">
        <h2 class="item__title">OMME Shop</h2>
        <p class="item__paragraph">
          Aggiungi servizi al tuo contratto e paga comodamente al saldo.
        </p>
      </div>
      <div class="item__right">
        <i class="bx bx-right-arrow-alt"></i>
      </div>
    </router-link>

    <router-link class="nav-main__item item" :to="{ name: 'CoffersShop' }">
      <div class="item__left">
        <img class="item__icon" src="@/assets/images/icons/icon-coffers.svg" />
      </div>
      <div class="item__center">
        <h2 class="item__title">Shop Forzieri</h2>
        <p class="item__paragraph">
          Acquista i forzieri per guadagnare ommepoint più velocemente.
        </p>
      </div>
      <div class="item__right">
        <i class="bx bx-right-arrow-alt"></i>
      </div>
    </router-link>

    <router-link class="nav-main__item item" :to="{ name: 'GiftCards' }">
      <div class="item__left">
        <img
          class="item__icon"
          src="@/assets/images/icons/icon-gift-card.svg"
        />
      </div>
      <div class="item__center">
        <h2 class="item__title">Gift Card</h2>
        <p class="item__paragraph">
          Regalala a chi, come te, si sta per sposare. Tanti sconti in regalo!
        </p>
      </div>
      <div class="item__right">
        <i class="bx bx-right-arrow-alt"></i>
      </div>
    </router-link>
  </nav>
</template>

<script>
export default {}
</script>

<style></style>
