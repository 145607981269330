<template>
  <div
    v-if="true"
    class="page"
    :class="{ 'page--login': !user, 'page--default': user }"
  >
    <Topbar v-if="user" />
    <div class="page__content">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
    <Navbar v-if="user" />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Topbar from '@/components/Topbar.vue'
import getUser from '@/composables/getUser'
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'

export default {
  components: { Navbar, Topbar },
  setup() {
    const { user } = getUser()

    const router = useRoute()

    return { user }
  },
}
</script>

<style>
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.3s ease-in-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.3s ease-in-out;
}
</style>
