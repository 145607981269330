<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />

    <h1 class="heading">
      Missione <b class="ommepoints">{{ missNumber }}</b>
    </h1>

    <div class="panel">
      <h3 class="text-center" style="margin-bottom: 3rem">
        Conquista le tre carte<br />per completare la missione
      </h3>

      <div class="grid grid--cards">
        <article class="card card--social">
          <router-link
            :to="{ name: 'CardSocial', params: { id: missNumber } }"
            class="card__state card__state--attiva"
          >
            <div class="card__body">
              <h3 class="card__title">Carta <span>Social</span></h3>

              <img
                class="card__icon"
                src="@/assets/images/icons/icon-card-social.svg"
              />
            </div>

            <div class="card__footer">
              Scegli obiettivo
            </div>
          </router-link>

          <div v-if="utente">
            <div v-for="mix in missi" :key="mix">
              <div
                v-if="mix.op == missNumber && mix.social === 'conquistata'"
                class="card__state card__state--conquistata"
              >
                <h3 class="card__title">Carta <span>Social</span></h3>

                <div class="card__check">
                  <i class="bx bx-check"></i>
                </div>

                <div class="card__ommepoints">
                  <span class="ommepoints">
                    <div v-for="fascia in fasce" :key="fascia">
                      <div
                        v-if="
                          utente.opStart >= fascia.opStart.min &&
                            utente.opStart <= fascia.opStart.max
                        "
                      >
                        {{ fascia.social }}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="utente">
            <div v-for="mix in missi" :key="mix">
              <div
                v-if="mix.op == missNumber && mix.social === 'bloccata'"
                class="card__state card__state--bloccata"
              >
                <i class="bx bxs-lock"></i>
                <div>
                  Conquista la carta
                  <span>social </span> per sbloccare
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="card card--action">
          <router-link
            :to="{ name: 'CardAction', params: { id: missNumber } }"
            class="card__state card__state--attiva"
          >
            <div class="card__body">
              <h3 class="card__title">Carta <span>Action</span></h3>

              <img
                class="card__icon"
                src="@/assets/images/icons/icon-card-action.svg"
              />
            </div>

            <div class="card__footer">
              Scegli obiettivo
            </div>
          </router-link>

          <div v-if="utente">
            <div v-for="mix in missi" :key="mix">
              <div
                v-if="mix.op == missNumber && mix.action === 'conquistata'"
                class="card__state card__state--conquistata"
              >
                <h3 class="card__title">Carta <span>Action</span></h3>

                <div class="card__check">
                  <i class="bx bx-check"></i>
                </div>

                <div class="card__ommepoints">
                  <span class="ommepoints">
                    <div v-for="fascia in fasce" :key="fascia">
                      <div
                        v-if="
                          utente.opStart >= fascia.opStart.min &&
                            utente.opStart <= fascia.opStart.max
                        "
                      >
                        {{ fascia.action }}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="utente">
            <div v-for="mix in missi" :key="mix">
              <div
                v-if="mix.op == missNumber && mix.action === 'bloccata'"
                class="card__state card__state--bloccata"
              >
                <i class="bx bxs-lock"></i>
                <div>
                  Conquista la carta
                  <span>social </span><br />
                  per sbloccare
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="card card--shopping">
          <router-link
            :to="{ name: 'CardShopping', params: { id: missNumber } }"
            class="card__state card__state--attiva"
          >
            <div class="card__body">
              <h3 class="card__title">Carta <span>Shopping</span></h3>

              <img
                class="card__icon"
                src="@/assets/images/icons/icon-card-shopping.svg"
              />
            </div>

            <div class="card__footer">
              Scegli obiettivo
            </div>
          </router-link>

          <div v-if="utente">
            <div v-for="mix in missi" :key="mix">
              <div
                v-if="mix.op == missNumber && mix.shopping === 'conquistata'"
                class="card__state card__state--conquistata"
              >
                <h3 class="card__title">Carta <span>Shopping</span></h3>

                <div class="card__check">
                  <i class="bx bx-check"></i>
                </div>

                <div class="card__ommepoints">
                  <div v-if="missNumber === '500'">
                    <span class="ommepoints">250</span>
                  </div>
                  <div v-if="missNumber === '1000'">
                    <span class="ommepoints">450</span>
                  </div>
                  <div v-if="missNumber === '1500'">
                    <span class="ommepoints">750</span>
                  </div>
                  <div v-if="missNumber === '2000'">
                    <span class="ommepoints">950</span>
                  </div>
                  <div v-if="missNumber === '2500'">
                    <span class="ommepoints">1150</span>
                  </div>
                  <div v-if="missNumber === '3000'">
                    <span class="ommepoints">1550</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="utente">
            <div v-for="mix in missi" :key="mix">
              <div
                v-if="mix.op == missNumber && mix.shopping === 'bloccata'"
                class="card__state card__state--bloccata"
              >
                <i class="bx bxs-lock"></i>
                <div>
                  Conquista la carta
                  <span>action </span><br />
                  per sbloccare
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'
import getSubcollection from '@/composables/getSubcollection'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'
import { ref } from 'vue'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  props: ['id'],
  components: { Bg, Topbar },
  setup(props) {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { documents: fasce } = getCollection('fasce-prezzo')
    const { documents: servizi } = getCollection('servizi')

    const { document: tutorial } = getDocument('tutorial', 'missioni')

    const { documents: missi } = getSubcollection(
      'utenti',
      user.value.uid,
      'missioni',
      '',
      'op'
    )

    const missNumber = ref(props.id)

    const id = ref(props.id)

    return {
      utente,
      error,
      missNumber,
      fasce,
      id,
      missNumber,
      missi,
      servizi,
      tutorial,
    }
  },
}
</script>

<style scoped>
h1.heading {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999999 !important;
}

.panel {
  background-color: transparent;
  margin-bottom: 6rem;
  margin-top: 10rem;
}
</style>
