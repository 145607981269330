<template>
  <section class="tracklist" v-if="utente">
    <div class="tracklist__header">
      <div class="tracklist__icon">
        <img src="@/assets/images/icons/icon-ring.svg" />
      </div>
      <h2 class="tracklist__title">
        Cerimonia religiosa
      </h2>
      <div class="tracklist__btn">
        <button
          class="btn btn--primary"
          @click.prevent="isEditingReligioso = !isEditingReligioso"
        >
          Modifica
        </button>
      </div>
    </div>

    <form class="tracklist__list" @submit.prevent="editReligioso">
      <div class="tracklist__item">
        <label>Entrata Sposo:</label>
        <input
          type="text"
          :placeholder="utente.repertorioReligioso.entrataSposo"
          v-model="entrataSposo1R"
          v-if="isEditingReligioso"
        />
        <p v-else>
          <small v-if="utente.repertorioReligioso.entrataSposo">{{
            utente.repertorioReligioso.entrataSposo
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Entrata Sposa:</label>
        <input
          type="text"
          :placeholder="utente.repertorioReligioso.entrataSposa"
          v-model="entrataSposo2R"
          v-if="isEditingReligioso"
        />
        <p v-else>
          <small v-if="utente.repertorioReligioso.entrataSposa">{{
            utente.repertorioReligioso.entrataSposa
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Alleluia:</label>
        <input
          type="text"
          :placeholder="utente.repertorioReligioso.alleluia"
          v-model="alleluia"
          v-if="isEditingReligioso"
        />
        <p v-else>
          <small v-if="utente.repertorioReligioso.alleluia">{{
            utente.repertorioReligioso.alleluia
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Rito matrimonio e scambio fedi:</label>
        <input
          type="text"
          :placeholder="utente.repertorioReligioso.rito"
          v-model="rito"
          v-if="isEditingReligioso"
        />
        <p v-else>
          <small v-if="utente.repertorioReligioso.rito">{{
            utente.repertorioReligioso.rito
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Offertorio:</label>
        <input
          type="text"
          :placeholder="utente.repertorioReligioso.offertorio"
          v-model="offertorio"
          v-if="isEditingReligioso"
        />
        <p v-else>
          <small v-if="utente.repertorioReligioso.offertorio">{{
            utente.repertorioReligioso.offertorio
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Comunione:</label>
        <input
          type="text"
          :placeholder="utente.repertorioReligioso.comunione"
          v-model="comunione"
          v-if="isEditingReligioso"
        />
        <p v-else>
          <small v-if="utente.repertorioReligioso.comunione">{{
            utente.repertorioReligioso.comunione
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Firme/chiusura:</label>
        <input
          type="text"
          :placeholder="utente.repertorioReligioso.firme"
          v-model="firme"
          v-if="isEditingReligioso"
        />
        <p v-else>
          <small v-if="utente.repertorioReligioso.firme">{{
            utente.repertorioReligioso.firme
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <button class="btn btn--primary btn--small">Salva</button>
    </form>

    <div class="form__submit-ok" v-if="submitReligiosoOK">
      Modifiche salvate correttamente!
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import useDocument from '@/composables/useDocument'

export default {
  setup() {
    //utente
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    //updateDoc
    const { updateDoc } = useDocument('utenti', user.value.uid)

    //refs
    const isEditingReligioso = ref(false)
    const submitReligiosoOK = ref(false)
    const entrataSposo1R = ref('')
    const entrataSposo2R = ref('')
    const alleluia = ref('')
    const rito = ref('')
    const offertorio = ref('')
    const comunione = ref('')
    const firme = ref('')

    //modifica repertorio religioso
    const editReligioso = async () => {
      let res = null

      //entrata sposo
      if (entrataSposo1R.value) {
        res = await updateDoc({
          'repertorioReligioso.entrataSposo': entrataSposo1R.value,
        })
      }

      //entrata sposa
      if (entrataSposo2R.value) {
        res = await updateDoc({
          'repertorioReligioso.entrataSposa': entrataSposo2R.value,
        })
      }

      //alleluia
      if (alleluia.value) {
        res = await updateDoc({
          'repertorioReligioso.alleluia': alleluia.value,
        })
      }

      //rito
      if (rito.value) {
        res = await updateDoc({
          'repertorioReligioso.rito': rito.value,
        })
      }

      //offertorio
      if (offertorio.value) {
        res = await updateDoc({
          'repertorioReligioso.offertorio': offertorio.value,
        })
      }

      //comunione
      if (comunione.value) {
        res = await updateDoc({
          'repertorioReligioso.comunione': comunione.value,
        })
      }

      //firme
      if (firme.value) {
        res = await updateDoc({
          'repertorioReligioso.firme': firme.value,
        })
      }

      isEditingReligioso.value = !isEditingReligioso.value
      submitReligiosoOK.value = true
      console.log('Modifiche salvate correttamente')
    }

    return {
      utente,
      error,
      submitReligiosoOK,
      isEditingReligioso,
      entrataSposo1R,
      entrataSposo2R,
      alleluia,
      rito,
      offertorio,
      comunione,
      firme,
      editReligioso,
    }
  },
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}
input {
  background-color: #e9e9e9;
  border: none;
  border-radius: 2px;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.6rem;
  outline: none;
  padding: 1rem;
}
button {
  margin-right: auto;
}
</style>
