<template>
  <div>
    <h1 class="heading">
      Gift Card<br />
      <span>OMME Music</span>
    </h1>
    <div class="panel">
      <p>
        <strong>Ciao, sono una Gift Card OMME Music del valore di 100€!</strong>
      </p>
      <img src="@/assets/images/gift-card.jpg" alt="Gift Card 100€" />

      <p>
        Sono valida su tutti i servizi OMME Music (musica, foto e video),
        l’azienda che i tuoi amici hanno scelto per l’intrattenimento del loro
        matrimonio.
      </p>

      <a
        href="https://firebasestorage.googleapis.com/v0/b/omme-area-sposi.appspot.com/o/gift-card.pdf?alt=media&token=68f7d890-00bc-4725-8d16-47463ff2cac8"
        target="_blank"
        class="btn btn--secondary"
      >
        Scaricami! <i class="bx bx-download"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.heading {
  margin-top: 3rem;
}
.panel {
  margin-top: 1rem;
  text-align: center;
}

.btn {
  margin: 0 auto;
}
</style>
