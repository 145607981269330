<template>
  <div>
    <Bg />

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <div v-if="utente">
      <div v-if="utente.cerimonia.tipologia">
        <CerimoniaReligiosa v-if="utente.cerimonia.tipologia === 'religiosa'" />
        <CerimoniaCivile v-if="utente.cerimonia.tipologia === 'civile'" />
      </div>
      <Ricevimento v-if="utente.ricevimento.location" />
      <div class="no-data" v-else>
        <h3>Dati in aggiornamento</h3>
      </div>
    </div>
  </div>
</template>

<script>
//components
import Bg from '@/components/Bg'
import Topbar from '@/components/Topbar'
import CerimoniaReligiosa from '@/components/CerimoniaReligiosa'
import CerimoniaCivile from '@/components/CerimoniaCivile'
import Ricevimento from '@/components/Ricevimento'

//composables
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'

import { ref } from 'vue'
export default {
  components: { Bg, Topbar, CerimoniaReligiosa, CerimoniaCivile, Ricevimento },
  setup() {
    //user
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { err, document: tutorial } = getDocument(
      'tutorial',
      'repertorio-musicale'
    )

    //referecens
    const test = ref('ciao')

    return {
      test,
      utente,
      error,
      tutorial,
    }
  },
}
</script>

<style lang="scss" scoped>
.no-data {
  display: flex;
  height: 100vh;

  h3 {
    margin: auto;
  }
}
</style>
