<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />

    <div v-if="utente" class="panel">
      <!-- COFFERS GRID-->
      <ul class="grid grid--coffers">
        <li
          v-for="forziere in forzieri"
          :key="forziere"
          class="coffer coffer--bronze"
        >
          <div class="coffer__content">
            <div class="coffer__points ommepoints">
              {{ forziere.op }}
            </div>

            <img
              class="coffer__icon"
              src="@/assets/images/icons/icon-coffer-bronze.svg"
            />
          </div>
          <div class="coffer__price">
            <div>{{ forziere.prezzo }}€</div>
            <div>
              <button
                class="coffer__cart"
                @click="handleSubmit(forziere.id, forziere.op)"
              >
                <i class="bx bx-cart-alt"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- COFFERS CART -->
    <section class="shop-total">
      <div class="shop-total__counter">
        Totale
        <div>
          <i class="bx bx-cart-alt"></i>
          <span v-if="utente">{{ utente.forzieri.length }}</span>
        </div>
      </div>
      <div class="shop-total__btn">
        <router-link :to="{ name: 'Services' }" class="btn btn--primary"
          >I vostri servizi</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import useDocument from '../composables/useDocument'

import { projectFirestore } from '../firebase/config'
import firebase from 'firebase/app'
import { ref } from 'vue'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { document: tutorial } = getDocument('tutorial', 'forzieri')

    const { documents: forzieri } = getCollection('forzieri-shop', '', 'prezzo')

    const { documents: fasce } = getCollection(
      'fasce-prezzo',
      '',
      'opStart.min'
    )

    const forziereOp = ref(null)

    const { updateDoc } = useDocument('utenti', user.value.uid)

    const handleSubmit = async (forziere, op) => {
      let res = await updateDoc({
        forzieriShop: firebase.firestore.FieldValue.arrayUnion(forziere),
        opTotal: firebase.firestore.FieldValue.increment(op),
        forzieriTotal: firebase.firestore.FieldValue.increment(op),
        //sconti: firebase.firestore.FieldValue.arrayUnion(30),
      })
    }

    return {
      forzieri,
      utente,
      fasce,
      handleSubmit,
      forziereOp,
      tutorial,
    }
  },
}
</script>

<style scoped>
.panel {
  margin-top: 8rem;
}
</style>
