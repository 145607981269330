<template>
  <div v-if="error" class="error">{{ error }}</div>

  <div v-if="utente" class="avatar">
    <div class="avatar__pic">
      <img
        :src="utente.fotoProfilo"
        :alt="utente.nomeUtente"
        v-if="utente.fotoProfilo"
      />
      <img v-else src="@/assets/images/avatar-placeholder.png" />
    </div>
    <h1 class="avatar__name">{{ utente.nomeUtente }}</h1>
    <div class="avatar__date">
      {{
        utente.dataMatrimonio.toDate().toLocaleString('it-IT', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      }}
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'

export default {
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    return { error, utente }
  },
}
</script>

<style></style>
