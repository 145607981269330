<template>
  <div id="fb-root"></div>
  <div
    class="goal goal--action"
    :class="{ gold: gold, silver: silver }"
    @click="displayModal(id)"
  >
    <!-- GOAL COMPLETE -->
    <div v-for="usrGoal in usrGoals" :key="usrGoal">
      <div v-if="usrGoal.id === id">
        <div class="goal--complete" v-if="usrGoal.raggiunto">
          <div class="goal__icon">
            <img src="@/assets/images/icons/icon-instagram.svg" />
          </div>

          <div class="goal__content">
            <h3 class="goal__title">{{ name }}</h3>
            <p class="goal__description">{{ description }}</p>
          </div>

          <i class="bx bx-check"></i>
        </div>
      </div>
    </div>

    <div class="goal__left">
      <div class="goal__icon">
        <img src="@/assets/images/icons/icon-instagram.svg" />
      </div>

      <div class="goal__content">
        <h3 class="goal__title">{{ name }}</h3>
        <p class="goal__description">{{ description }}</p>
      </div>

      <div class="goal__ommepoints">
        <div v-for="fascia in fasce" :key="fascia">
          <div
            v-if="
              opStart >= fascia.opStart.min && opStart <= fascia.opStart.max
            "
          >
            <span class="ommepoints">{{ (op = fascia.action) }}</span>

            <span v-if="extra" class="goal__extra">
              <span class="ommepoints">+{{ extra }}</span> extra</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="goal__right">
      <i class="bx bx-right-arrow-alt"></i>
    </div>
  </div>

  <div class="modal" v-if="showModal === true">
    <div class="modal__overlay" @click.self="showModal = false">
      <div class="modal__card modal__card--action">
        <button class="modal__close" @click="showModal = false">
          <i class="bx bx-x"></i>
        </button>

        <div class="modal__body">
          <h3 class="modal__title">
            {{ name }}
          </h3>
          <div class="modal__subtitle">
            Carta action
          </div>

          <p class="modal__paragraph">
            {{ paragraph }}
          </p>

          <!-- <div class="modal__price">
                100€
              </div> -->

          <!--<p class="modal__paragraph">
                Regalami a una coppia di amici che sta per sposarsi e portali in
                OMME MUSIC. Per te
              </p> -->

          <div class="modal__ommepoints modal__ommepoints--big">
            <span class="ommepoints">+{{ op }}</span>
          </div>

          <!--<div class="modal__ommepoints modal__ommepoints--big">
                <span class="ommepoints">+{{ omme }}</span>
              </div>-->

          <div
            class="modal__extra"
            :class="{
              'modal__extra--gold': gold,
              'modal__extra--silver': silver,
            }"
            v-if="extra"
          >
            <h4>
              Action <span v-if="gold">gold</span
              ><span v-if="silver">silver</span>!
            </h4>

            <p>Se conquisti questa carta guadagnerai un bonus di</p>

            <div>
              <span class="ommepoints"> +{{ extra }}</span>
              extra
            </div>
          </div>

          <div class="modal__btn" v-if="name === 'Condividi Mediapics'">
            <a
              href="https://wa.me/?text=Ciao!%20Per%20le%20foto%20e%20i%20video%20del%20nostro%20matrimonio%20abbiamo%20scelto%20MediaPics.%20Te%20lo%20consigliamo!%20Ecco%20il%20biglietto%20da%20visita%20digitale:%20condividilo!%20http:%2F%2Fareasposi.ommemusic.it%2Fcondividi-mediapics"
              class="btn btn--primary"
              @click="goalCTA(missionId, op, id, extra)"
              target="_blank"
            >
              {{ btn }} <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>

          <div class="modal__btn" v-if="name === 'Gift card'">
            <div v-if="utente.giftNum <= 20">
              <p>
                Hai inviato {{ utente.giftNum }} gift card. Devi inviarne 20 per
                poter raggiungere l'obiettivo
              </p>

              <router-link :to="{ name: 'GiftCards' }" class="btn btn--primary">
                {{ btn }} <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </div>

            <div v-else>
              <p>Complimenti! Obiettivo raggiunto!</p>

              <a
                class="btn btn--primary"
                @click.prevent="goalCTA(missionId, op, id, extra)"
                target="_blank"
              >
                Continua <i class="bx bx-right-arrow-alt"></i>
              </a>
            </div>
          </div>

          <div class="modal__btn" v-if="name === 'Artisti cercasi'">
            <a
              href="https://wa.me/?text=Ciao!%20OMME%20Music,%20l'azienda%20che%20abbiamo%20scelto%20per%20la%20musica%20del%20nostro%20matrimonio%20è%20alla%20ricerca%20di%20nuovi%20artisti.%20Abbiamo%20pensato%20potesse%20interessarti.%20A%20questo%20link%20puoi%20proporre%20la%20tua%20collaborazione:%20https:%2F%2Fwww.ommemusic.it%2Flavora-con-noi%2F"
              class="btn btn--primary"
              @click="goalCTA(missionId, op, id, extra)"
              target="_blank"
            >
              {{ btn }} <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>

          <div
            class="modal__btn"
            v-if="name === 'Condividi il sito OMME Music!'"
          >
            <div
              @click="goalCTA(missionId, op, id, extra)"
              class="btn btn--primary"
              data-href="https://www.ommemusic.it/"
              data-layout="button"
              data-size="large"
            >
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.ommemusic.it%2F&amp;src=sdkpreparse"
                class="fb-xfbml-parse-ignore"
                >Condividi</a
              >
            </div>
          </div>

          <div class="modal__btn" v-if="name === 'Repertorio musicale'">
            <button
              class="btn btn--primary"
              @click="goalCTA(missionId, op, id, extra)"
            >
              <router-link :to="{ name: 'Playlist' }">
                {{ btn }} <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </button>
          </div>

          <div class="modal__btn" v-if="name === 'Mediapics foto e video'">
            <a
              href="https://www.ommemusic.it/contatti"
              class="btn btn--primary"
              @click="goalCTA(missionId, op, id, extra)"
              target="_blank"
            >
              {{ btn }} <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>

          <!-- https://www.ommemusic.it/lavora-con-noi/ -->

          <!-- <div class="modal__btn" v-else>
            <a
              :href="azione"
              class="btn btn--primary"
              @click="goalCTA(missionId, op, id, extra)"
              target="_blank"
            >
              {{ btn }} <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//components
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

//composables
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import getSubcollection from '@/composables/getSubcollection'
import getUser from '@/composables/getUser'
import useDocument from '@/composables/useDocument'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  props: [
    'azione',
    'btn',
    'description',
    'extra',
    'id',
    'gold',
    'missionId',
    'name',
    'opStart',
    'paragraph',
    'silver',
  ],

  setup(props) {
    //user
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)
    const { updateDoc } = useDocument('utenti', user.value.uid)

    //fasce
    const { documents: fasce } = getCollection('fasce-prezzo')

    //obiettivi
    const { documents: obiettiviAction } = getCollection('obiettivi', [
      'categoria',
      '==',
      'action',
    ])

    //obiettivi utente
    const { documents: usrGoals } = getSubcollection(
      'utenti',
      user.value.uid,
      'obiettivi',
      '',
      'raggiunto'
    )

    //props
    const azione = ref(props.azione)
    const btn = ref(props.btn)
    const description = ref(props.description)
    const gold = ref(props.gold)
    const id = ref(props.id)
    const extra = ref(props.extra)
    const missionId = ref(props.missionId)
    const name = ref(props.name)
    const opStart = ref(props.opStart)
    const paragraph = ref(props.paragraph)
    const silver = ref(props.silver)

    //goalCTA
    const goalCTA = async (missionId, op, id, extra) => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('missioni')
        .doc(missionId)
        .update({
          action: 'conquistata',
          shopping: 'attiva',
          carteConquistate: firebase.firestore.FieldValue.increment(1),
        })

      //update opTotal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          opTotal: firebase.firestore.FieldValue.increment(op),
          obiettiviActionStandard: firebase.firestore.FieldValue.increment(1),
        })

      if (extra) {
        //update opTotal if extra
        projectFirestore
          .collection('utenti')
          .doc(user.value.uid)
          .update({
            opTotal: firebase.firestore.FieldValue.increment(extra),
          })
      }

      //update goal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('obiettivi')
        .doc(id)
        .update({
          raggiunto: true,
        })

      showModal.value = false
      router.push({ name: 'Missions' })

      console.log('aggiornato!')
    }

    const router = useRouter()
    const route = useRoute()

    router.beforeEach(({ to, from, redirect, next }) => {
      console.log(from)
    })

    //modal
    const modal = ref()
    const showModal = ref(false)
    const displayModal = (goal) => {
      modal.value = goal
      showModal.value = true
    }

    return {
      azione,
      btn,
      description,
      displayModal,
      fasce,
      id,
      extra,
      goalCTA,
      gold,
      missionId,
      modal,
      name,
      obiettiviAction,
      opStart,
      paragraph,
      silver,
      showModal,
      usrGoals,
      utente,
    }
  },
}
</script>

<style></style>
