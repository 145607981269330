<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />

    <!-- SHOPPING FILTER -->
    <ul class="shop-filter">
      <li
        class="shop-filter__item"
        v-for="category in shopCategories"
        :key="category"
      >
        <div
          class="shop-filter__btn"
          :class="{ 'shop-filter__btn--active': category === activeCat }"
          @click="activeCat = category"
        >
          {{ category }}
        </div>
      </li>
    </ul>

    <!-- SHOPPING GRID -->
    <div v-if="utente" class="shopping-grid">
      <span
        class="shopping-grid__item"
        v-for="servizio in servizi"
        :key="servizio.id"
      >
        <article
          class="service"
          v-if="servizio.categoria === activeCat || activeCat === 'Tutti'"
        >
          <div v-for="shopItem in utente.shop" :key="shopItem">
            <button
              v-if="servizio.nome === shopItem"
              class="service__close"
              @click="handleDelete(servizio.nome, servizio.op)"
            >
              <i class="bx bx-x"></i>
            </button>
          </div>

          <div class="service__content" ref="service">
            <figure class="service__thumb">
              <a href="#">
                <img
                  :src="getImgUrl(servizio.immagine)"
                  alt="Cassa portatile"
                />
              </a>
            </figure>

            <h3 class="service__name">
              {{ servizio.nome }}
            </h3>

            <div class="service__btn">
              <a
                v-show="servizio.link"
                :href="servizio.link"
                class="btn btn--outline"
                target="_blank"
                >Scopri di più</a
              >
            </div>

            <p
              style="display: block; font-size: 1.2rem; line-height: 1; margin-top: 1rem"
            >
              Mandaci la richiesta.<br />
              Ti contatteremo per la conferma.
            </p>
          </div>

          <div class="service__footer">
            <div class="service__price">
              <span class="ommepoints">{{ servizio.op }}</span> |
              <span class="price">{{ servizio.prezzo }}€</span>
            </div>

            <!--<button
              class="service__cart"
              @click="handleSubmit(servizio.nome, servizio.op)"
            >
              <i class="bx bx-cart-alt"></i>
            </button>-->

            <a
              class="service__cart"
              target="_blank"
              :href="
                'https://wa.me/393923727778/?text=Ciao, siamo ' +
                  utente.nomeUtente +
                  '. Vorremmo aggiungere ' +
                  servizio.nome +
                  '. Chiediamo conferma. Grazie!'
              "
              ><i class="bx bxl-whatsapp"></i
            ></a>
          </div>
        </article>
      </span>
    </div>

    <section class="shop-total">
      <div class="shop-total__counter">
        Totale
        <div>
          <i class="bx bx-cart-alt"></i>
          <span v-if="utente">{{ utente.shop.length }}</span>
        </div>
      </div>
      <div class="shop-total__btn">
        <router-link :to="{ name: 'Services' }" class="btn btn--primary"
          >I vostri servizi</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'

import { projectFirestore } from '@/firebase/config'
import firebase from 'firebase/app'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

import { ref } from 'vue'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { documents: servizi } = getCollection('servizi', '', 'prezzo')

    const { document: tutorial } = getDocument('tutorial', 'omme-shop')

    const { error, document: utente } = getDocument('utenti', user.value.uid)
    const { updateDoc } = useDocument('utenti', user.value.uid)

    const service = ref(null)

    const shopCategories = [
      'Tutti',
      'Cerimonia',
      'Aperitivo',
      'Giochi',
      'Animazione bimbi',
      'Scenografia',
    ]

    const activeCat = ref('Tutti')

    const getImgUrl = (img) => {
      const images = require.context(
        '@/assets/images/services/',
        false,
        /\.jpg$/
      )

      return images('./' + img + '.jpg')
    }

    const upDoc = async () => {
      const newMissions = {
        500: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 500,
          stato: 'attiva',
        },
        1000: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 1000,
          stato: 'attiva',
        },
        1500: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 1500,
          stato: 'attiva',
        },
        2000: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 2000,
          stato: 'attiva',
        },
        2500: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 2500,
          stato: 'attiva',
        },
        3000: {
          carte: {
            action: 'bloccata',
            shopping: 'bloccata',
            social: 'attiva',
          },
          carteConquistate: 0,
          op: 3000,
          stato: 'attiva',
        },
      }

      const newCerimonia = {
        indirizzo: '',
        ora: '',
        tipologia: '',
      }

      const newGift300 = {
        nome: 'gift-300',
        op: 300,
        quantita: 0,
        stato: 'disponibile',
        total: 0,
      }

      const newGift600 = {
        nome: 'gift-600',
        op: 600,
        quantita: 0,
        stato: 'disponibile',
        total: 0,
      }

      const newGift1000 = {
        nome: 'gift-1000',
        op: 1000,
        quantita: 0,
        stato: 'disponibile',
        total: 0,
      }
      const res = await updateDoc({
        buoni: [],
        cerimonia: newCerimonia,
        contratto: [],
        durataMatrimonio: '',
        forzieri: [],
        gift: [newGift300, newGift600, newGift1000],
        missioni: newMissions,
        repertorioCivile: {
          entrataSposo: '',
          entrataSposa: '',
          intro: '',
          promesse: '',
          rito: '',
          articoli: '',
          atto: '',
          firme: '',
          uscita: '',
          altro: '',
        },
        repertorioReligioso: {
          alleluia: '',
          comunione: '',
          entrataSposa: '',
          entrataSposo: '',
          firme: '',
          offertorio: '',
          rito: '',
        },
        repertorioRicevimento: {
          altro: '',
          arrivo: '',
          artistiNo: '',
          artistiSi: '',
          ballo: '',
          ingresso: '',
          torta: '',
        },
        ricevimento: {
          indirizzo: '',
          location: '',
        },
        sconti: [],
        shop: [],
        sposo1: {
          email: '',
          indirizzo: '',
          nome: '',
          telefono: '',
        },
        sposo2: {
          email: '',
          indirizzo: '',
          nome: '',
          telefono: '',
        },
      })
    }

    const handleSubmit = async (servizio, op) => {
      const res = await updateDoc({
        shop: firebase.firestore.FieldValue.arrayUnion(servizio),
        opTotal: firebase.firestore.FieldValue.increment(op),
        shopTotal: firebase.firestore.FieldValue.increment(op),
      })
    }

    const handleDelete = async (servizio, op) => {
      const res = await updateDoc({
        shop: firebase.firestore.FieldValue.arrayRemove(servizio),
        opTotal: firebase.firestore.FieldValue.increment(-op),
        shopTotal: firebase.firestore.FieldValue.increment(-op),
      })
    }

    return {
      shopCategories,
      activeCat,
      servizi,
      getImgUrl,
      utente,
      error,
      handleSubmit,
      handleDelete,
      upDoc,
      tutorial,
    }
  },
}
</script>

<style scoped>
.service__btn {
  min-height: 26px;
}
</style>
