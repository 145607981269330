<template>
  <div>
    <Bg />

    <div class="gifts" v-if="utente">
      <div class="gift" v-for="gift in gifts" :key="gift">
        <div class="gift__card gift__card--locked">
          <div class="gift__front" @click.self="displayModal(gift.op)">
            <img class="gift__image" :src="utente.fotoProfilo" alt="" />
            <span v-if="gift.quantita" class="gift__label"
              >Regalate: {{ gift.quantita }}</span
            >
            <h2 class="gift__couple">{{ utente.nomeUtente }}</h2>
            <div class="gift__date">
              {{
                utente.dataMatrimonio.toDate().toLocaleString('it-IT', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })
              }}
            </div>
            <div class="gift__price">100€</div>
          </div>

          <div class="gift__overlay" v-if="gift.stato === 'bloccata'">
            <i class="bx bxs-lock"></i>
            <div>
              Regala la Gift Card da 100€ +
              <span class="ommepoints">600</span> per sbloccarmi
            </div>
          </div>
        </div>
        <span class="gift__plus">+</span>
        <div class="gift__ommepoints">
          <span class="ommepoints">{{ gift.op }}</span>
        </div>
      </div>
    </div>

    <!-- modal300 -->
    <div v-if="showModal300" class="modal modal--gift">
      <div class="modal__overlay">
        <div class="modal__card">
          <button class="modal__close" @click="showModal300 = false">
            <i class="bx bx-x"></i>
          </button>

          <div class="modal__body">
            <h3 class="modal__title">Gift Card</h3>

            <p class="modal__paragraph">
              Regalami a una coppia di amici che sta per sposarsi e portali in
              OMME MUSIC. Per te
            </p>

            <div class="modal__price">
              100€
            </div>

            <p class="modal__paragraph">di sconto sui nostri servizi</p>

            <div class="modal__ommepoints modal__ommepoints--small">
              <span class="ommepoints">+300</span>
            </div>

            <div class="modal__link">
              <a
                href="https://wa.me/?text=Ciao!%20Ti%20regaliamo%20una%20Gift%20Card%20OMME%20Music%20dal%20valore%20di%20100€!%20Clicca%20sul%20link%20per%20scaricarla%20e%20scoprire%20di%20più:%20https:%2F%2Fareasposi.ommemusic.it%2Finvia-gift-card"
                class="btn btn--secondary"
                target="_blank"
                @click="sendGift(300)"
              >
                Inviami! <i class="bx bx-right-arrow-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal600 -->
    <div v-if="showModal600" class="modal modal--gift">
      <div class="modal__overlay">
        <div class="modal__card">
          <button class="modal__close" @click="showModal600 = false">
            <i class="bx bx-x"></i>
          </button>

          <div class="modal__body">
            <h3 class="modal__title">Gift Card</h3>

            <p class="modal__paragraph">
              Regalami a una coppia di amici che sta per sposarsi e portali in
              OMME MUSIC. Per te
            </p>

            <div class="modal__price">
              100€
            </div>

            <p class="modal__paragraph">di sconto sui nostri servizi</p>

            <div class="modal__ommepoints modal__ommepoints--small">
              <span class="ommepoints">+600</span>
            </div>

            <div class="modal__link">
              <a
                href="https://wa.me/?text=Ciao!%20Ti%20regaliamo%20una%20Gift%20Card%20OMME%20Music%20dal%20valore%20di%20100€!%20Clicca%20sul%20link%20per%20scaricarla%20e%20scoprire%20di%20più:%20https:%2F%2Fareasposi.ommemusic.it%2Finvia-gift-card"
                target="_blank"
                class="btn btn--secondary"
                @click="sendGift(600)"
              >
                Inviami! <i class="bx bx-right-arrow-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal600 -->
    <div v-if="showModal1000" class="modal modal--gift">
      <div class="modal__overlay">
        <div class="modal__card">
          <button class="modal__close" @click="showModal1000 = false">
            <i class="bx bx-x"></i>
          </button>

          <div class="modal__body">
            <h3 class="modal__title">Gift Card</h3>

            <p class="modal__paragraph">
              Regalami a una coppia di amici che sta per sposarsi e portali in
              OMME MUSIC. Per te
            </p>

            <div class="modal__price">
              100€
            </div>

            <p class="modal__paragraph">di sconto sui nostri servizi</p>

            <div class="modal__ommepoints modal__ommepoints--small">
              <span class="ommepoints">+1000</span>
            </div>

            <div class="modal__link">
              <a
                href="https://wa.me/?text=Ciao!%20Ti%20regaliamo%20una%20Gift%20Card%20OMME%20Music%20dal%20valore%20di%20100€!%20Clicca%20sul%20link%20per%20scaricarla%20e%20scoprire%20di%20più:%20https:%2F%2Fareasposi.ommemusic.it%2Finvia-gift-card"
                class="btn btn--secondary"
                target="_blank"
                @click="sendGift(1000)"
              >
                Inviami! <i class="bx bx-right-arrow-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import { getCurrentInstance, ref } from 'vue'
import getSubcollection from '@/composables/getSubcollection'

import Bg from '@/components/Bg.vue'
import Modal from '@/components/Modal.vue'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  components: { Bg, Modal },
  setup() {
    const show = ref(false)

    const { emit } = getCurrentInstance()
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    //gift cards utente
    const { documents: gifts } = getSubcollection(
      'utenti',
      user.value.uid,
      'gift',
      '',
      'op'
    )

    const showModal300 = ref(false)
    const showModal600 = ref(false)
    const showModal1000 = ref(false)

    const displayModal = (op) => {
      if (op === 300) {
        showModal300.value = true
      }

      if (op === 600) {
        showModal600.value = true
      }

      if (op === 1000) {
        showModal1000.value = true
      }
    }

    const sendGift = async (op) => {
      //incrementa il contatore
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('gift')
        .doc('' + op)
        .update({
          quantita: firebase.firestore.FieldValue.increment(1),
        })

      /* //cambia stato della carta successiva
      if (op === 300) {
        projectFirestore
          .collection('utenti')
          .doc(user.value.uid)
          .collection('gift')
          .doc('' + 600)
          .update({
            stato: 'disponibile',
          })
      }
      if (op === 600) {
        projectFirestore
          .collection('utenti')
          .doc(user.value.uid)
          .collection('gift')
          .doc('' + 1000)
          .update({
            stato: 'disponibile',
          })
      } */

      //incrementa gli opTotal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          //opTotal: firebase.firestore.FieldValue.increment(op),
          //giftTotal: firebase.firestore.FieldValue.increment(op),
          giftNum: firebase.firestore.FieldValue.increment(1),
        })

      showModal300.value = false
      showModal600.value = false
      showModal1000.value = false

      console.log('aggiornato!')
    }

    return {
      utente,
      error,
      displayModal,
      showModal300,
      showModal600,
      showModal1000,
      sendGift,
      gifts,
    }
  },
}
</script>
