<template>
  <div>
    <h1 class="heading">
      MediaPics<br />
      <span>Foto e video</span>
    </h1>
    <div class="panel">
      <img
        src="@/assets/images/biglietto-mediapics.gif"
        alt="Biglietto MediaPics"
      />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.heading {
  margin-top: 3rem;
}
.panel {
  margin-top: 1rem;
  text-align: center;
}

.btn {
  margin: 0 auto;
}
</style>
