<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <div style="margin-top: 10rem" v-if="utente">
      <div
        class="modal"
        v-if="
          utente.forzieriOro &&
            utente.forzieriBronzo &&
            utente.obiettiviSocialStandard >= 4 &&
            utente.obiettiviActionStandard >= 4 &&
            utente.forzieriArgento &&
            utente.puzzleComplete &&
            utente.modalComplete === 1
        "
      >
        <div class="modal__overlay">
          <div class="modal__card">
            <div class="modal__body">
              <h3 class="modal__title" style="margin-bottom: 2rem">
                Hai completato il puzzle!
              </h3>

              <img
                style="margin-bottom: 3rem"
                src="@/assets/images/icons/icon-confetti.svg"
                alt=""
              />

              <div v-for="fascia in fasce" :key="fascia" style="display: none">
                <div
                  v-if="
                    utente.opStart >= fascia.opStart.min &&
                      utente.opStart <= fascia.opStart.max
                  "
                >
                  {{ (opBonus = fascia.premio) }}
                </div>
              </div>

              <button class="btn btn--primary" @click="add(opBonus)">
                <router-link :to="{ name: 'Piggybank' }"
                  >Ottieni i premi</router-link
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Bg />
    <div class="trip">
      <div class="trip__pride">
        <div class="trip__ribbon">
          <div class="ribbon">
            <div class="ribbon__content">Premio finale</div>
          </div>
        </div>

        <div class="trip__content">
          <div class="trip__content--left">
            <div class="trip__price">
              <span>40€</span>
              <span>sconto</span>
            </div>
            <div v-for="fascia in fasce" :key="fascia">
              <div
                class="ommepoint"
                style="margin-left: 2rem"
                v-if="
                  utente.opStart >= fascia.opStart.min &&
                    utente.opStart <= fascia.opStart.max
                "
              >
                <span>{{ fascia.premio }}</span>
                <span>ommepoint</span>
              </div>
            </div>
          </div>

          <div class="trip__content--right">
            <img
              v-if="utente && utente.fotoProfilo"
              :src="utente.fotoProfilo"
            />
            <div>Stampa foto</div>
          </div>
        </div>
      </div>

      <div class="trip__road">
        <ul class="trip__pieces" v-if="utente">
          <!-- pezzo 8 -->
          <li class="trip__piece pezzo8">
            <div @click="showModal8 = true">
              <img
                v-if="
                  utente.forzieriOro &&
                    utente.forzieriBronzo &&
                    utente.obiettiviSocialStandard >= 4 &&
                    utente.obiettiviActionStandard >= 4 &&
                    utente.forzieriArgento
                "
                src="@/assets/images/trip/pieces/complete/piece-complete-8.svg"
              />
              <img
                v-else
                src="@/assets/images/trip/pieces/locked/piece-locked-8.svg"
              />

              <div
                v-if="
                  utente.forzieriBronzo &&
                    utente.obiettiviSocialStandard >= 4 &&
                    utente.obiettiviActionStandard >= 4 &&
                    utente.forzieriArgento > 0 &&
                    utente.forzieriOro > 0
                "
              >
                {{ updateDiscount40() }}
              </div>
            </div>

            <div class="piece__coffer">
              <router-link
                :to="{ name: 'CoffersGold' }"
                v-if="!utente.forzieriOro"
              >
                <img
                  class="coffer-gold"
                  src="@/assets/images/icons/icon-coffer-gold.svg"
                />
              </router-link>
              <img
                v-else
                class="coffer-gold"
                src="@/assets/images/icons/icon-coffer-gold.svg"
              />
            </div>

            <div class="modal" v-if="showModal8 === true">
              <div class="modal__overlay" @click.self="showModal8 = false">
                <div class="modal__card">
                  <button class="modal__close" @click="showModal8 = false">
                    <i class="bx bx-x"></i>
                  </button>

                  <div class="modal__body">
                    <h3
                      class="modal__title"
                      v-if="
                        utente.forzieriOro === 0 &&
                          utente.forzieriBronzo === 0 &&
                          utente.obiettiviSocialStandard < 4 &&
                          utente.obiettiviActionStandard < 4 &&
                          utente.forzieriArgento === 0
                      "
                    >
                      Acquista un forziere oro
                    </h3>

                    <h3 class="modal__title" v-else>
                      Pezzo conquistato!
                    </h3>

                    <p
                      class="modal__paragraph"
                      v-if="
                        utente.forzieriOro === 0 &&
                          utente.forzieriBronzo === 0 &&
                          utente.obiettiviSocialStandard < 4 &&
                          utente.obiettiviActionStandard < 4 &&
                          utente.forzieriArgento === 0
                      "
                    >
                      Per conquistare questo pezzo devi acquistare almeno un
                      forziere oro.
                    </p>

                    <img
                      v-if="utente.forzieriOro === 0"
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/locked/piece-locked-8.svg"
                    />

                    <img
                      v-else
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/complete/piece-complete-8.svg"
                    />

                    <div
                      class="modal__btn"
                      v-if="
                        utente.forzieriOro === 0 &&
                          utente.forzieriBronzo === 0 &&
                          utente.obiettiviSocialStandard < 4 &&
                          utente.obiettiviActionStandard < 4 &&
                          utente.forzieriArgento === 0
                      "
                    >
                      <router-link
                        :to="{ name: 'Coffers' }"
                        class="btn btn--primary"
                      >
                        Acquista!
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- pezzo 7 -->
          <li class="trip__piece pezzo7">
            <div @click="showModal7 = true">
              <img
                v-if="
                  utente.forzieriBronzo &&
                    utente.obiettiviSocialStandard >= 4 &&
                    utente.obiettiviActionStandard >= 4 &&
                    utente.forzieriArgento > 0
                "
                src="@/assets/images/trip/pieces/complete/piece-complete-7.svg"
              />
              <img
                v-else
                src="@/assets/images/trip/pieces/locked/piece-locked-7.svg"
              />

              <div
                v-if="
                  utente.forzieriBronzo &&
                    utente.obiettiviSocialStandard >= 4 &&
                    utente.obiettiviActionStandard >= 4 &&
                    utente.forzieriArgento > 0
                "
              >
                {{ updateDiscount(30) }}
              </div>
            </div>
            <div class="piece__tooltip piece__tooltip--top">
              Sconto <span>30€</span>
            </div>

            <div class="modal" v-if="showModal7 === true">
              <div class="modal__overlay" @click.self="showModal7 = false">
                <div class="modal__card">
                  <button class="modal__close" @click="showModal7 = false">
                    <i class="bx bx-x"></i>
                  </button>

                  <div class="modal__body">
                    <h3
                      class="modal__title"
                      v-if="
                        utente.forzieriBronzo === 0 &&
                          utente.obiettiviSocialStandard < 4 &&
                          utente.obiettiviActionStandard < 4 &&
                          utente.forzieriArgento === 0
                      "
                    >
                      Conquista tutti i pezzi precedenti
                    </h3>

                    <h3 class="modal__title" style="margin-bottom: 3rem" v-else>
                      Pezzo conquistato!
                    </h3>

                    <p
                      class="modal__paragraph"
                      v-if="utente.forzieriArgento === 0"
                    >
                      Per conquistare questo pezzo devi aver conquistato tutti i
                      pezzi precedenti
                    </p>

                    <img
                      v-if="utente.forzieriArgento === 0"
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/locked/piece-locked-7.svg"
                    />

                    <img
                      v-else
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/complete/piece-complete-7.svg"
                    />

                    <div class="modal__btn" v-if="utente.forzieriArgento === 0">
                      <a
                        @click.prevent="showModal7 = false"
                        class="btn btn--primary"
                      >
                        Torna al puzzle
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- pezzo 6 -->
          <li class="trip__piece pezzo6">
            <div v-for="forziere in utente.forzieri" :key="forziere"></div>
            <div @click="showModal6 = true">
              <img
                v-if="
                  utente.forzieriBronzo &&
                    utente.obiettiviSocialStandard >= 4 &&
                    utente.obiettiviActionStandard >= 4 &&
                    utente.forzieriArgento > 0
                "
                src="@/assets/images/trip/pieces/complete/piece-complete-6.svg"
              />
              <img
                v-else
                src="@/assets/images/trip/pieces/locked/piece-locked-6.svg"
              />
            </div>

            <div class="piece__coffer">
              <router-link
                :to="{ name: 'CoffersSilver' }"
                v-if="!utente.forzieriArgento"
              >
                <img
                  class="coffer-silver"
                  src="@/assets/images/icons/icon-coffer-silver.svg"
                />
              </router-link>
              <img
                v-else
                class="coffer-silver"
                src="@/assets/images/icons/icon-coffer-silver.svg"
              />
            </div>

            <div class="modal" v-if="showModal6 === true">
              <div class="modal__overlay" @click.self="showModal6 = false">
                <div class="modal__card">
                  <button class="modal__close" @click="showModal6 = false">
                    <i class="bx bx-x"></i>
                  </button>

                  <div class="modal__body">
                    <h3
                      class="modal__title"
                      v-if="utente.forzieriArgento === 0"
                    >
                      Acquista un forziere argento
                    </h3>

                    <h3 class="modal__title" style="margin-bottom: 3rem" v-else>
                      Pezzo conquistato
                    </h3>

                    <p
                      class="modal__paragraph"
                      v-if="utente.forzieriArgento === 0"
                    >
                      Per conquistare questo pezzo devi acquistare almeno un
                      forziere argento.
                    </p>

                    <img
                      v-if="utente.forzieriArgento === 0"
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/locked/piece-locked-6.svg"
                    />

                    <img
                      v-else
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/complete/piece-complete-6.svg"
                    />

                    <div class="modal__btn" v-if="utente.forzieriArgento === 0">
                      <router-link
                        :to="{ name: 'Coffers' }"
                        class="btn btn--primary"
                      >
                        Acquista!
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- pezzo 5 -->
          <li class="trip__piece pezzo5">
            <div @click="showModal5 = true">
              <img
                v-if="
                  utente.forzieriBronzo &&
                    utente.obiettiviSocialStandard >= 4 &&
                    utente.obiettiviActionStandard >= 4
                "
                src="@/assets/images/trip/pieces/complete/piece-complete-5.svg"
              />
              <img
                v-else
                src="@/assets/images/trip/pieces/locked/piece-locked-5.svg"
              />
            </div>

            <div class="modal" v-if="showModal5">
              <div class="modal__overlay" @click.self="showModal5 = false">
                <div class="modal__card">
                  <button class="modal__close" @click="showModal5 = false">
                    <i class="bx bx-x"></i>
                  </button>

                  <div class="modal__body">
                    <h3
                      class="modal__title"
                      v-if="utente.obiettiviActionStandard < 4"
                    >
                      Conquista tutte le carte action
                    </h3>

                    <h3 class="modal__title" v-else>
                      Pezzo conquistato
                    </h3>

                    <p
                      class="modal__paragraph"
                      v-if="utente.obiettiviActionStandard < 4"
                    >
                      Per conquistare questo pezzo devi raggiungere tutti gli
                      <strong>obiettivi action</strong> ad eccezione degli
                      obiettivi <strong>silver</strong> e <strong>gold</strong>
                    </p>

                    <img
                      v-if="utente.obiettiviActionStandard < 4"
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/locked/piece-locked-5.svg"
                    />

                    <img
                      v-else
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/complete/piece-complete-5.svg"
                    />

                    <div
                      class="modal__btn"
                      v-if="utente.obiettiviActionStandard < 4"
                    >
                      <router-link
                        :to="{ name: 'Missions' }"
                        class="btn btn--primary"
                      >
                        Vai agli obiettivi action
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- pezzo 4 -->
          <li class="trip__piece pezzo4">
            <div @click="showModal4 = true">
              <img
                v-if="
                  utente.forzieriBronzo && utente.obiettiviSocialStandard >= 4
                "
                src="@/assets/images/trip/pieces/complete/piece-complete-4.svg"
              />
              <img
                v-else
                src="@/assets/images/trip/pieces/locked/piece-locked-4.svg"
              />

              <div
                v-if="
                  utente.forzieriBronzo && utente.obiettiviSocialStandard >= 4
                "
              >
                {{ updateDiscount(20) }}
              </div>
            </div>
            <div class="piece__tooltip piece__tooltip--top">
              Sconto <span>20€</span>
            </div>

            <div class="modal" v-if="showModal4 === true">
              <div class="modal__overlay" @click.self="showModal4 = false">
                <div class="modal__card">
                  <button class="modal__close" @click="showModal4 = false">
                    <i class="bx bx-x"></i>
                  </button>

                  <div class="modal__body">
                    <h3
                      class="modal__title"
                      v-if="utente.forzieri.length === 0"
                    >
                      Conquista tutti i pezzi precedenti
                    </h3>

                    <h3 class="modal__title" style="margin-bottom: 3rem" v-else>
                      Pezzo conquistato
                    </h3>

                    <p
                      class="modal__paragraph"
                      v-if="utente.forzieri.length === 0"
                    >
                      Per conquistare questo pezzo devi aver conquistato tutti i
                      pezzi precedenti
                    </p>

                    <img
                      v-if="utente.forzieri.length === 0"
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/locked/piece-locked-4.svg"
                    />

                    <img
                      v-else
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/complete/piece-complete-4.svg"
                    />

                    <div class="modal__btn" v-if="utente.forzieri.length === 0">
                      <a
                        @click.prevent="showModal4 = false"
                        class="btn btn--primary"
                      >
                        Torna al puzzle
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- pezzo 3 -->
          <li class="trip__piece pezzo3">
            <div @click="showModal3 = true">
              <img
                v-if="
                  utente.forzieriBronzo && utente.obiettiviSocialStandard >= 4
                "
                src="@/assets/images/trip/pieces/complete/piece-complete-3.svg"
              />
              <img
                v-else
                src="@/assets/images/trip/pieces/locked/piece-locked-3.svg"
              />
            </div>

            <div class="piece__coffer">
              <router-link
                :to="{ name: 'CoffersBronze' }"
                v-if="!utente.forzieriBronzo"
              >
                <img
                  class="coffer-bronze"
                  src="@/assets/images/icons/icon-coffer-bronze.svg"
                />
              </router-link>
              <img
                v-else
                class="coffer-bronze"
                src="@/assets/images/icons/icon-coffer-bronze.svg"
              />
            </div>

            <div class="modal" v-if="showModal3 === true">
              <div class="modal__overlay" @click.self="showModal3 = false">
                <div class="modal__card">
                  <button class="modal__close" @click="showModal3 = false">
                    <i class="bx bx-x"></i>
                  </button>

                  <div class="modal__body">
                    <h3
                      class="modal__title"
                      v-if="utente.forzieri.length === 0"
                    >
                      Acquista un forziere bronzo
                    </h3>

                    <h3 class="modal__title" style="margin-bottom: 3rem" v-else>
                      Pezzo conquistato
                    </h3>

                    <p
                      class="modal__paragraph"
                      v-if="utente.forzieri.length === 0"
                    >
                      Per conquistare questo pezzo devi acquistare almeno un
                      forziere bronzo.
                    </p>

                    <img
                      v-if="utente.forzieri.length === 0"
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/locked/piece-locked-3.svg"
                    />

                    <img
                      v-else
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/complete/piece-complete-3.svg"
                    />

                    <div class="modal__btn" v-if="utente.forzieri.length === 0">
                      <router-link
                        :to="{ name: 'Coffers' }"
                        class="btn btn--primary"
                      >
                        Acquista!
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- pezzo 2 -->
          <li class="trip__piece pezzo2">
            <div @click="showModal2 = true">
              <img
                v-if="utente.obiettiviSocialStandard >= 4"
                src="@/assets/images/trip/pieces/complete/piece-complete-2.svg"
              />
              <img
                v-else
                src="@/assets/images/trip/pieces/locked/piece-locked-2.svg"
              />
            </div>

            <div class="modal" v-if="showModal2">
              <div class="modal__overlay" @click.self="showModal2 = false">
                <div class="modal__card">
                  <button class="modal__close" @click="showModal2 = false">
                    <i class="bx bx-x"></i>
                  </button>

                  <div class="modal__body">
                    <h3
                      class="modal__title"
                      v-if="utente.obiettiviSocialStandard < 4"
                    >
                      Conquista tutte le carte social
                    </h3>

                    <h3 class="modal__title" style="margin-bottom: 3rem" v-else>
                      Pezzo conquistato!
                    </h3>

                    <p
                      class="modal__paragraph"
                      v-if="utente.obiettiviSocialStandard < 4"
                    >
                      Per conquistare questo pezzo devi raggiungere tutti gli
                      <strong>obiettivi social</strong> ad eccezione degli
                      obiettivi <strong>silver</strong> e <strong>gold</strong>
                    </p>

                    <img
                      v-if="utente.obiettiviSocialStandard < 4"
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/locked/piece-locked-2.svg"
                    />

                    <img
                      v-else
                      style="width: 10rem"
                      src="@/assets/images/trip/pieces/complete/piece-complete-2.svg"
                    />

                    <div
                      class="modal__btn"
                      v-if="utente.obiettiviSocialStandard < 4"
                    >
                      <router-link
                        :to="{ name: 'Missions' }"
                        class="btn btn--primary"
                      >
                        Vai agli obiettivi social
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <!-- pezzo1 -->
          <li class="trip__piece pezzo1">
            <img
              src="@/assets/images/trip/pieces/complete/piece-complete-1.svg"
            />
          </li>
        </ul>

        <div class="trip__path" v-for="piece in pieces" :key="piece">
          <img src="@/assets/images/trip/paths/path-1.svg" />
          <img src="@/assets/images/trip/paths/path-2.svg" />
          <img src="@/assets/images/trip/paths/path-3.svg" />
          <img src="@/assets/images/trip/paths/path-4.svg" />
          <img src="@/assets/images/trip/paths/path-5.svg" />
          <img src="@/assets/images/trip/paths/path-6.svg" />
          <img src="@/assets/images/trip/paths/path-7.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import getSubcollection from '@/composables/getSubcollection'
import getUser from '@/composables/getUser'
import useDocument from '@/composables/useDocument'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

import { onMounted, ref } from 'vue'

export default {
  components: { Bg, Topbar },

  setup() {
    //user
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)
    const { updateDoc } = useDocument('utenti', user.value.uid)

    const { document: tutorial } = getDocument('tutorial', 'puzzle-bonus')

    const countArgento = 0
    const countOro = 0

    //fasce
    const { documents: fasce } = getCollection('fasce-prezzo')

    //obiettivi utente
    const { documents: pieces } = getSubcollection(
      'utenti',
      user.value.uid,
      'puzzle',
      '',
      'conquistato'
    )

    //modal
    const showModal2 = ref(false)
    const showModal3 = ref(false)
    const showModal4 = ref(false)
    const showModal5 = ref(false)
    const showModal6 = ref(false)
    const showModal7 = ref(false)
    const showModal8 = ref(false)

    const modalCompleteNum = ref(0)

    const updateDiscount = (op) => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          sconti: firebase.firestore.FieldValue.arrayUnion(op),
        })
    }

    const add = (op) => {
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          opTotal: firebase.firestore.FieldValue.increment(op),
        })

      if (modalCompleteNum.value === 1) {
        projectFirestore
          .collection('utenti')
          .doc(user.value.uid)
          .update({
            modalComplete: firebase.firestore.FieldValue.increment(1),
          })
      }
    }

    const updateDiscount40 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          sconti: firebase.firestore.FieldValue.arrayUnion(40),
          puzzleComplete: true,
        })
      modalCompleteNum.value = 1

      if (modalCompleteNum.value === 0) {
        projectFirestore
          .collection('utenti')
          .doc(user.value.uid)
          .update({
            modalComplete: 1,
          })
      }
    }

    const updatePiece8 = () => {
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({})
    }

    return {
      utente,
      pieces,
      countArgento,
      countOro,
      fasce,
      tutorial,
      showModal2,
      showModal3,
      showModal4,
      showModal5,
      showModal6,
      showModal7,
      showModal8,
      updateDiscount,
      updatePiece8,
      updateDiscount40,
      modalCompleteNum,
      add,
    }
  },
}
</script>

<style scoped>
.modal {
  z-index: 999999 !important;
}
</style>
