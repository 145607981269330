<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />
    <div class="box" v-if="utente">
      <div class="box__content">
        <p>
          In questa sezione compariranno tutte le foto di Instagram pubblicate
          dai vostri invitati il giorno del vostro matrimonio e taggate con
          l'hashtag che avete scelto.
        </p>

        <div class="text-center" v-if="utente.hashtag">
          <h3 class="box__hashtag">{{ utente.hashtag }}</h3>

          <router-link :to="{ name: 'Settings' }" class="box__edit"
            >Modifica l'hashtag</router-link
          >
        </div>
        <div v-else>
          <p>
            Non avete ancora scelto l'hashtag? Per farlo basta aggiungerlo nelle
            impostazioni:
          </p>
          <div class="text-center">
            <router-link :to="{ name: 'Settings' }" class="box__edit"
              >Imposta l'hashtag</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { err, document: tutorial } = getDocument('tutorial', 'instagallery')

    return { utente, error, tutorial }
  },
}
</script>

<style scoped>
.box {
  margin-top: 10rem;
}
</style>
