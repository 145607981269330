<template>
  <section class="tracklist" v-if="utente">
    <div class="tracklist__header">
      <div class="tracklist__icon">
        <img src="@/assets/images/icons/icon-ring.svg" />
      </div>
      <h2 class="tracklist__title">
        Cerimonia civile
      </h2>
      <div class="tracklist__btn">
        <button
          class="btn btn--primary"
          @click.prevent="isEditingCivile = !isEditingCivile"
        >
          Modifica
        </button>
      </div>
    </div>

    <form class="tracklist__list" @submit.prevent="editCivile">
      <div class="tracklist__item">
        <label>Entrata Sposo/a:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.entrataSposo"
          v-model="entrataSposo1C"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.entrataSposo">{{
            utente.repertorioCivile.entrataSposo
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Entrata Sposo/a:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.entrataSposa"
          v-model="entrataSposo2C"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.entrataSposa">{{
            utente.repertorioCivile.entrataSposa
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Intro celebrante:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.intro"
          v-model="intro"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.intro">{{
            utente.repertorioCivile.intro
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Scambio promesse + anelli</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.promesse"
          v-model="promesse"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.promesse">{{
            utente.repertorioCivile.promesse
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Rito matrimonio e scambio fedi:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.rito"
          v-model="rito"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.rito">{{
            utente.repertorioCivile.rito
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Lettura articoli/consenso:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.articoli"
          v-model="articoli"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.articoli">{{
            utente.repertorioCivile.articoli
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Lettura atto:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.atto"
          v-model="atto"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.atto">{{
            utente.repertorioCivile.atto
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Firme:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.firme"
          v-model="firme"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.firme">{{
            utente.repertorioCivile.firme
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Uscita:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.uscita"
          v-model="uscita"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.uscita">{{
            utente.repertorioCivile.uscita
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Altro:</label>
        <input
          type="text"
          :placeholder="utente.repertorioCivile.altro"
          v-model="altro"
          v-if="isEditingCivile"
        />
        <p v-else>
          <small v-if="utente.repertorioCivile.altro">{{
            utente.repertorioCivile.altro
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <button @click="updateGoal" class="btn btn--primary btn--small">
        Salva
      </button>
    </form>

    <div class="form__submit-ok" v-if="submitCivileOK">
      Modifiche salvate correttamente!
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import useDocument from '@/composables/useDocument'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  setup() {
    //utente
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    //updateDoc
    const { updateDoc } = useDocument('utenti', user.value.uid)

    //refs
    const isEditingCivile = ref(false)
    const submitCivileOK = ref(false)
    const entrataSposo1C = ref('')
    const entrataSposo2C = ref('')
    const intro = ref('')
    const rito = ref('')
    const promesse = ref('')
    const articoli = ref('')
    const atto = ref('')
    const firme = ref('')
    const uscita = ref('')
    const altro = ref('')

    //modifica repertorio religioso
    const editCivile = async () => {
      let res = null

      //entrata sposo
      if (entrataSposo1C.value) {
        res = await updateDoc({
          'repertorioCivile.entrataSposo': entrataSposo1C.value,
        })
      }

      //entrata sposa
      if (entrataSposo2C.value) {
        res = await updateDoc({
          'repertorioCivile.entrataSposa': entrataSposo2C.value,
        })
      }

      //intro
      if (intro.value) {
        res = await updateDoc({
          'repertorioCivile.intro': intro.value,
        })
      }

      //promesse
      if (promesse.value) {
        res = await updateDoc({
          'repertorioCivile.promesse': promesse.value,
        })
      }

      //rito
      if (rito.value) {
        res = await updateDoc({
          'repertorioCivile.rito': rito.value,
        })
      }

      //articoli
      if (articoli.value) {
        res = await updateDoc({
          'repertorioCivile.articoli': articoli.value,
        })
      }

      //atto
      if (atto.value) {
        res = await updateDoc({
          'repertorioCivile.atto': atto.value,
        })
      }

      //firme
      if (firme.value) {
        res = await updateDoc({
          'repertorioCivile.firme': firme.value,
        })
      }

      //uscita
      if (uscita.value) {
        res = await updateDoc({
          'repertorioCivile.uscita': uscita.value,
        })
      }

      //altro
      if (altro.value) {
        res = await updateDoc({
          'repertorioCivile.altro': altro.value,
        })
      }

      isEditingCivile.value = !isEditingCivile.value
      submitCivileOK.value = true
      console.log('Modifiche salvate correttamente')
    }

    const updateGoal = () => {}

    return {
      utente,
      error,
      submitCivileOK,
      isEditingCivile,
      entrataSposo1C,
      entrataSposo2C,
      intro,
      promesse,
      rito,
      articoli,
      atto,
      firme,
      uscita,
      editCivile,
      altro,
    }
  },
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}
input {
  background-color: #e9e9e9;
  border: none;
  border-radius: 2px;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.6rem;
  outline: none;
  padding: 1rem;
}
button {
  margin-right: auto;
}
</style>
