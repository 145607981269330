<template>
  <img class="page__bg" v-if="utente" :src="utente.bg" />
</template>

<script>
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'

export default {
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    return {
      utente,
      error,
    }
  },
}
</script>
