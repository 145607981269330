<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />
    <div v-if="utente">
      <div v-for="miss in utente.missioni" :key="miss">
        <div v-if="miss.carte.shopping === 'conquistata'">
          carta shopping completata!
        </div>
      </div>
    </div>

    <!-- SHOPPING FILTER -->
    <ul class="shop-filter">
      <li
        class="shop-filter__item"
        v-for="category in shopCategories"
        :key="category"
      >
        <div
          class="shop-filter__btn"
          :class="{ 'shop-filter__btn--active': category === activeCat }"
          @click="activeCat = category"
        >
          {{ category }}
        </div>
      </li>
    </ul>
    <!-- SHOPPING GRID -->
    <div v-if="utente" class="shopping-grid">
      <span
        class="shopping-grid__item"
        v-for="servizio in servizi"
        :key="servizio.id"
      >
        <article
          class="service"
          v-if="servizio.categoria === activeCat || activeCat === 'Tutti'"
        >
          <div v-for="shopItem in utente.shop" :key="shopItem">
            <button
              v-if="servizio.nome === shopItem"
              class="service__close"
              @click="handleDelete(servizio.nome, servizio.op)"
            >
              <i class="bx bx-x"></i>
            </button>
          </div>

          <div class="service__content" ref="service">
            <figure class="service__thumb">
              <a href="#">
                <img
                  :src="getImgUrl(servizio.immagine)"
                  alt="Cassa portatile"
                />
              </a>
            </figure>

            <h3 class="service__name">
              {{ servizio.nome }}
            </h3>

            <div class="service__btn">
              <a
                v-show="servizio.link"
                :href="servizio.link"
                class="btn btn--outline"
                target="_blank"
                >Scopri di più</a
              >
            </div>

            <p
              style="display: block; font-size: 1.2rem; line-height: 1; margin-top: 1rem"
            >
              Mandaci la richiesta.<br />
              Ti contatteremo per la conferma.
            </p>
          </div>

          <div class="service__footer">
            <div class="service__price">
              <span class="ommepoints">{{ servizio.op }}</span> |
              <span class="price">{{ servizio.prezzo }}€</span>
            </div>

            <!--<button
              class="service__cart"
              @click="goalCTA(servizio.nome, servizio.op, missionId)"
            >
              <i class="bx bx-cart-alt"></i>
            </button>-->
            <a
              class="service__cart"
              target="_blank"
              :href="
                'https://wa.me/393923727778/?text=Ciao, siamo ' +
                  utente.nomeUtente +
                  '. Vorremmo aggiungere ' +
                  servizio.nome +
                  '. Chiediamo conferma. Grazie!'
              "
              ><i class="bx bxl-whatsapp"></i
            ></a>
          </div>
        </article>
      </span>

      <section class="shop-total">
        <div class="shop-total__counter">
          Totale
          <div>
            <i class="bx bx-cart-alt"></i>
            <span v-if="utente">{{ utente.shop.length }}</span>
          </div>
        </div>
        <div class="shop-total__btn">
          <router-link :to="{ name: 'Services' }" class="btn btn--primary"
            >I vostri servizi</router-link
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import getSubcollection from '@/composables/getSubcollection'

import { projectFirestore } from '@/firebase/config'
import firebase from 'firebase/app'

import { ref } from 'vue'
export default {
  components: { Bg, Topbar },
  props: ['id'],
  setup(props) {
    const { user } = getUser()

    const { document: tutorial } = getDocument('tutorial', 'missioni')

    const missionId = ref(props.id)

    let opZone = null
    if (missionId.value == 500) {
      opZone = 200
    }
    if (missionId.value == 1000) {
      opZone = 300
    }
    if (missionId.value == 1500) {
      opZone = 450
    }
    if (missionId.value == 2000) {
      opZone = 600
    }
    if (missionId.value == 2500) {
      opZone = 700
    }
    if (missionId.value == 3000) {
      opZone = 900
    }

    const { documents: servizi } = getCollection(
      'servizi',
      ['op', '==', opZone],
      'prezzo'
    )

    const { documents: fasce } = getCollection(
      'fasce-prezzo',
      '',
      'opStart.min'
    )

    const { error, document: utente } = getDocument('utenti', user.value.uid)
    const { updateDoc } = useDocument('utenti', user.value.uid)

    const service = ref(null)

    const shopCategories = [
      'Tutti',
      'Cerimonia',
      'Aperitivo',
      'Giochi',
      'Animazione bimbi',
      'Scenografia',
    ]

    const activeCat = ref('Tutti')

    const getImgUrl = (img) => {
      const images = require.context(
        '@/assets/images/services/',
        false,
        /\.jpg$/
      )

      return images('./' + img + '.jpg')
    }

    const goalCTA = async (servizio, op, missionId) => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('missioni')
        .doc(missionId)
        .update({
          shopping: 'conquistata',
          carteConquistate: firebase.firestore.FieldValue.increment(1),
        })

      //update opTotal & add service to shop counter
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          opTotal: firebase.firestore.FieldValue.increment(op),
          shop: firebase.firestore.FieldValue.arrayUnion(servizio),
          shopTotal: firebase.firestore.FieldValue.increment(op),
        })

      //update opTotal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          opTotal: firebase.firestore.FieldValue.increment(missionId),
        })

      console.log('aggiornato!')
    }

    const handleDelete = async (servizio, op) => {
      const res = await updateDoc({
        shop: firebase.firestore.FieldValue.arrayRemove(servizio),
        opTotal: firebase.firestore.FieldValue.increment(-op),
      })
    }

    /* const goalCTA = async (missionId, op, id, extra) => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('missioni')
        .doc(missionId)
        .update({
          action: 'conquistata',
          shopping: 'attiva',
          carteConquistate: firebase.firestore.FieldValue.increment(1),
        })

      //update opTotal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          opTotal: firebase.firestore.FieldValue.increment(op),
        })

      if (extra) {
        //update opTotal if extra
        projectFirestore
          .collection('utenti')
          .doc(user.value.uid)
          .update({
            opTotal: firebase.firestore.FieldValue.increment(extra),
          })
      }

      //update goal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('obiettivi')
        .doc(id)
        .update({
          raggiunto: true,
        })

      console.log('aggiornato!')
    } */

    return {
      shopCategories,
      activeCat,
      servizi,
      getImgUrl,
      utente,
      error,
      goalCTA,
      handleDelete,
      fasce,
      missionId,
      opZone,
      tutorial,
    }
  },
}
</script>

<style scoped>
.service__btn {
  min-height: 26px;
}
</style>
