import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getCollection = (collection, query, order, orientation = 'asc') => {
  const documents = ref(null)
  const error = ref(null)

  let collectionRef = null
  // register the firestore collection reference
  if (order) {
    collectionRef = projectFirestore
      .collection(collection)
      .orderBy(order, orientation)
  } else {
    collectionRef = projectFirestore.collection(collection)
  }

  if (query) {
    collectionRef = collectionRef.where(...query)
  }

  const unsub = collectionRef.onSnapshot(
    (snap) => {
      let results = []
      snap.docs.forEach((doc) => {
        // must wait for the server to create the timestamp & send it back
        doc.data() && results.push({ ...doc.data(), id: doc.id })
      })

      // update values
      documents.value = results
      error.value = null
    },
    (err) => {
      console.log(err.message)
      documents.value = null
      error.value = 'could not fetch the data'
    }
  )

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  })

  return { error, documents }
}

export default getCollection
