<template>
  <div class="login">
    <img
      class="logo flex mb-3"
      src="@/assets/images/logo.svg"
      alt="OMME Music"
    />

    <h1 class="heading heading--span-box mb-5">Area <span>Sposi</span></h1>

    <!--<div class="area-intro">
      <p>Cari sposi,</p>
      <p>
        da <strong>lunedì 1 marzo</strong> alle <strong>ore 11:00</strong> la
        nuova Area Sposi sarà accessibile con le credenziali in vostro possesso.
        🎉<br />
        Ci scusiamo per il disagio 🙏
      </p>

      <p>Buon divertimento! 😊</p>

      <button @click="popup = true" class="btn btn--primary">
        Guarda il tutorial<i class="bx bxl-youtube"></i>
      </button>
    </div>-->

    <form @submit.prevent="handleSubmit" class="form form--login">
      <div class="form__row">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" />
      </div>

      <div class="form__row">
        <label for="password">Password</label>
        <input type="password" v-model="password" />
      </div>

      <div v-if="error" class="error">{{ error }}</div>

      <button v-if="!isPending" class="btn btn--big btn--primary btn--full">
        Accedi <i class="bx bx-log-in"></i>
      </button>

      <button
        v-if="isPending"
        class="btn btn--big btn--primary btn--full"
        disabled
      >
        Caricamento...
      </button>
    </form>

    <div class="area-intro">
      <a
        href="https://youtu.be/zmruFjB6IhI"
        target="_blank"
        class="btn btn--secondary"
      >
        Guarda il tutorial<i class="bx bxl-youtube"></i>
      </a>
    </div>
  </div>
</template>

<script>
import useLogin from '@/composables/useLogin'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const { error, login, isPending } = useLogin()
    const router = useRouter()

    const email = ref('')
    const password = ref('')

    const handleSubmit = async () => {
      const res = await login(email.value, password.value)
      if (!error.value) {
        router.push({ name: 'Account' })
      }
    }
    return {
      email,
      password,
      handleSubmit,
      error,
      isPending,
    }
  },
}
</script>

<style scoped>
.error {
  color: tomato;
}
.area-intro {
  line-height: 1.6;
  max-width: 80vw;
  margin: 0 auto 5rem;
  text-align: center;
}
.area-intro p {
  margin-bottom: 2rem;
}
.area-intro h2 {
  font-family: 'Sofia Pro';
  margin-bottom: 6rem;
}
.area-intro .btn {
  display: inline-flex;
  font-size: 2.4rem;
  line-height: 1;
  padding: 1.2rem 3rem;
}
.area-intro .btn .bx {
  margin-left: 1rem;
}
.popup {
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition: 0.3s;
  z-index: 99999 !important;
}
.popupShow {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}

.popup-card {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  height: 80vh;
  width: 90vw;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.popup-close {
  border: none;
  display: inline-flex;
  background: white;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  color: #00ccbc;
  font-size: 3.2rem;
  position: absolute;
  right: -1rem;
  top: -1rem;
}

.forgot-password {
  font-size: 1.6rem;
  line-height: 1;
  margin-top: 2rem;
  text-align: center;
}
</style>
