<template>
  <div>
    <Bg />

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <!-- AVATAR -->
    <Avatar />

    <div v-if="utente">
      <div v-if="utente.primoAccesso">
        <div class="modal modal--intro">
          <div class="modal__overlay">
            <div class="modal__card">
              <div class="modal__body">
                <h3 class="modal__title">
                  benvenuti<br />
                  nell'Area Sposi!
                </h3>

                <p class="modal__paragraph">
                  In regalo per voi il primo pezzo del puzzle
                </p>

                <img
                  class="modal__piece"
                  src="@/assets/images/trip/pieces/complete/piece-complete-1.svg"
                />

                <div
                  class="modal__ommepoints modal__ommepoints--big"
                  v-for="fascia in fasce"
                  :key="fascia"
                >
                  <div
                    v-if="
                      utente.opStart >= fascia.opStart.min &&
                        utente.opStart <= fascia.opStart.max
                    "
                  >
                    <span class="ommepoints"
                      >+{{ (op = fascia.puzzle.pezzo1.op) }}</span
                    >
                  </div>
                </div>

                <p class="modal__disclaimer">
                  Prima di cominciare, ricordiamo che l'app è appena nata e i
                  vostri dati sono ancora in fase di aggiornamento, per cui vi
                  invitiamo a controllarli e a segnalarci eventuali anomalie.
                  Gli errori saranno prontamente risolti e tutti i conteggi, in
                  breve tempo, risulteranno corretti.<br />
                  Stiamo lavorando per voi!<br />
                  Buon divertimento!
                </p>

                <button class="btn btn--primary" @click="disableFirstLogin(op)">
                  Iniziamo a giocare!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- COUNTDOWN -->
    <Countdown v-if="utente" :date="utente.dataMatrimonio" />

    <!-- USER MENU -->
    <UserMenu v-if="utente" :date="utente.dataMatrimonio" />

    <!-- MAIN MENU -->
    <div class="panel">
      <MainMenu />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import Bg from '@/components/Bg.vue'
import Countdown from '@/components/Countdown.vue'
import MainMenu from '@/components/MainMenu.vue'
import UserMenu from '@/components/UserMenu.vue'
import Topbar from '@/components/Topbar.vue'

import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  components: { Avatar, Bg, Countdown, UserMenu, MainMenu, Topbar },
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { err, document: tutorial } = getDocument('tutorial', 'home')

    //fasce
    const { documents: fasce } = getCollection('fasce-prezzo')

    const disableFirstLogin = (op) => {
      console.log('Primo accesso disabilitato')

      //set primoAccesso to false
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          primoAccesso: false,
          opTotal: firebase.firestore.FieldValue.increment(op),
        })

      //update pezzo1
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('puzzle')
        .doc('pezzo1')
        .update({
          current: false,
          conquistato: true,
        })

      //pezzo2 is the new current
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('puzzle')
        .doc('pezzo2')
        .update({
          current: true,
        })
    }

    return { utente, error, tutorial, fasce, disableFirstLogin }
  },
}
</script>

<style lang="scss" scoped>
p.small {
  font-size: 1.3rem;
  line-height: 1.4rem;
}

.modal__piece {
  height: 7rem;
}

.ommepoints {
  font-size: 3rem;

  &::after {
    height: 2.3rem;
    width: 2.3rem;
    transform: translateX(4px);
  }
}
</style>
