<template>
  <Bg />
  <p>Pagina non trovata</p>
</template>

<script>
import Bg from '@/components/Bg.vue'
export default {
  components: { Bg },
}
</script>
