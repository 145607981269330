<template>
  <div>
    <div class="panel" style="padding: 10vh 2rem">
      <form class="form" @submit.prevent="creaDocumentoUtente()">
        <div class="form__row">
          <label>ID Utente</label>
          <input type="text" placeholder="id utente" v-model="userId" />
        </div>

        <button class="btn btn--primary">
          Crea Documento Utente
        </button>
      </form>

      <div style="margin-top: 3rem">
        <h3>Inserisci dati utente</h3>
        <form class="form" @submit.prevent="riempiCampi()">
          <div class="form__row">
            <label>ID Utente</label>
            <input type="text" placeholder="id utente" v-model="userId" />
          </div>
          <!-- Foto Sfondo -->
          <div class="form__row">
            <label>Foto sfondo</label>
            <input type="text" v-model="bg" />
          </div>

          <!-- Dati cerimonia -->
          <div class="form__row">
            <label>Cerimonia Indirizzo</label>
            <input type="text" v-model="cerimoniaIndirizzo" />
          </div>

          <div class="form__row">
            <label>Cerimonia Ora</label>
            <input type="text" v-model="cerimoniaOra" />
          </div>

          <div class="form__row">
            <label>Cerimonia Tipologia (civile o religiosa)</label>
            <input type="text" v-model="cerimoniaTipologia" />
          </div>

          <!-- Contratto -->
          <div class="form__row">
            <label>Servizio 1</label>
            <input type="text" v-model="servizio1" />
          </div>

          <div class="form__row">
            <label>Servizio 2</label>
            <input type="text" v-model="servizio2" />
          </div>

          <div class="form__row">
            <label>Servizio 3</label>
            <input type="text" v-model="servizio3" />
          </div>

          <div class="form__row">
            <label>Servizio 4</label>
            <input type="text" v-model="servizio4" />
          </div>

          <div class="form__row">
            <label>Servizio 5</label>
            <input type="text" v-model="servizio5" />
          </div>

          <div class="form__row">
            <label>Servizio 6</label>
            <input type="text" v-model="servizio6" />
          </div>

          <div class="form__row">
            <label>Servizio 7</label>
            <input type="text" v-model="servizio7" />
          </div>

          <div class="form__row">
            <label>Servizio 8</label>
            <input type="text" v-model="servizio8" />
          </div>

          <div class="form__row">
            <label>Servizio 9</label>
            <input type="text" v-model="servizio9" />
          </div>

          <div class="form__row">
            <label>Servizio 10</label>
            <input type="text" v-model="servizio10" />
          </div>

          <!-- Data matrimonio gg/mm/aaaa -->
          <div class="form__row">
            <label>Data matrimonio (gg/mm/aaaa)</label>
            <input type="date" v-model="dataMatrimonio" />
          </div>

          <!-- Durata matrimonio -->
          <div class="form__row">
            <label>Durata matrimonio (giornaliero/serale)</label>
            <input type="text" v-model="durataMatrimonio" />
          </div>

          <!-- Foto profilo -->
          <div class="form__row">
            <label>Foto profilo</label>
            <input type="text" v-model="fotoProfilo" />
          </div>

          <!-- Hashtag -->
          <div class="form__row">
            <label>Hashtag</label>
            <input type="text" v-model="hashtag" />
          </div>

          <!-- Nome Utente -->
          <div class="form__row">
            <label>Nome utente</label>
            <input type="text" v-model="nomeUtente" />
          </div>

          <!-- ommepoint iniziali -->
          <div class="form__row">
            <label>ommepoint iniziali</label>
            <input type="text" v-model="opStart" />
          </div>

          <!-- Ricevimento -->
          <div class="form__row">
            <label>Ricevimento Indirizzo</label>
            <input type="text" v-model="ricevimentoIndirizzo" />
          </div>

          <div class="form__row">
            <label>Ricevimento Location</label>
            <input type="text" v-model="ricevimentoLocation" />
          </div>

          <!-- Sposo1 -->
          <div class="form__row">
            <label>Sposo 1 Nome</label>
            <input type="text" v-model="sposo1Nome" />
          </div>

          <div class="form__row">
            <label>Sposo 1 Telefono</label>
            <input type="text" v-model="sposo1Telefono" />
          </div>

          <div class="form__row">
            <label>Sposo 1 Email</label>
            <input type="text" v-model="sposo1Email" />
          </div>

          <!-- Sposo2 -->
          <div class="form__row">
            <label>Sposo 2 Nome</label>
            <input type="text" v-model="sposo2Nome" />
          </div>

          <div class="form__row">
            <label>Sposo 2 Telefono</label>
            <input type="text" v-model="sposo2Telefono" />
          </div>

          <div class="form__row">
            <label>Sposo 2 Email</label>
            <input type="text" v-model="sposo2Email" />
          </div>

          <!-- Torneo -->
          <div class="form__row">
            <label>Torneo (sogno d'estate / vento d'autunno)</label>
            <input type="text" v-model="torneo" />
          </div>

          <button class="btn btn--primary">
            Riempi campi
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import firebase from 'firebase'

import useStorage from '@/composables/useStorage'

import { ref } from 'vue'

export default {
  setup() {
    //let docRef = ref(null)

    const userId = ref('')
    const { filePath, url, uploadImage } = useStorage()

    const file = ref(null)
    const fileError = ref(null)

    //utenti
    const { documents: utenti } = getCollection('users', '', '')

    const { user } = getUser()

    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { updateDoc } = useDocument('utenti', user.value.uid)

    const creaDocumenti = () => {
      const utenti = ['Ciimoyvof4caGwtCzvtrfkANST13']

      utenti.forEach((utente) => {
        const pezzi = [
          'pezzo1',
          'pezzo2',
          'pezzo3',
          'pezzo4',
          'pezzo5',
          'pezzo6',
          'pezzo7',
          'pezzo8',
        ]

        pezzi.forEach((pezzo) => {
          projectFirestore
            .collection('utenti')
            .doc(utente)
            .collection('puzzle')
            .doc(pezzo)
            .set({
              bloccato: true,
              conquistato: false,
              current: false,
            })
        })

        projectFirestore
          .collection('utenti')
          .doc(utente)
          .collection('puzzle')
          .doc('pezzo1')
          .update({
            bloccato: false,
            conquistato: true,
          })

        projectFirestore
          .collection('utenti')
          .doc(utente)
          .collection('puzzle')
          .doc('pezzo2')
          .update({
            bloccato: false,
            current: true,
          })

        console.log('Modifiche apportate!')
      })
    }

    const inserisciCampi = () => {
      const utenti = [
        '16Rvaoqa66UMTvOn8i0H8EDFjR03',
        '1IgBFOMTekhbzKfM7NkJK8PFZaJ2',
        '1mVwrVnSV9ZFO9lsyCXv4D7u2tk1',
        '2IEvcWh7SWaLG3C3WAwhWVEpk8J2',
        '2KLmFSbAwvgRzYOYBvW1bB52LH23',
        '2PwG68Be6mWSzlianxFMmxurcxF3',
        '2xzih17xkcXuCgr2otGV00hRYgI3',
        '3b0CEkebbqWpFyzAqJyV600raU83',
        '4IkRX7cTxCQGlM9E4uXKzQEm7G63',
        '4S4YIICPXzatjjHGPH0lPk4Onx53',
        '4eVzWPXdaTeQML3kJ9Tqd6H0i2B3',
        '51CyX73NGFYnISGPsEf4DJHVgTh1',
        '53TrlNvRwdTCMKVqs3FLLsl6uDI3',
        '5Cjk9vv4TqazItWWA47ejvXtDxy2',
        '5oEWX4uYx8giknXFZF7YPCQnw7P2',
        '60w1hK9FbSSqwufvEKV9RVwyOMT2',
        '6BqRmSO5ZZTcVhrnsmPe47k6kCt1',
        '6TnEWlAyyNfyndsVjvcAOyF1w9k2',
        '6Whn8RWPaUNp393xLsrELZJre133',
        '6e21DCZOJTTDbUQRuLwffAn8rYG3',
        '6fxxHfRSBRN94bJN4onD30RYc0G2',
        '6pxG4G6gk4a8XzEHm3aIqgMVoag2',
        '73htzq1tCtOUzy1XcSCXDsycETF3',
        '7nEm0Ia0pzQyujOWZ5QhyiR7ZG33',
        '85tKb7WtY9V07xtM18Nkd3DtXXm1',
        '8Bg1gdEKSjN7AmPewOKPEisAGNv2',
        '8K6KH9ouXvffPLhwPxFdPZiGyLr2',
        '8PNFqTAy1pTgn104OPfwuppq7B63',
        '8UsxpKT6FsbY4t1rrox7hsWLBKC2',
        '8Zvr6YC9TdMDPbdIcmm3YeDGw1I3',
        '9ENFd0zxkcQgPO06CRyha9usdpi2',
        '9IMRx6U9WKUYQs8OzYsd47vTUzM2',
        '9TU2x6yp4yWwICqH0tq1oyPjps62',
        '9UINRiAPBUViLMi17u4FOyrEH662',
        '9WBd8XW7A6gsM0QdS57s1uytTIw2',
        'AatC1AuMKhQTDtutzpXMbJGtZ7g1',
        'AvbBbZdMhFUyzTlXkUmsIPmzAwt2',
        'B5AcMP2ITnavCyVkhZYh1MPRwi13',
        'BMjXcKsdMUaahbbbXhwP4WdI4eh2',
        'BP2MJ7u0BKa9PM1tspA5bGXh5WB3',
        'BQkbFvEnROOCVVovUKrMNiRa6DG3',
        'BpnBTcwKmWVYMXQhrlXN6vMqOxN2',
        'Bqbz4ewmGrcphD7B5cDQxBzbu5H2',
        'ByxAR1UdYkRZNAPWmTTsatR8I8r1',
        'C2sEzuSlyVcGqM6ScrEX8CCwBsL2',
        'CJq7P909M9emXA2RKOOpYWOYBkx1',
        'CfDP4DoTsIWALXPJyAsL7355lyi2',
        'ClHqZ7jvb8NO7ClElsbT4pYIA2v2',
        'CztEYEJi8HUrdx3DKACNSk9gkHp2',
        'DQBh5gLLILOoD5lnJz5hd1ntmx73',
        'DQLX5Kr3rFVTy7EXp8Wfz28Mo4J2',
        'DtJT2Sqjj2ccMO4Amn9iBQbKyIv1',
        'DzqFNKnG3uSRweGtk4Qv08rOki52',
        'E9Zpui8LqjORQYzkKCRAo5f5kMt1',
        'ESrFtuGUmuZ9Qod0lNJDeogWk5r2',
        'F7X13Y2CZnRa9zep52ifbxB56KB2',
        'FBjx7wEnbmfvq0lNbzlkqetcjDV2',
        'FNOOvvp4czXKvNTmBJqqUxPwtlo2',
        'FZHFDGDLsGZOmXxjGWj851FU5bb2',
        'Frw6GxyS7XVaN5gpaEKlkZR0TXp1',
        'FwLYzJGVxufNUJufNqOp6wmIhIh1',
        'Ginh4K3BkTXvtDlasXTyhx7QD4O2',
        'Hen2Y5ibNESoOdDoAWVjocII0GR2',
        'Hj46PVl0Jrci9kDcrzP2zHBOgSk2',
        'HjF5SlynvTUXENOGuQBCFC1Fexy1',
        'I4wKD9uVUjh9xySrbFOzBhnm33k1',
        'I518x3B2EjRcVrvD1XiDjT7DXsQ2',
        'IQbchhvDWzOCsxaYA3IGLBIXk2u1',
        'J081zmEvTCM5hZ1VzSHIp2Ests63',
        'J0NPoPTuVNY4SBLrXhhjwin6vcn1',
        'JARxgiyaVjfEl77BRUGgbz4uy9z1',
        'JXlgDXd6hTXv2GbIHTo9idEAIWt2',
        'JbeZzOW5fscdpmjvC6S5KNVw03j1',
        'JbkEAUMuPYZbf7pNjr5LOxG1Ijr1',
        'JgZ2e8xzRggUZqH3PrJ4iBvyPRy1',
        'JjpcNtt2EFaP8efATdwxUZC0yYN2',
        'JrVmvJpOv1ULIvKvt7z4NDOHJGq1',
        'JuB2Ie0papZlqE2R2hrTJi9Ik4z1',
        'K2AvCGIBAvgjdKAQxQY4X3Y7K1I2',
        'KbIvjM6A1rayHQymgnF5RXmur0g2',
        'Lf2HDpoxv0MAlAVxRgwJYK9Cgdc2',
        'LuDv3DXLx3Q960Gz7AA7O9ZRijF2',
        'MAkpAtBSekV0n7NKflEeK5QdgJH2',
        'MIbri6C8GNfL5CBPbZJmejshHZK2',
        'MsQLe1Jo6ZONgofGVXdUHLsbTF63',
        'Mv4Loqr4osYPrkgYAbylcYh5h5d2',
        'NNrdO18oKfSxzurkJ4QCADEFAWA2',
        'NQUlTu3tCXS1ihYFydS4etOdpMr1',
        'NWOQ1wlU8ZhN2SPC5f2loFdHTBv2',
        'NyrMGL2r19dkrFvvtXBNtH0f7Fk1',
        'O1XuTCSn5Gcl0xC5so5NSH8Nf1n2',
        'O3lgux0kO4fYyNaefEDDIN5CdUs1',
        'O9bzFWhpeFTB5jqXgExEXQsJYYu2',
        'OKuefE5bVpRURtHICjH0DIFvuGp2',
        'P2RZYEDmgQOZ9IbHixrhVffvRT53',
        'PEKfbxUiyFcRAQjslgxsaRkqXzf2',
        'PMDrb0XvPrdQ3zF8leqyqV3gOIu2',
        'Pkd4E51OjaYuXVv8p1XyV2KbMY92',
        'Pq90dYyerpZBiF1U2penObtfEWy1',
        'QCtr6mwgKhRdleeqw0GlnA5mTt23',
        'Qop3M0l2PrMdjhno3BntdrePvWo1',
        'QqhK8h712TbaCryHOlRyv0oWg7i1',
        'QqvhwTdSTlOLxhEP5Pzz4EqeiDQ2',
        'QtjK4Ivse3fkgUPkWCVEx8Hazrp2',
        'QvgJYdpcg0NQPIVQYgwaVdN5UVM2',
        'QwjpaNRvtMOhjhq9moZsrwthtXQ2',
        'R6xHNctnNEbqyG1UijAFOZqVM4y2',
        'RLaYwiRLjPep2InI6vw600Lx3LN2',
        'RN3kYTecj1Uv2UQnwvHRKXIeemq2',
        'RS1mgwlY8HW71nqHnlXoY5NtuDV2',
        'RnZ5o4Ct7xTch0AUaQ4GaM1SW4C3',
        'RrquR6jJ5CZMyvK5lbzAlUo8laj2',
        'S2A8tUUcMFeHtvNqzMxqnOlhzab2',
        'S9tjE4kkCmRoSrwz6lMyP5IjSng2',
        'Se4Z00WYaZVn06IRgaRfl0y3Fc63',
        'SuAbIr0rzabUikjdBloLlklLjat2',
        'TQ6AtF8OwbUiG6sYM2hTEF2QwQ73',
        'TSlewGFXmXe0aL4jbU3UIa4zEsw1',
        'TYhTkD886cd58joajHSzJUda06T2',
        'TclgLKNk5LS4rKouPWkDyAIcdb22',
        'U4RWiP36PcWTKUrbJ8owrxQ1U152',
        'UKUChFkFFdQC8LfvFD6h83th8dL2',
        'UTNNR6XHEgV4KyQHwcZxAMSCXom2',
        'UhgZe18KMNVN8pjcsyzqYrFiWF23',
        'Un4tn1S8a3TS1XsVHeEDxuu77ek2',
        'Uur61tErebckkDhrdO5ojB7yhcz1',
        'VCRQ2l4pp0cwAKb7Bzpu9XCKM083',
        'VH3MlckvytUzqQ9p0FVFM4XRUVq1',
        'VHsp2eoEBNOt3lRn1b3Ir9Rq6FO2',
        'VPn3YuWMtOVnG6okCPIKoOD3Wpd2',
        'Wh6CQx9snEZAfm9X7rcbwVKs8vp2',
        'Wy4au5vwkCUAm1JicgIG6K5Vl2j1',
        'X0FI4x8P2OVtA4b6cCiMvzXnoyk2',
        'XDykhmDqc6VwGjXJUgfv2cp9zT52',
        'XFQf0WBx1MePNnUU09n6GDazpcf1',
        'XnsDHZeCdTharZ5ph2MaC55a9sq2',
        'Y5npp181hFa07921rh4yAYaTDvs1',
        'YIlpcxLntwPyH28WFIoRAY2m8Za2',
        'YhLdNvhrqoauQw9H3XPu0N0udIv2',
        'YuJrmAycIAgvPqBzXfENvYXsRo23',
        'Yyg0xMpq1RRYte7YtCdtcMPbn0f2',
        'Z1yUqaG0yMOpPVHuaAwiQo5CN4g2',
        'Z6S2JIbyvKTofunKKNQDpBPVtWJ3',
        'ZBDDPPQSICRr9uVREpi4w9OL8212',
        'ZOhGzibwykcM6s7NLUgIOLzJhaF2',
        'ZWWoZP1MF9ZETcvwfAtv4Q7LG8t2',
        'ZuKMJCKfBxgJqvAOBPQbaR0G4QR2',
        'ZuZt2ligPWfzPQ8qwN8EuFciYTm2',
        'ZvssGDQuTzMYCxG4FCGRnmoyqQB3',
        'a5gY1YUUGAcFwn4pfZDYMutKDho2',
        'aBSFgehDUAaEhGQowDnAKxcRBU12',
        'aF9uzPVyvQdZCi8WLj1YMQ6PSbO2',
        'ayLh2SmVppVY9wGXWeEuU8n1eel1',
        'bb5vOJqrJea9b4ey7W05Tr7vudi2',
        'bqDP7tFaU7Th9SEO9VowxhJ4IF42',
        'bvO8u0XJwHSSCPzDNM3Sv295QuE3',
        'cBSQRt9wWhh6h42ZqB2nPJYnFl33',
        'cQMLUKIcUObecGNT5OFxC3RXiwn2',
        'caKeYujFAufrzgCcphAQuloAttC3',
        'cwrGx3AfDXeOJsWDqZ33KYJ1plK2',
        'dtUo5Ob2VQZd7QxMnjL2fX3HN622',
        'e7kZW8iu2wbA5BvwoaGg8Qq7Gn72',
        'eWudc9ASOoV2XZA6KuzhnzyMwUB3',
        'ebneyx8RJtd1IdXuD31ip9Waq3z2',
        'egpgQZGHgodmbuhV1WbhltRpKSa2',
        'eho0reXek7UWQrMUm23qhtVPNrr1',
        'ewzoq5KgsuPPqmCukReoj8I1pHi1',
        'exAI4rXi36SGu8kpa5WT9AqHRzf2',
        'f4pgQWvK8Adi9ZjacYe6DBozzqz2',
        'fBR44l5GRiPeoRk9KDRcdxI93sp2',
        'fRCeKARMqmPHIueAAtpV7WHwcEl2',
        'fh2zIihacxaJE1UJR8DnHln3ufs2',
        'gAb9fuBf7cOGXg50kYcywsrsetn1',
        'gVSWdEZkuAaVPdTieExGPasfLv03',
        'goEWUhBXRGRFLJ6NAFmkl0QTuxy1',
        'gzQlFFwkpWa2wkkpcm3rGD4AoSH2',
        'h1ZL7iZ3WidQgeAikV2be5af1hB3',
        'huPJGUjT3MXqY211bugeOc7px0F3',
        'iacJ4aqnpRWLjj1NaaBSJYtvD553',
        'im7ygbUJUVRr1MNTJNzgJ2HRGSk1',
        'ipF3vgIdhbhUC8CeX8MfXtTGlQt2',
        'ivUXvIulLEM7384Z3bcHZAnlWMX2',
        'jdtn8XLzcycd1mT96jGtICc6EOH2',
        'l6kj4ITYyXP0oJz0PG5HkkfTWBB2',
        'lBPBd6Lh6HRPfClLUnaU62ErZx92',
        'lJPzrdkYtmYdkWIPSOpmZOLP2dE2',
        'lXxnhHd7A2ZpBchZwEwj7pO4Pbp2',
        'lgK9Z7YtWMceXc641J981es6OWn2',
        'lgpaA8lxbZM85jHBBI6IYm0PzAa2',
        'm6kZL1GNUyUFLTV4pJBihHjxJx92',
        'mPKPUgdDiDX6ZdXWxQXFbK00nwH2',
        'mbhYJ4mklzTJpdhoA2dBxGxWzir1',
        'nUX3Tqyz9KgxXIH9XMCrgKkC4Ng2',
        'nUkJoFcz5uRV4rZltb1cDJFl8Aa2',
        'nems4XTegoeLbPgSHilaeFYY0qs1',
        'np9rMAd6fDfnZOPsTN62GYLEtW12',
        'nvyQMZQrwDTzkXp4Ui580cCd8uE3',
        'oX0tCLtfCKhULy0s8luMQycLLwA3',
        'ojyGkbrBexYO6VrrksNt7X1qOP23',
        'omr4nUwZGRNJOWCHb0Fzonh93BZ2',
        'ooej5Q9breNOHX6btaQKrbardOH2',
        'pAKPpklf7rVXtFrFz3ORxws3za02',
        'pJNND5aUzlMViRKbY4ViWjdIBOu2',
        'pPOdXSOLVsMdgvzRvxwgiymHcjA3',
        'pW10EnbOTnhbQCgtK1wL0nQdx3D3',
        'pYhBeAH1aCdk0V36LDwgplhSdps2',
        'pcG3Nt4s3abQhC3F4vShmZBgLQ73',
        'pcwFBOlbuAYRcopj25RAlhucM7J2',
        'pxhw41sbZAP4tOqta858f4vLApj1',
        'qFHC0sncJYOqgfGtKW2ouTeqgGG3',
        'qbNv8MxPEWOdOm08ymQW7fHtPKw1',
        'qhw0wnOzRigbbI14mXZKrXOzvbM2',
        'rEjL2yrV22dCmY8LkBS45MyJEDT2',
        'rNRbTrKn2gTmNahNim8YuPpTW533',
        'rPtYOmklp1chgXQVKP2nCS1PVVY2',
        'reheuSjPR2OE5Jp2Q3jlWhNc3eJ2',
        's7un8rBDACXU7H2CQodS7ydkLP73',
        'sga0o5fCBWWmJIH2Ar6RUMksbda2',
        't7c6Dui2VrUXJdGGoS9yrh2vZDJ3',
        'tewsmtRrX3bu38NSnW1c5MIEsQq1',
        'tlEawhKMG8XX1axjjfqJO9y78Sd2',
        'txyRDQTQhFWKl6fTTtybJFGT7T23',
        'uPj91XiNJudVbNWWcBRWup0ndW13',
        'uUQxejNlj1TJ7ArghmyU7NT82Sn2',
        'umDVNCvvFFVbLCFOGwzN2WyiFzp1',
        'v3ZwQc27Slh65CR4RdvWYi14XZi2',
        'vAcogGXtrVZlakoDSPqQ0sB1B6p2',
        'vC5LpFkkmISxphwlMqimOnVY7SI2',
        'vD4ijcUY09Or4X5Y34kas8xs1Km2',
        'vEPThFhLZceBFpzskP4iJeGElGq2',
        'vFfzWadkFhSntCt8i6GqzIcv4Ak2',
        'vKuVw6KYGzOUFy0jXBqSAWb0WqH3',
        'vMrDeLEGurafyHzrGGAaFVj8JOE3',
        'vkoX28bFYFThVEkVjsILQNvojMY2',
        'vw8prNn8alatsQ52LVlk2ocqNRH2',
        'wOb4swns9Xc4aj11RZPXYEm0WdO2',
        'wOkHm0od4ifVxz6lOYoUVJ1ZLdk1',
        'wTQkz8ECPBVfYT3FvkwQEqLwYy22',
        'wVaUjYtTbJcML9uZOiib7M8QMxf2',
        'x96ktCIqAQU7lsAW5eFvAxqTq782',
        'xFkv6QIhD8ZjQHvttxx3FZcnkci1',
        'xJmT05BlU2YKwr8ukhm50dgmsdh2',
        'xZEmRZfQfCZ1nYWdli1N4gcviD73',
        'xcZOLnQDypX2WJZDhCXBlsGXIG03',
        'xrOvN7fmguaQNM1ytEeRXsa0aro1',
        'xtN9dkZejCU12kYOw3hAOPM1ySw1',
        'yJz9WCxqZ0b7nKDGrHogtF35RqZ2',
        'yk4pWh6TMbW3p5ZfB5RWa2NKumH2',
        'ypav2PcJeDP3jrr73xQAuq1uBXM2',
        'zTPwHLT3pOMTCmuY4rCeplE1X352',
        'zbHX4w3n8WS00vTNIJmKkJA1Ijh1',
        'zkWyTHD9XvUQtzXePWoKkp1CsUx2',
        'zxipwkIMpFMoBod38YGIMilg74q1',
      ]

      utenti.forEach((utente) => {
        projectFirestore
          .collection('utenti')
          .doc(utente)
          .update({
            puzzleComplete: false,
            modalComplete: 1,
          })

        console.log('Campi aggiunti correttamente!')
      })
    }

    const creaDocumentoUtente = () => {
      //inserisci campi utente
      projectFirestore
        .collection('utenti')
        .doc(userId.value)
        .set({
          bg: '',
          buoni: [],
          cerimonia: {
            indirizzo: '',
            ora: '',
            tipologia: '',
          },
          contratto: [],
          dataMatrimonio: '',
          durataMatrimonio: '',
          forzieri: [],
          forzieriArgento: 0,
          forzieriBronzo: 0,
          forzieriOro: 0,
          forzieriShop: [],
          fotoProfilo: '',
          giftNum: 0,
          giftTotal: 0,
          hashtag: '',
          modalComplete: 1,
          nomeUtente: '',
          obiettiviActionStandard: 0,
          obiettiviSocialStandard: 0,
          opStart: 0,
          opTotal: 0,
          primoAccesso: true,
          puzzleComplete: false,
          repertorioCivile: {
            altro: '',
            articoli: '',
            atto: '',
            entrataSposa: '',
            entrataSposo: '',
            firme: '',
            intro: '',
            promesse: '',
            rito: '',
            uscita: '',
          },
          repertorioReligioso: {
            alleluia: '',
            comunione: '',
            entrataSposa: '',
            entrataSposo: '',
            firme: '',
            offertorio: '',
            rito: '',
          },
          repertorioRicevimento: {
            altro: '',
            arrivo: '',
            artistiNo: '',
            artistiSi: '',
            ballo: '',
            entrataSposo: '',
            ingresso: '',
            rito: '',
            torta: '',
          },
          ricevimento: {
            indirizzo: '',
            location: '',
          },
          sconti: [],
          shop: [],
          shopTotal: 0,
          sposo1: {
            email: '',
            indirizzo: '',
            nome: '',
            telefono: '',
          },
          sposo2: {
            email: '',
            indirizzo: '',
            nome: '',
            telefono: '',
          },
          torneo: '',
        })

      //gift
      const gifts = ['300', '600', '1000']

      gifts.forEach((gift) => {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .collection('gift')
          .doc(gift)
          .set({
            nome: 'gift-' + gift,
            op: gift,
            quantita: 0,
            stato: 'bloccata',
          })
      })

      projectFirestore
        .collection('utenti')
        .doc(userId.value)
        .collection('gift')
        .doc(300 + '')
        .update({
          stato: 'attiva',
        })

      //missioni
      const missions = [500, 1000, 1500, 2000, 2500, 3000]
      missions.forEach((mission) => {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .collection('missioni')
          .doc(mission + '')
          .set({
            action: 'bloccata',
            carteConquistate: 0,
            op: mission,
            shopping: 'bloccata',
            social: 'attiva',
          })
      })

      //obiettivi
      const goals = [
        'biglietto-mediapics',
        'contatti-artisti',
        'follow-ig',
        'gift-card',
        'inviti-omme-music',
        'like-fb',
        'preventivo-mediapics',
        'repertorio',
        'share-fb',
        'stories-ig',
        'tab-fb',
        'tag-fb',
        'tag-ig',
      ]

      goals.forEach((goal) => {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .collection('obiettivi')
          .doc(goal)
          .set({
            raggiunto: false,
          })
      })

      //puzzle
      const puzzle = [
        'pezzo1',
        'pezzo2',
        'pezzo3',
        'pezzo4',
        'pezzo5',
        'pezzo6',
        'pezzo7',
        'pezzo8',
      ]

      puzzle.forEach((piece) => {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .collection('puzzle')
          .doc(piece)
          .set({
            bloccato: true,
            conquistato: false,
            current: false,
          })
      })

      projectFirestore
        .collection('utenti')
        .doc(userId.value)
        .collection('puzzle')
        .doc('pezzo1')
        .update({
          bloccato: false,
          conquistato: true,
          current: false,
        })

      projectFirestore
        .collection('utenti')
        .doc(userId.value)
        .collection('puzzle')
        .doc('pezzo2')
        .update({
          bloccato: false,
          conquistato: false,
          current: true,
        })

      console.log('Documento creato correttamente!')
    }

    const bg = ref('')
    const cerimoniaIndirizzo = ref('')
    const cerimoniaOra = ref('')
    const cerimoniaTipologia = ref('')
    const nomeUtente = ref('')
    const servizio1 = ref('')
    const servizio2 = ref('')
    const servizio3 = ref('')
    const servizio4 = ref('')
    const servizio5 = ref('')
    const servizio6 = ref('')
    const servizio7 = ref('')
    const servizio8 = ref('')
    const servizio9 = ref('')
    const servizio10 = ref('')
    const dataMatrimonio = ref('')
    const durataMatrimonio = ref('')
    const fotoProfilo = ref('')
    const hashtag = ref('')
    const opStart = ref('')
    const ricevimentoIndirizzo = ref('')
    const ricevimentoLocation = ref('')
    const sposo1Nome = ref('')
    const sposo1Telefono = ref('')
    const sposo1Email = ref('')
    const sposo2Nome = ref('')
    const sposo2Telefono = ref('')
    const sposo2Email = ref('')
    const torneo = ref('')

    const types = ['image/png', 'image/jpeg', 'image/jpg']

    const riempiCampi = async () => {
      //bg
      if (bg.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            bg: bg.value,
          })
      }

      //cerimonia indirizzo
      if (cerimoniaIndirizzo.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'cerimonia.indirizzo': cerimoniaIndirizzo.value,
          })
      }

      //cerimonia ora
      if (cerimoniaOra.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'cerimonia.ora': cerimoniaOra.value,
          })
      }

      //cerimonia tipologia
      if (cerimoniaTipologia.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'cerimonia.tipologia': cerimoniaTipologia.value,
          })
      }

      //servizio 1
      if (servizio1.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio1.value
            ),
          })
      }

      //servizio 2
      if (servizio2.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio2.value
            ),
          })
      }

      //servizio 3
      if (servizio3.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio3.value
            ),
          })
      }

      //servizio 4
      if (servizio4.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio4.value
            ),
          })
      }

      //servizio 5
      if (servizio5.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio5.value
            ),
          })
      }

      //servizio 6
      if (servizio6.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio6.value
            ),
          })
      }

      //servizio 7
      if (servizio7.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio7.value
            ),
          })
      }

      //servizio 8
      if (servizio8.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio8.value
            ),
          })
      }

      //servizio 9
      if (servizio9.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio9.value
            ),
          })
      }

      //servizio 10
      if (servizio10.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            contratto: firebase.firestore.FieldValue.arrayUnion(
              servizio10.value
            ),
          })
      }

      //data matrimonio
      if (dataMatrimonio.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            dataMatrimonio: new Date(dataMatrimonio.value),
          })
      }

      //durata matrimonio
      if (durataMatrimonio.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            durataMatrimonio: durataMatrimonio.value,
          })
      }

      //foto profilo
      if (fotoProfilo.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            fotoProfilo: fotoProfilo.value,
          })
      }

      //hashtag
      if (hashtag.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            hashtag: hashtag.value,
          })
      }

      //nome utente
      if (nomeUtente.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            nomeUtente: nomeUtente.value,
          })
      }

      //opStart/opTotal
      if (opStart.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            opStart: firebase.firestore.FieldValue.increment(opStart.value),
            opTotal: firebase.firestore.FieldValue.increment(opStart.value),
          })
      }

      //ricevimento indirizzo
      if (ricevimentoIndirizzo.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'ricevimento.indirizzo': ricevimentoIndirizzo.value,
          })
      }

      //ricevimento location
      if (ricevimentoLocation.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'ricevimento.location': ricevimentoLocation.value,
          })
      }

      //sposo1 nome
      if (sposo1Nome.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'sposo1.nome': sposo1Nome.value,
          })
      }

      //sposo1 telefono
      if (sposo1Telefono.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'sposo1.telefono': sposo1Telefono.value,
          })
      }

      //sposo1 email
      if (sposo1Email.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'sposo1.email': sposo1Email.value,
          })
      }

      //sposo2 nome
      if (sposo2Nome.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'sposo2.nome': sposo2Nome.value,
          })
      }

      //sposo2 telefono
      if (sposo2Telefono.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'sposo2.telefono': sposo2Telefono.value,
          })
      }

      //sposo2 email
      if (sposo2Email.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            'sposo2.email': sposo2Email.value,
          })
      }

      //torneo
      if (torneo.value) {
        projectFirestore
          .collection('utenti')
          .doc(userId.value)
          .update({
            torneo: torneo.value,
          })
      }
    }

    const fotoProfiloUpload = (e) => {
      const selected = e.target.files[0]
      console.log(selected)

      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Puoi caricare solo immagini in formato jpg o png!'
      }
    }

    return {
      utenti,
      utente,
      creaDocumenti,
      inserisciCampi,
      creaDocumentoUtente,
      userId,
      bg,
      nomeUtente,
      cerimoniaIndirizzo,
      cerimoniaOra,
      cerimoniaTipologia,
      servizio1,
      servizio2,
      servizio3,
      servizio4,
      servizio5,
      servizio6,
      servizio7,
      servizio8,
      servizio9,
      servizio10,
      dataMatrimonio,
      durataMatrimonio,
      fotoProfilo,
      hashtag,
      nomeUtente,
      opStart,
      ricevimentoIndirizzo,
      ricevimentoLocation,
      sposo1Nome,
      sposo1Telefono,
      sposo1Email,
      sposo2Nome,
      sposo2Telefono,
      sposo2Email,
      torneo,
      riempiCampi,
      fotoProfiloUpload,
      fileError,
      filePath,
    }
  },
}
</script>

<style scoped>
.form {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr;
}

.form .btn {
  height: 4rem;
  margin-top: 3rem;
}

.form__row {
}
</style>

<!--
<template>
  <div>
    <button style="margin-top: 10rem" @click="createDoc()">
      Crea utente
    </button>

    <button
      style="margin-top: 10rem"
      @click="deleteDoc('a5gY1YUUGAcFwn4pfZDYMutKDho2')"
    >
      Elimina documento
    </button>

    <button
      style="margin-top: 10rem"
      @click="updateDoc('BP2MJ7u0BKa9PM1tspA5bGXh5WB3')"
    >
      modifica utente
    </button>

    <button
      style="margin-top: 10rem"
      @click="newFields('zxipwkIMpFMoBod38YGIMilg74q1')"
    >
      new fields
    </button>
  </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config'
import getCollection from '@/composables/getCollection'

import { ref } from 'vue'

export default {
  setup() {
    //let opStart = ref(null)
    let docRef = ref(null)

    // retrieve a collection
    // projectFirestore
    //   .collection('users')
    //   .doc('QSqbK9jKI2NB8VLs7b9oFVyPWEw2')
    //   .get()
    //   .then((snapshot) => {
    //     const document = snapshot.data()
    //     opStart.value = document.opStart
    //   })

    const { documents: utenti } = getCollection('utenti', '', '')

    const createDoc = () => {
      const users = [
        'a5gY1YUUGAcFwn4pfZDYMutKDho2',
        'aBSFgehDUAaEhGQowDnAKxcRBU12',
        'aF9uzPVyvQdZCi8WLj1YMQ6PSbO2',
        'ayLh2SmVppVY9wGXWeEuU8n1eel1',
        'bb5vOJqrJea9b4ey7W05Tr7vudi2',
        'bqDP7tFaU7Th9SEO9VowxhJ4IF42',
        'bvO8u0XJwHSSCPzDNM3Sv295QuE3',
        'cBSQRt9wWhh6h42ZqB2nPJYnFl33',
        'cQMLUKIcUObecGNT5OFxC3RXiwn2',
        'caKeYujFAufrzgCcphAQuloAttC3',
        'cwrGx3AfDXeOJsWDqZ33KYJ1plK2',
        'dtUo5Ob2VQZd7QxMnjL2fX3HN622',
        'e7kZW8iu2wbA5BvwoaGg8Qq7Gn72',
        'eWudc9ASOoV2XZA6KuzhnzyMwUB3',
        'ebneyx8RJtd1IdXuD31ip9Waq3z2',
        'egpgQZGHgodmbuhV1WbhltRpKSa2',
        'eho0reXek7UWQrMUm23qhtVPNrr1',
        'ewzoq5KgsuPPqmCukReoj8I1pHi1',
        'exAI4rXi36SGu8kpa5WT9AqHRzf2',
        'f4pgQWvK8Adi9ZjacYe6DBozzqz2',
        'fBR44l5GRiPeoRk9KDRcdxI93sp2',
        'fRCeKARMqmPHIueAAtpV7WHwcEl2',
        'fh2zIihacxaJE1UJR8DnHln3ufs2',
        'gAb9fuBf7cOGXg50kYcywsrsetn1',
        'gVSWdEZkuAaVPdTieExGPasfLv03',
        'goEWUhBXRGRFLJ6NAFmkl0QTuxy1',
        'gzQlFFwkpWa2wkkpcm3rGD4AoSH2',
        'h1ZL7iZ3WidQgeAikV2be5af1hB3',
        'huPJGUjT3MXqY211bugeOc7px0F3',
        'iacJ4aqnpRWLjj1NaaBSJYtvD553',
        'im7ygbUJUVRr1MNTJNzgJ2HRGSk1',
        'ipF3vgIdhbhUC8CeX8MfXtTGlQt2',
        'ivUXvIulLEM7384Z3bcHZAnlWMX2',
        'jdtn8XLzcycd1mT96jGtICc6EOH2',
        'l6kj4ITYyXP0oJz0PG5HkkfTWBB2',
        'lBPBd6Lh6HRPfClLUnaU62ErZx92',
        'lJPzrdkYtmYdkWIPSOpmZOLP2dE2',
        'lXxnhHd7A2ZpBchZwEwj7pO4Pbp2',
        'lgK9Z7YtWMceXc641J981es6OWn2',
        'lgpaA8lxbZM85jHBBI6IYm0PzAa2',
        'm6kZL1GNUyUFLTV4pJBihHjxJx92',
        'mPKPUgdDiDX6ZdXWxQXFbK00nwH2',
        'mbhYJ4mklzTJpdhoA2dBxGxWzir1',
        'nUX3Tqyz9KgxXIH9XMCrgKkC4Ng2',
        'nUkJoFcz5uRV4rZltb1cDJFl8Aa2',
        'nems4XTegoeLbPgSHilaeFYY0qs1',
        'np9rMAd6fDfnZOPsTN62GYLEtW12',
        'nvyQMZQrwDTzkXp4Ui580cCd8uE3',
        'oX0tCLtfCKhULy0s8luMQycLLwA3',
        'ojyGkbrBexYO6VrrksNt7X1qOP23',
        'omr4nUwZGRNJOWCHb0Fzonh93BZ2',
        'ooej5Q9breNOHX6btaQKrbardOH2',
        'pAKPpklf7rVXtFrFz3ORxws3za02',
        'pJNND5aUzlMViRKbY4ViWjdIBOu2',
        'pPOdXSOLVsMdgvzRvxwgiymHcjA3',
        'pW10EnbOTnhbQCgtK1wL0nQdx3D3',
        'pYhBeAH1aCdk0V36LDwgplhSdps2',
        'pcG3Nt4s3abQhC3F4vShmZBgLQ73',
        'pcwFBOlbuAYRcopj25RAlhucM7J2',
        'pxhw41sbZAP4tOqta858f4vLApj1',
        'qFHC0sncJYOqgfGtKW2ouTeqgGG3',
        'qbNv8MxPEWOdOm08ymQW7fHtPKw1',
        'qhw0wnOzRigbbI14mXZKrXOzvbM2',
        'rEjL2yrV22dCmY8LkBS45MyJEDT2',
        'rNRbTrKn2gTmNahNim8YuPpTW533',
        'rPtYOmklp1chgXQVKP2nCS1PVVY2',
        'reheuSjPR2OE5Jp2Q3jlWhNc3eJ2',
        's7un8rBDACXU7H2CQodS7ydkLP73',
        'sga0o5fCBWWmJIH2Ar6RUMksbda2',
        't7c6Dui2VrUXJdGGoS9yrh2vZDJ3',
        'tewsmtRrX3bu38NSnW1c5MIEsQq1',
        'tlEawhKMG8XX1axjjfqJO9y78Sd2',
        'txyRDQTQhFWKl6fTTtybJFGT7T23',
        'uPj91XiNJudVbNWWcBRWup0ndW13',
        'uUQxejNlj1TJ7ArghmyU7NT82Sn2',
        'umDVNCvvFFVbLCFOGwzN2WyiFzp1',
        'v3ZwQc27Slh65CR4RdvWYi14XZi2',
        'vAcogGXtrVZlakoDSPqQ0sB1B6p2',
        'vC5LpFkkmISxphwlMqimOnVY7SI2',
        'vD4ijcUY09Or4X5Y34kas8xs1Km2',
        'vEPThFhLZceBFpzskP4iJeGElGq2',
        'vFfzWadkFhSntCt8i6GqzIcv4Ak2',
        'vKuVw6KYGzOUFy0jXBqSAWb0WqH3',
        'vMrDeLEGurafyHzrGGAaFVj8JOE3',
        'vkoX28bFYFThVEkVjsILQNvojMY2',
        'vw8prNn8alatsQ52LVlk2ocqNRH2',
        'wOb4swns9Xc4aj11RZPXYEm0WdO2',
        'wOkHm0od4ifVxz6lOYoUVJ1ZLdk1',
        'wTQkz8ECPBVfYT3FvkwQEqLwYy22',
        'wVaUjYtTbJcML9uZOiib7M8QMxf2',
        'x96ktCIqAQU7lsAW5eFvAxqTq782',
        'xFkv6QIhD8ZjQHvttxx3FZcnkci1',
        'xJmT05BlU2YKwr8ukhm50dgmsdh2',
        'xZEmRZfQfCZ1nYWdli1N4gcviD73',
        'xcZOLnQDypX2WJZDhCXBlsGXIG03',
        'xrOvN7fmguaQNM1ytEeRXsa0aro1',
        'xtN9dkZejCU12kYOw3hAOPM1ySw1',
        'yJz9WCxqZ0b7nKDGrHogtF35RqZ2',
        'yk4pWh6TMbW3p5ZfB5RWa2NKumH2',
        'ypav2PcJeDP3jrr73xQAuq1uBXM2',
        'zTPwHLT3pOMTCmuY4rCeplE1X352',
        'zbHX4w3n8WS00vTNIJmKkJA1Ijh1',
        'zkWyTHD9XvUQtzXePWoKkp1CsUx2',
        'zxipwkIMpFMoBod38YGIMilg74q1',
      ]

      users.forEach((el) => {
        projectFirestore
          .collection('utenti')
          .doc(el)
          .collection('obiettivi')
          .doc('tag-fb')
          .set({
            raggiunto: false,
          })
      })
    }

    const updateDoc = async (id) => {
      docRef = projectFirestore
        .collection('utenti')
        .doc(id)
        .collection('obiettivi')
        .doc('300')

      docRef
        .update({
          stato: 'attiva',
        })
        .then(() => {
          console.log('user updated!')
        })
    }

    const deleteDoc = async () => {
      const users = [
        'a5gY1YUUGAcFwn4pfZDYMutKDho2',
        'aBSFgehDUAaEhGQowDnAKxcRBU12',
        'aF9uzPVyvQdZCi8WLj1YMQ6PSbO2',
        'ayLh2SmVppVY9wGXWeEuU8n1eel1',
        'bb5vOJqrJea9b4ey7W05Tr7vudi2',
        'bqDP7tFaU7Th9SEO9VowxhJ4IF42',
        'bvO8u0XJwHSSCPzDNM3Sv295QuE3',
        'cBSQRt9wWhh6h42ZqB2nPJYnFl33',
        'cQMLUKIcUObecGNT5OFxC3RXiwn2',
        'caKeYujFAufrzgCcphAQuloAttC3',
        'cwrGx3AfDXeOJsWDqZ33KYJ1plK2',
        'dtUo5Ob2VQZd7QxMnjL2fX3HN622',
        'e7kZW8iu2wbA5BvwoaGg8Qq7Gn72',
        'eWudc9ASOoV2XZA6KuzhnzyMwUB3',
        'ebneyx8RJtd1IdXuD31ip9Waq3z2',
        'egpgQZGHgodmbuhV1WbhltRpKSa2',
        'eho0reXek7UWQrMUm23qhtVPNrr1',
        'ewzoq5KgsuPPqmCukReoj8I1pHi1',
        'exAI4rXi36SGu8kpa5WT9AqHRzf2',
      ]

      users.forEach((el) => {
        projectFirestore
          .collection('utenti')
          .doc(el)
          .collection('missioni')
          .doc('300')
          .delete()
      })
    }

    const newFields = async (id) => {
      docRef = projectFirestore.collection('utenti').doc(id)

      docRef
        .update({
          forzieriBronzo: 0,
          forzieriArgento: 0,
          forzieriOro: 0,
        })
        .then(() => {
          console.log('user updated!')
        })
    }

    return { createDoc, updateDoc, utenti, newFields, deleteDoc }
  },
}
</script>

<style></style>
-->

<!--
UTENTI

'16Rvaoqa66UMTvOn8i0H8EDFjR03',
'1IgBFOMTekhbzKfM7NkJK8PFZaJ2',
'1mVwrVnSV9ZFO9lsyCXv4D7u2tk1',
'2IEvcWh7SWaLG3C3WAwhWVEpk8J2',
'2KLmFSbAwvgRzYOYBvW1bB52LH23',
'2PwG68Be6mWSzlianxFMmxurcxF3',
'2xzih17xkcXuCgr2otGV00hRYgI3',
'3b0CEkebbqWpFyzAqJyV600raU83',
'4IkRX7cTxCQGlM9E4uXKzQEm7G63',
'4S4YIICPXzatjjHGPH0lPk4Onx53',
'4eVzWPXdaTeQML3kJ9Tqd6H0i2B3',
'51CyX73NGFYnISGPsEf4DJHVgTh1',
'53TrlNvRwdTCMKVqs3FLLsl6uDI3',
'5Cjk9vv4TqazItWWA47ejvXtDxy2',
'5oEWX4uYx8giknXFZF7YPCQnw7P2',
'60w1hK9FbSSqwufvEKV9RVwyOMT2',
'6BqRmSO5ZZTcVhrnsmPe47k6kCt1',
'6TnEWlAyyNfyndsVjvcAOyF1w9k2',
'6Whn8RWPaUNp393xLsrELZJre133',
'6e21DCZOJTTDbUQRuLwffAn8rYG3',
'6fxxHfRSBRN94bJN4onD30RYc0G2',
'6pxG4G6gk4a8XzEHm3aIqgMVoag2',
'73htzq1tCtOUzy1XcSCXDsycETF3',
'7nEm0Ia0pzQyujOWZ5QhyiR7ZG33',
'85tKb7WtY9V07xtM18Nkd3DtXXm1',
'8Bg1gdEKSjN7AmPewOKPEisAGNv2',
'8K6KH9ouXvffPLhwPxFdPZiGyLr2',
'8PNFqTAy1pTgn104OPfwuppq7B63',
'8UsxpKT6FsbY4t1rrox7hsWLBKC2',
'8Zvr6YC9TdMDPbdIcmm3YeDGw1I3',
'9ENFd0zxkcQgPO06CRyha9usdpi2',
'9IMRx6U9WKUYQs8OzYsd47vTUzM2',
'9TU2x6yp4yWwICqH0tq1oyPjps62',
'9UINRiAPBUViLMi17u4FOyrEH662',
'9WBd8XW7A6gsM0QdS57s1uytTIw2',
'AatC1AuMKhQTDtutzpXMbJGtZ7g1',
'AvbBbZdMhFUyzTlXkUmsIPmzAwt2',
'B5AcMP2ITnavCyVkhZYh1MPRwi13',
'BMjXcKsdMUaahbbbXhwP4WdI4eh2',
'BP2MJ7u0BKa9PM1tspA5bGXh5WB3',
'BQkbFvEnROOCVVovUKrMNiRa6DG3',
'BpnBTcwKmWVYMXQhrlXN6vMqOxN2',
'Bqbz4ewmGrcphD7B5cDQxBzbu5H2',
'ByxAR1UdYkRZNAPWmTTsatR8I8r1',
'C2sEzuSlyVcGqM6ScrEX8CCwBsL2',
'CJq7P909M9emXA2RKOOpYWOYBkx1',
'CfDP4DoTsIWALXPJyAsL7355lyi2',
'ClHqZ7jvb8NO7ClElsbT4pYIA2v2',
'CztEYEJi8HUrdx3DKACNSk9gkHp2',
'DQBh5gLLILOoD5lnJz5hd1ntmx73',
'DQLX5Kr3rFVTy7EXp8Wfz28Mo4J2',
'DtJT2Sqjj2ccMO4Amn9iBQbKyIv1',
'DzqFNKnG3uSRweGtk4Qv08rOki52',
'E9Zpui8LqjORQYzkKCRAo5f5kMt1',
'ESrFtuGUmuZ9Qod0lNJDeogWk5r2',
'F7X13Y2CZnRa9zep52ifbxB56KB2',
'FBjx7wEnbmfvq0lNbzlkqetcjDV2',
'FNOOvvp4czXKvNTmBJqqUxPwtlo2',
'FZHFDGDLsGZOmXxjGWj851FU5bb2',
'Frw6GxyS7XVaN5gpaEKlkZR0TXp1',
'FwLYzJGVxufNUJufNqOp6wmIhIh1',
'Ginh4K3BkTXvtDlasXTyhx7QD4O2',
'Hen2Y5ibNESoOdDoAWVjocII0GR2',
'Hj46PVl0Jrci9kDcrzP2zHBOgSk2',
'HjF5SlynvTUXENOGuQBCFC1Fexy1',
'I4wKD9uVUjh9xySrbFOzBhnm33k1',
'I518x3B2EjRcVrvD1XiDjT7DXsQ2',
'IQbchhvDWzOCsxaYA3IGLBIXk2u1',
'J081zmEvTCM5hZ1VzSHIp2Ests63',
'J0NPoPTuVNY4SBLrXhhjwin6vcn1',
'JARxgiyaVjfEl77BRUGgbz4uy9z1',
'JXlgDXd6hTXv2GbIHTo9idEAIWt2',
'JbeZzOW5fscdpmjvC6S5KNVw03j1',
'JbkEAUMuPYZbf7pNjr5LOxG1Ijr1',
'JgZ2e8xzRggUZqH3PrJ4iBvyPRy1',
'JjpcNtt2EFaP8efATdwxUZC0yYN2',
'JrVmvJpOv1ULIvKvt7z4NDOHJGq1',
'JuB2Ie0papZlqE2R2hrTJi9Ik4z1',
'K2AvCGIBAvgjdKAQxQY4X3Y7K1I2',
'KbIvjM6A1rayHQymgnF5RXmur0g2',
'Lf2HDpoxv0MAlAVxRgwJYK9Cgdc2',
'LuDv3DXLx3Q960Gz7AA7O9ZRijF2',
'MAkpAtBSekV0n7NKflEeK5QdgJH2',
'MIbri6C8GNfL5CBPbZJmejshHZK2',
'MsQLe1Jo6ZONgofGVXdUHLsbTF63',
'Mv4Loqr4osYPrkgYAbylcYh5h5d2',
'NNrdO18oKfSxzurkJ4QCADEFAWA2',
'NQUlTu3tCXS1ihYFydS4etOdpMr1',
'NWOQ1wlU8ZhN2SPC5f2loFdHTBv2',
'NyrMGL2r19dkrFvvtXBNtH0f7Fk1',
'O1XuTCSn5Gcl0xC5so5NSH8Nf1n2',
'O3lgux0kO4fYyNaefEDDIN5CdUs1',
'O9bzFWhpeFTB5jqXgExEXQsJYYu2',
'OKuefE5bVpRURtHICjH0DIFvuGp2',
'P2RZYEDmgQOZ9IbHixrhVffvRT53',
'PEKfbxUiyFcRAQjslgxsaRkqXzf2',
'PMDrb0XvPrdQ3zF8leqyqV3gOIu2',
'Pkd4E51OjaYuXVv8p1XyV2KbMY92',
'Pq90dYyerpZBiF1U2penObtfEWy1',
'QCtr6mwgKhRdleeqw0GlnA5mTt23',
'QSqbK9jKI2NB8VLs7b9oFVyPWEw2',
'Qop3M0l2PrMdjhno3BntdrePvWo1',
'QqhK8h712TbaCryHOlRyv0oWg7i1',
'QqvhwTdSTlOLxhEP5Pzz4EqeiDQ2',
'QtjK4Ivse3fkgUPkWCVEx8Hazrp2',
'QvgJYdpcg0NQPIVQYgwaVdN5UVM2',
'QwjpaNRvtMOhjhq9moZsrwthtXQ2',
'R6xHNctnNEbqyG1UijAFOZqVM4y2',
'RLaYwiRLjPep2InI6vw600Lx3LN2',
'RN3kYTecj1Uv2UQnwvHRKXIeemq2',
'RS1mgwlY8HW71nqHnlXoY5NtuDV2',
'RnZ5o4Ct7xTch0AUaQ4GaM1SW4C3',
'RrquR6jJ5CZMyvK5lbzAlUo8laj2',
'S2A8tUUcMFeHtvNqzMxqnOlhzab2',
'S9tjE4kkCmRoSrwz6lMyP5IjSng2',
'Se4Z00WYaZVn06IRgaRfl0y3Fc63',
'SuAbIr0rzabUikjdBloLlklLjat2',
'TQ6AtF8OwbUiG6sYM2hTEF2QwQ73',
'TSlewGFXmXe0aL4jbU3UIa4zEsw1',
'TYhTkD886cd58joajHSzJUda06T2',
'TclgLKNk5LS4rKouPWkDyAIcdb22',
'U4RWiP36PcWTKUrbJ8owrxQ1U152',
'UKUChFkFFdQC8LfvFD6h83th8dL2',
'UTNNR6XHEgV4KyQHwcZxAMSCXom2',
'UhgZe18KMNVN8pjcsyzqYrFiWF23',
'Un4tn1S8a3TS1XsVHeEDxuu77ek2',
'Uur61tErebckkDhrdO5ojB7yhcz1',
'VCRQ2l4pp0cwAKb7Bzpu9XCKM083',
'VH3MlckvytUzqQ9p0FVFM4XRUVq1',
'VHsp2eoEBNOt3lRn1b3Ir9Rq6FO2',
'VPn3YuWMtOVnG6okCPIKoOD3Wpd2',
'Wh6CQx9snEZAfm9X7rcbwVKs8vp2',
'Wy4au5vwkCUAm1JicgIG6K5Vl2j1',
'X0FI4x8P2OVtA4b6cCiMvzXnoyk2',
'XDykhmDqc6VwGjXJUgfv2cp9zT52',
'XFQf0WBx1MePNnUU09n6GDazpcf1',
'XnsDHZeCdTharZ5ph2MaC55a9sq2',
'Y5npp181hFa07921rh4yAYaTDvs1',
'YIlpcxLntwPyH28WFIoRAY2m8Za2',
'YhLdNvhrqoauQw9H3XPu0N0udIv2',
'YuJrmAycIAgvPqBzXfENvYXsRo23',
'Yyg0xMpq1RRYte7YtCdtcMPbn0f2',
'Z1yUqaG0yMOpPVHuaAwiQo5CN4g2',
'Z6S2JIbyvKTofunKKNQDpBPVtWJ3',
'ZBDDPPQSICRr9uVREpi4w9OL8212',
'ZOhGzibwykcM6s7NLUgIOLzJhaF2',
'ZWWoZP1MF9ZETcvwfAtv4Q7LG8t2',
'ZuKMJCKfBxgJqvAOBPQbaR0G4QR2',
'ZuZt2ligPWfzPQ8qwN8EuFciYTm2',
'ZvssGDQuTzMYCxG4FCGRnmoyqQB3',
'a5gY1YUUGAcFwn4pfZDYMutKDho2',
'aBSFgehDUAaEhGQowDnAKxcRBU12',
'aF9uzPVyvQdZCi8WLj1YMQ6PSbO2',
'ayLh2SmVppVY9wGXWeEuU8n1eel1',
'bb5vOJqrJea9b4ey7W05Tr7vudi2',
'bqDP7tFaU7Th9SEO9VowxhJ4IF42',
'bvO8u0XJwHSSCPzDNM3Sv295QuE3',
'cBSQRt9wWhh6h42ZqB2nPJYnFl33',
'cQMLUKIcUObecGNT5OFxC3RXiwn2',
'caKeYujFAufrzgCcphAQuloAttC3',
'cwrGx3AfDXeOJsWDqZ33KYJ1plK2',
'dtUo5Ob2VQZd7QxMnjL2fX3HN622',
'e7kZW8iu2wbA5BvwoaGg8Qq7Gn72',
'eWudc9ASOoV2XZA6KuzhnzyMwUB3',
'ebneyx8RJtd1IdXuD31ip9Waq3z2',
'egpgQZGHgodmbuhV1WbhltRpKSa2',
'eho0reXek7UWQrMUm23qhtVPNrr1',
'ewzoq5KgsuPPqmCukReoj8I1pHi1',
'exAI4rXi36SGu8kpa5WT9AqHRzf2',
'f4pgQWvK8Adi9ZjacYe6DBozzqz2',
'fBR44l5GRiPeoRk9KDRcdxI93sp2',
'fRCeKARMqmPHIueAAtpV7WHwcEl2',
'fh2zIihacxaJE1UJR8DnHln3ufs2',
'gAb9fuBf7cOGXg50kYcywsrsetn1',
'gVSWdEZkuAaVPdTieExGPasfLv03',
'goEWUhBXRGRFLJ6NAFmkl0QTuxy1',
'gzQlFFwkpWa2wkkpcm3rGD4AoSH2',
'h1ZL7iZ3WidQgeAikV2be5af1hB3',
'huPJGUjT3MXqY211bugeOc7px0F3',
'iacJ4aqnpRWLjj1NaaBSJYtvD553',
'im7ygbUJUVRr1MNTJNzgJ2HRGSk1',
'ipF3vgIdhbhUC8CeX8MfXtTGlQt2',
'ivUXvIulLEM7384Z3bcHZAnlWMX2',
'jdtn8XLzcycd1mT96jGtICc6EOH2',
'l6kj4ITYyXP0oJz0PG5HkkfTWBB2',
'lBPBd6Lh6HRPfClLUnaU62ErZx92',
'lJPzrdkYtmYdkWIPSOpmZOLP2dE2',
'lXxnhHd7A2ZpBchZwEwj7pO4Pbp2',
'lgK9Z7YtWMceXc641J981es6OWn2',
'lgpaA8lxbZM85jHBBI6IYm0PzAa2',
'm6kZL1GNUyUFLTV4pJBihHjxJx92',
'mPKPUgdDiDX6ZdXWxQXFbK00nwH2',
'mbhYJ4mklzTJpdhoA2dBxGxWzir1',
'nUX3Tqyz9KgxXIH9XMCrgKkC4Ng2',
'nUkJoFcz5uRV4rZltb1cDJFl8Aa2',
'nems4XTegoeLbPgSHilaeFYY0qs1',
'np9rMAd6fDfnZOPsTN62GYLEtW12',
'nvyQMZQrwDTzkXp4Ui580cCd8uE3',
'oX0tCLtfCKhULy0s8luMQycLLwA3',
'ojyGkbrBexYO6VrrksNt7X1qOP23',
'omr4nUwZGRNJOWCHb0Fzonh93BZ2',
'ooej5Q9breNOHX6btaQKrbardOH2',
'pAKPpklf7rVXtFrFz3ORxws3za02',
'pJNND5aUzlMViRKbY4ViWjdIBOu2',
'pPOdXSOLVsMdgvzRvxwgiymHcjA3',
'pW10EnbOTnhbQCgtK1wL0nQdx3D3',
'pYhBeAH1aCdk0V36LDwgplhSdps2',
'pcG3Nt4s3abQhC3F4vShmZBgLQ73',
'pcwFBOlbuAYRcopj25RAlhucM7J2',
'pxhw41sbZAP4tOqta858f4vLApj1',
'qFHC0sncJYOqgfGtKW2ouTeqgGG3',
'qbNv8MxPEWOdOm08ymQW7fHtPKw1',
'qhw0wnOzRigbbI14mXZKrXOzvbM2',
'rEjL2yrV22dCmY8LkBS45MyJEDT2',
'rNRbTrKn2gTmNahNim8YuPpTW533',
'rPtYOmklp1chgXQVKP2nCS1PVVY2',
'reheuSjPR2OE5Jp2Q3jlWhNc3eJ2',
's7un8rBDACXU7H2CQodS7ydkLP73',
'sga0o5fCBWWmJIH2Ar6RUMksbda2',
't7c6Dui2VrUXJdGGoS9yrh2vZDJ3',
'tewsmtRrX3bu38NSnW1c5MIEsQq1',
'tlEawhKMG8XX1axjjfqJO9y78Sd2',
'txyRDQTQhFWKl6fTTtybJFGT7T23',
'uPj91XiNJudVbNWWcBRWup0ndW13',
'uUQxejNlj1TJ7ArghmyU7NT82Sn2',
'umDVNCvvFFVbLCFOGwzN2WyiFzp1',
'v3ZwQc27Slh65CR4RdvWYi14XZi2',
'vAcogGXtrVZlakoDSPqQ0sB1B6p2',
'vC5LpFkkmISxphwlMqimOnVY7SI2',
'vD4ijcUY09Or4X5Y34kas8xs1Km2',
'vEPThFhLZceBFpzskP4iJeGElGq2',
'vFfzWadkFhSntCt8i6GqzIcv4Ak2',
'vKuVw6KYGzOUFy0jXBqSAWb0WqH3',
'vMrDeLEGurafyHzrGGAaFVj8JOE3',
'vkoX28bFYFThVEkVjsILQNvojMY2',
'vw8prNn8alatsQ52LVlk2ocqNRH2',
'wOb4swns9Xc4aj11RZPXYEm0WdO2',
'wOkHm0od4ifVxz6lOYoUVJ1ZLdk1',
'wTQkz8ECPBVfYT3FvkwQEqLwYy22',
'wVaUjYtTbJcML9uZOiib7M8QMxf2',
'x96ktCIqAQU7lsAW5eFvAxqTq782',
'xFkv6QIhD8ZjQHvttxx3FZcnkci1',
'xJmT05BlU2YKwr8ukhm50dgmsdh2',
'xZEmRZfQfCZ1nYWdli1N4gcviD73',
'xcZOLnQDypX2WJZDhCXBlsGXIG03',
'xrOvN7fmguaQNM1ytEeRXsa0aro1',
'xtN9dkZejCU12kYOw3hAOPM1ySw1',
'yJz9WCxqZ0b7nKDGrHogtF35RqZ2',
'yk4pWh6TMbW3p5ZfB5RWa2NKumH2',
'ypav2PcJeDP3jrr73xQAuq1uBXM2',
'zTPwHLT3pOMTCmuY4rCeplE1X352',
'zbHX4w3n8WS00vTNIJmKkJA1Ijh1',
'zkWyTHD9XvUQtzXePWoKkp1CsUx2',
'zxipwkIMpFMoBod38YGIMilg74q1'

-->
