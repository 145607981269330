import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyDMIieFI5V1AVa_Xp4d9FiiUwaCnwLnPhs',
  authDomain: 'omme-area-sposi.firebaseapp.com',
  projectId: 'omme-area-sposi',
  storageBucket: 'omme-area-sposi.appspot.com',
  messagingSenderId: '159543355288',
  appId: '1:159543355288:web:73c5746d1f65fdf9846ded',
  measurementId: 'G-LNE1QRK4V5',
}

//init firebase
export const db = firebase.initializeApp(firebaseConfig)

//init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()
const projectAnalytics = firebase.analytics()

//timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export {
  projectFirestore,
  projectAuth,
  projectStorage,
  timestamp,
  projectAnalytics,
}
