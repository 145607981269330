<template>
  <div>
    <Bg />

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <section class="settings settings--profile">
      <h2 class="settings__heading">Profilo</h2>

      <form
        class="form settings__form"
        v-if="utente"
        @submit.prevent="modificaProfilo"
      >
        <!-- Nome Utente -->
        <div class="form__row">
          <label>Nome Utente</label>
          <input
            type="text"
            :placeholder="utente.nomeUtente"
            v-model="nomeUtente"
            v-if="isEditing"
          />

          <p v-else @click="isEditing = !isEditing">{{ utente.nomeUtente }}</p>
        </div>

        <!-- Data Matrimonio -->
        <div class="form__row">
          <label>Data matrimonio</label>

          <input
            type="datetime-local"
            :placeholder="utente.dataMatrimonio"
            v-model="dataMatrimonio"
            v-if="isEditing"
          />

          <p v-else @click="isEditing = !isEditing">
            {{
              utente.dataMatrimonio.toDate().toLocaleString('it-IT', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })
            }}
          </p>
        </div>

        <!-- Email -->
        <div class="form__row">
          <label>Email</label>
          <p>{{ userEmail }}</p>

          <small>
            Per cambiare l'indirizzo email, contatta il team OMME Music
          </small>
        </div>

        <!-- Password 
        <div class="form__row">
          <label for="password">Password</label>
          <button
            class="btn btn--small btn--secondary btn--pwd"
            @click.prevent="forgetPassword"
          >
            Cambia password
          </button>

          <small>
            Per cambiare la password, clicca sul bottone, ti verrà inviata una
            mail con le istruzioni per effettuare il cambio
          </small>
        </div>-->

        <!-- Foto Profilo -->
        <div class="form__row">
          <label>Foto profilo</label>

          <div
            class="form__profile-pic"
            v-if="!isEditing"
            @click="isEditing = !isEditing"
          >
            <img :src="utente.fotoProfilo" :alt="utente.nomeUtente" />

            <div class="btn btn--small btn--secondary">Modifica</div>
          </div>

          <input type="file" @change="handleChange" v-if="isEditing" />
        </div>

        <!-- Hashtag -->
        <div class="form__row">
          <label>Hashtag Instagallery</label>
          <input
            type="text"
            :placeholder="utente.hashtag"
            v-model="hashtag"
            v-if="isEditing"
          />
          <p v-else @click="isEditing = !isEditing">{{ utente.hashtag }}</p>
        </div>

        <button class="btn btn--small btn--primary">Salva</button>
      </form>

      <div class="form__submit-ok" v-if="submitOkProfilo">
        Modifiche salvate correttamente!
      </div>
    </section>

    <!--<section class="settings">
      <h2 class="settings__heading">Social</h2>

      <ul class="settings__accounts">
        <li>
          <strong>Account Facebook</strong>
          <a class="btn btn--fb" href="#"
            ><i class="bx bxl-facebook-square"></i> Connetti</a
          >
        </li>
        <li>
          <strong>Profilo Instagram</strong
          ><a class="btn btn--ig" href="#"
            ><i class="bx bxl-instagram"></i>Collega</a
          >
        </li>
      </ul>

      <form class="form settings__form">
        <div class="form__row">
          <label for="hashtag">Hashtag Instagallery</label>
          <a href="#" class="form__value">#martyeluca12giugno2021</a>
        </div>
      </form> 
    </section>-->

    <section class="settings settings--sposo1">
      <h2 class="settings__heading">Sposo/a</h2>

      <form
        class="form settings__form"
        @submit.prevent="modificaSposo1"
        v-if="utente"
      >
        <!-- Nome e cognome -->
        <div class="form__row">
          <label>Nome e cognome</label>

          <input
            type="text"
            :placeholder="utente.sposo1.nome"
            v-model="sposo1Nome"
            v-if="isEditingSposo1"
          />

          <p v-else @click="isEditingSposo1 = !isEditingSposo1">
            {{ utente.sposo1.nome }}
          </p>
        </div>

        <!-- Telefono -->
        <div class="form__row">
          <label>Recapito telefonico</label>

          <input
            type="text"
            :placeholder="utente.sposo1.telefono"
            v-model="sposo1Telefono"
            v-if="isEditingSposo1"
          />

          <p v-else @click="isEditingSposo1 = !isEditingSposo1">
            {{ utente.sposo1.telefono }}
          </p>
        </div>

        <!-- Email -->
        <div class="form__row">
          <label>Email</label>

          <input
            type="email"
            :placeholder="utente.sposo1.email"
            v-model="sposo1Email"
            v-if="isEditingSposo1"
          />

          <p v-else @click="isEditingSposo1 = !isEditingSposo1">
            {{ utente.sposo1.email }}
          </p>
        </div>

        <!-- Indirizzo -->
        <div class="form__row">
          <label>Indirizzo</label>

          <input
            type="text"
            :placeholder="utente.sposo1.indirizzo"
            v-model="sposo1Indirizzo"
            v-if="isEditingSposo1"
          />

          <p v-else @click="isEditingSposo1 = !isEditingSposo1">
            {{ utente.sposo1.indirizzo }}
          </p>
        </div>

        <button class="btn btn--small btn--primary">Salva</button>
      </form>

      <div class="form__submit-ok" v-if="submitOkSposo1">
        Modifiche salvate correttamente!
      </div>
    </section>

    <section class="settings settings--sposo2">
      <h2 class="settings__heading">Sposo/a</h2>

      <form
        class="form settings__form"
        @submit.prevent="modificaSposo2"
        v-if="utente"
      >
        <!-- Nome e cognome -->
        <div class="form__row">
          <label>Nome e cognome</label>

          <input
            type="text"
            :placeholder="utente.sposo2.nome"
            v-model="sposo2Nome"
            v-if="isEditingSposo2"
          />

          <p v-else @click="isEditingSposo2 = !isEditingSposo2">
            {{ utente.sposo2.nome }}
          </p>
        </div>

        <!-- Telefono -->
        <div class="form__row">
          <label>Recapito telefonico</label>

          <input
            type="text"
            :placeholder="utente.sposo2.telefono"
            v-model="sposo2Telefono"
            v-if="isEditingSposo2"
          />

          <p v-else @click="isEditingSposo2 = !isEditingSposo2">
            {{ utente.sposo2.telefono }}
          </p>
        </div>

        <!-- Email -->
        <div class="form__row">
          <label>Email</label>

          <input
            type="email"
            :placeholder="utente.sposo2.email"
            v-model="sposo2Email"
            v-if="isEditingSposo2"
          />

          <p v-else @click="isEditingSposo2 = !isEditingSposo2">
            {{ utente.sposo2.email }}
          </p>
        </div>

        <!-- Indirizzo -->
        <div class="form__row">
          <label>Indirizzo</label>

          <input
            type="text"
            :placeholder="utente.sposo2.indirizzo"
            v-model="sposo2Indirizzo"
            v-if="isEditingSposo2"
          />

          <p v-else @click="isEditingSposo2 = !isEditingSposo2">
            {{ utente.sposo2.indirizzo }}
          </p>
        </div>

        <button class="btn btn--small btn--primary">Salva</button>
      </form>

      <div class="form__submit-ok" v-if="submitOkSposo2">
        Modifiche salvate correttamente!
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue'
import useStorage from '@/composables/useStorage'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import firebase from 'firebase'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

export default {
  components: { Bg, Topbar },
  setup() {
    const isEditing = ref(false)
    const isEditingSposo1 = ref(false)
    const isEditingSposo2 = ref(false)

    const { err, document: tutorial } = getDocument('tutorial', 'profilo')

    const { user } = getUser()

    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { updateDoc } = useDocument('utenti', user.value.uid)

    const { filePath, url, uploadImage } = useStorage()

    const username = ref('')
    const hashtag = ref('')
    const file = ref(null)
    const fileError = ref(null)

    //sposo 1
    const sposo1Nome = ref('')
    const sposo1Telefono = ref('')
    const sposo1Email = ref('')
    const sposo1Indirizzo = ref('')

    //sposo 2
    const sposo2Nome = ref('')
    const sposo2Telefono = ref('')
    const sposo2Email = ref('')
    const sposo2Indirizzo = ref('')
    const submitOkProfilo = ref(null)
    const submitOkSposo1 = ref(null)
    const submitOkSposo2 = ref(null)

    //allowed file types
    const types = ['image/png', 'image/jpeg', 'image/jpg']

    const userEmail = user.value.email

    //references to input fields
    const nomeUtente = ref('')
    const dataMatrimonio = ref('')

    //modifica profilo
    const modificaProfilo = async () => {
      let res = null

      //nome utente
      if (nomeUtente.value) {
        res = await updateDoc({
          nomeUtente: nomeUtente.value,
        })
      }

      //data matrimonio
      if (dataMatrimonio.value) {
        res = await updateDoc({
          dataMatrimonio: new Date(dataMatrimonio.value),
        })
      }

      //foto profilo
      if (file.value) {
        await uploadImage(file.value)

        res = await updateDoc({
          fotoProfilo: url.value,
        })
        console.log('immagine caricata, url: ', url.value)
      }

      //nome utente
      if (hashtag.value) {
        res = await updateDoc({
          hashtag: hashtag.value,
        })
      }

      isEditing.value = !isEditing.value

      submitOkProfilo.value = true
    }

    const handleChange = (e) => {
      const selected = e.target.files[0]
      console.log(selected)

      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Puoi caricare solo immagini in formato jpg o png!'
      }
    }

    const modificaSposo1 = async () => {
      let res = null

      //nome
      if (sposo1Nome.value) {
        res = await updateDoc({
          'sposo1.nome': sposo1Nome.value,
        })
      }

      //telefono
      if (sposo1Telefono.value) {
        res = await updateDoc({
          'sposo1.telefono': sposo1Telefono.value,
        })
      }

      //email
      if (sposo1Email.value) {
        res = await updateDoc({
          'sposo1.email': sposo1Email.value,
        })
      }

      //indirizzo
      if (sposo1Indirizzo.value) {
        res = await updateDoc({
          'sposo1.indirizzo': sposo1Indirizzo.value,
        })
      }

      isEditingSposo1.value = !isEditingSposo1.value

      submitOkSposo1.value = true
    }

    const modificaSposo2 = async () => {
      let res = null

      //nome
      if (sposo2Nome.value) {
        res = await updateDoc({
          'sposo2.nome': sposo2Nome.value,
        })
      }

      //telefono
      if (sposo2Telefono.value) {
        res = await updateDoc({
          'sposo2.telefono': sposo2Telefono.value,
        })
      }

      //email
      if (sposo2Email.value) {
        res = await updateDoc({
          'sposo2.email': sposo2Email.value,
        })
      }

      //indirizzo
      if (sposo2Indirizzo.value) {
        res = await updateDoc({
          'sposo2.indirizzo': sposo2Indirizzo.value,
        })
      }

      isEditingSposo2.value = !isEditingSposo2.value

      submitOkSposo2.value = true
    }

    const handleSubmit = async () => {
      if (file.value) {
        await uploadImage(file.value)
        console.log('immagine caricata, url: ', url.value)
      }

      const res = await updateDoc({
        nomeUtente: username.value,
        fotoProfilo: file.value,
      })

      console.log('Modifiche salvate correttamente')
    }

    const forgetPassword = () => {
      firebase
        .auth()
        .sendPasswordResetEmail(user.value.email)
        .then(() => {
          alert('Controlla la tua email per cambiare la password')
        })
        .catch((error) => {
          alert(error)
        })
    }

    return {
      isEditing,
      isEditingSposo1,
      isEditingSposo2,
      hashtag,
      username,
      handleSubmit,
      handleChange,
      fileError,
      filePath,
      submitOkProfilo,
      submitOkSposo1,
      submitOkSposo2,
      utente,
      error,
      userEmail,
      forgetPassword,
      nomeUtente,
      dataMatrimonio,
      modificaProfilo,
      sposo1Nome,
      sposo1Telefono,
      sposo1Email,
      sposo1Indirizzo,
      sposo2Nome,
      sposo2Telefono,
      sposo2Email,
      sposo2Indirizzo,
      modificaSposo1,
      modificaSposo2,
      tutorial,
    }
  },
}
</script>

<style></style>
