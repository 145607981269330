<template>
  <div class="tabs" v-if="utente">
    <div class="tabs__menu">
      <button
        class="tabs__btn"
        @click="activeTab = 'info'"
        :class="{ 'tabs__btn--active': activeTab === 'info' }"
      >
        Le vostre info
      </button>

      <button
        class="tabs__btn"
        @click="activeTab = 'wedding'"
        :class="{ 'tabs__btn--active': activeTab === 'wedding' }"
      >
        Il vostro matrimonio
      </button>
    </div>

    <div class="tabs__content">
      <div class="tabs__item" v-if="activeTab === 'info'">
        <div class="tab__info info">
          <div class="info__icon">
            <i class="bx bx-user-circle"></i>
          </div>

          <ul class="info__content">
            <li>
              <strong>{{ utente.sposo1.nome }}</strong>
            </li>
            <li>{{ utente.sposo1.telefono }}</li>
            <li class="tolower">
              {{ utente.sposo1.email }}
            </li>
            <li>{{ utente.sposo1.indirizzo }}</li>
          </ul>
        </div>

        <div class="tab__info info">
          <div class="info__icon">
            <i class="bx bx-user-circle"></i>
          </div>

          <ul class="info__content">
            <li>
              <strong>{{ utente.sposo2.nome }}</strong>
            </li>
            <li>{{ utente.sposo2.telefono }}</li>
            <li class="tolower">{{ utente.sposo2.email }}</li>
            <li>{{ utente.sposo2.indirizzo }}</li>
          </ul>
        </div>
      </div>

      <div class="tabs__item" v-if="activeTab === 'wedding'">
        <div>
          <strong>Matrimonio {{ utente.durataMatrimonio }}</strong>
        </div>
        <div class="tab__info info">
          <div class="info__icon">
            <i class="bx bx-church"></i>
          </div>
          <ul class="info__content">
            <li>
              <strong>Cerimonia {{ utente.cerimonia.tipologia }}</strong>
            </li>
            <li>{{ utente.cerimonia.location }}</li>
            <li>{{ utente.cerimonia.indirizzo }}</li>
            <li>{{ utente.cerimonia.ora }}</li>
          </ul>
        </div>
        <div class="tab__info info">
          <div class="info__icon">
            <i class="bx bx-drink"></i>
          </div>
          <ul class="info__content">
            <li>
              <strong>Ricevimento</strong>
            </li>
            <li>{{ utente.ricevimento.location }}</li>
            <li>{{ utente.ricevimento.indirizzo }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'

export default {
  setup() {
    const activeTab = ref('info')
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    return { activeTab, utente, error }
  },
}
</script>

<style></style>
