<template>
  <div>
    <Bg />

    {{ classifica }}

    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <section class="receipt" v-if="utente">
      <div class="receipt__table">
        <div class="receipt__row">
          <div class="receipt__item">Saldo iniziale</div>
          <div class="receipt__price">
            <span class="ommepoints">{{ utente.opStart }} </span>
          </div>
        </div>

        <div class="receipt__row">
          <div class="receipt__item">Servizi aggiunti con shopping</div>
          <div class="receipt__price">
            <span class="ommepoints">{{ utente.shopTotal }}</span>
          </div>
        </div>

        <div class="receipt__row">
          <div class="receipt__item">Forzieri acquistati</div>
          <div class="receipt__price">
            <span class="ommepoints">
              {{ utente.forzieriTotal }}
            </span>
          </div>
        </div>

        <div class="receipt__row" v-if="utente.puzzleComplete">
          <div class="receipt__item">Puzzle Bonus</div>
          <div class="receipt__price">
            <span class="ommepoints">
              <span v-for="fascia in fasce" :key="fascia">
                <span
                  v-if="
                    utente.opStart >= fascia.opStart.min &&
                      utente.opStart <= fascia.opStart.max
                  "
                >
                  {{ fascia.premio }}
                </span>
              </span>
            </span>
          </div>
        </div>

        <div class="receipt__row receipt__row--total">
          <div class="receipt__item">Totale</div>
          <div class="receipt__price">
            <span class="ommepoints">{{ utente.opTotal }}</span>
          </div>
        </div>

        <div class="receipt__row receipt__row--rank">
          <div class="receipt__item">
            Torneo<br />
            <span
              v-if="
                utente.dataMatrimonio.toDate().getMonth() + 1 == 5 ||
                  utente.dataMatrimonio.toDate().getMonth() + 1 == 6 ||
                  utente.dataMatrimonio.toDate().getMonth() + 1 == 7
              "
              >Sogno d'estate</span
            >
            <span v-else>Vento d'autunno</span>
          </div>
          <div class="receipt__position">
            Posizione
            <span class="badge badge--secondary">
              <div v-for="posizione in classifica" :key="posizione">
                <div v-if="posizione.id === utenteId">
                  {{ classifica.indexOf(posizione) + 1 }}
                </div>
              </div>
            </span>
          </div>
        </div>

        <div class="receipt__barcode">
          <img src="@/assets/images/icons/icon-barcode.svg" />
        </div>
      </div>
    </section>

    <div class="receipt__btn">
      <router-link
        :to="{ name: 'Ranking' }"
        class="btn btn--primary btn--rounded"
        >Classifica completa</router-link
      >
    </div>
  </div>
</template>

<script>
import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { err, document: tutorial } = getDocument(
      'tutorial',
      'servizi-sconti'
    )

    const { documents: fasce } = getCollection('fasce-prezzo')

    const { documents: classifica } = getCollection(
      'utenti',
      ['dataMatrimonio.toDate().getMonth() + 1', 'in', [5, 6, 7]],
      'opStart'
    )

    const utenteId = user.value.uid

    return { error, utente, classifica, utenteId, tutorial, fasce }
  },
}
</script>

<style></style>
