<template>
  <div class="countdown">
    <div class="countdown__grid">
      <div class="countdown__card">
        <span class="countdown__number">{{ months }}</span>
        <span class="countdown__label">mesi</span>
      </div>
      <div class="countdown__card">
        <span class="countdown__number">{{ days }}</span>
        <span class="countdown__label">giorni</span>
      </div>
      <div class="countdown__card">
        <span class="countdown__number">{{ hours }}</span>
        <span class="countdown__label">ore</span>
      </div>
    </div>
    <div class="countdown__footer">al vostro <strong>matrimonio!</strong></div>
  </div>
</template>

<script>
import countdown from "@/composables/countdown"
import getUser from "@/composables/getUser"
import getDocument from "@/composables/getDocument"
import { ref } from "vue"

export default {
  props: ["date"],
  setup(props) {
    const { user } = getUser()
    const { error, document: utente } = getDocument("utenti", user.value.uid)

    const date = ref(props.date.toDate())

    const countDown = countdown(date.value)
    const months = countDown.months
    const days = countDown.days
    const hours = countDown.hours

    return { months, days, hours, utente, date }
  },
}
</script>

<style></style>
