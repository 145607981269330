<template>
  <section class="tracklist" v-if="utente">
    <div class="tracklist__header">
      <div class="tracklist__icon">
        <img src="@/assets/images/icons/icon-cocktail.svg" />
      </div>
      <h2 class="tracklist__title">
        Ricevimento
      </h2>
      <div class="tracklist__btn">
        <button
          class="btn btn--primary"
          @click.prevent="isEditingRicevimento = !isEditingRicevimento"
        >
          Modifica
        </button>
      </div>
    </div>

    <form class="tracklist__list" @submit.prevent="editRicevimento">
      <div class="tracklist__item">
        <label>Arrivo in location:</label>
        <input
          type="text"
          :placeholder="utente.repertorioRicevimento.arrivo"
          v-model="arrivo"
          v-if="isEditingRicevimento"
        />
        <p v-else>
          <small v-if="utente.repertorioRicevimento.arrivo">{{
            utente.repertorioRicevimento.arrivo
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Ingresso in sala:</label>
        <input
          type="text"
          :placeholder="utente.repertorioRicevimento.ingresso"
          v-model="ingresso"
          v-if="isEditingRicevimento"
        />
        <p v-else>
          <small v-if="utente.repertorioRicevimento.ingresso">{{
            utente.repertorioRicevimento.ingresso
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Taglio torta:</label>
        <input
          type="text"
          :placeholder="utente.repertorioRicevimento.torta"
          v-model="torta"
          v-if="isEditingRicevimento"
        />
        <p v-else>
          <small v-if="utente.repertorioRicevimento.torta">{{
            utente.repertorioRicevimento.torta
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Primo ballo:</label>
        <input
          type="text"
          :placeholder="utente.repertorioRicevimento.ballo"
          v-model="ballo"
          v-if="isEditingRicevimento"
        />
        <p v-else>
          <small v-if="utente.repertorioRicevimento.ballo">{{
            utente.repertorioRicevimento.ballo
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Altri momenti:</label>
        <input
          type="text"
          :placeholder="utente.repertorioRicevimento.altro"
          v-model="altro"
          v-if="isEditingRicevimento"
        />
        <p v-else>
          <small v-if="utente.repertorioRicevimento.altro">{{
            utente.repertorioRicevimento.altro
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Artisti che ci piacciono:</label>
        <input
          type="text"
          :placeholder="utente.repertorioRicevimento.artistiSi"
          v-model="artistiSi"
          v-if="isEditingRicevimento"
        />
        <p v-else>
          <small v-if="utente.repertorioRicevimento.artistiSi">{{
            utente.repertorioRicevimento.artistiSi
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <div class="tracklist__item">
        <label>Artisti che non ci piacciono:</label>
        <input
          type="text"
          :placeholder="utente.repertorioRicevimento.artistiNo"
          v-model="artistiNo"
          v-if="isEditingRicevimento"
        />
        <p v-else>
          <small v-if="utente.repertorioRicevimento.artistiNo">{{
            utente.repertorioRicevimento.artistiNo
          }}</small>
          <small v-else>-</small>
        </p>
      </div>

      <button class="btn btn--primary btn--small">Salva</button>
    </form>

    <div class="form__submit-ok" v-if="submitRicevimento">
      Modifiche salvate correttamente!
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import useDocument from '@/composables/useDocument'

export default {
  setup() {
    //utente
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    //updateDoc
    const { updateDoc } = useDocument('utenti', user.value.uid)

    //refs
    const isEditingRicevimento = ref(false)
    const submitRicevimento = ref(false)
    const arrivo = ref('')
    const ingresso = ref('')
    const torta = ref('')
    const ballo = ref('')
    const altro = ref('')
    const artistiSi = ref('')
    const artistiNo = ref('')

    //modifica repertorio religioso
    const editRicevimento = async () => {
      let res = null

      //arrivo
      if (arrivo.value) {
        res = await updateDoc({
          'repertorioRicevimento.arrivo': arrivo.value,
        })
      }

      //ingresso
      if (ingresso.value) {
        res = await updateDoc({
          'repertorioRicevimento.ingresso': ingresso.value,
        })
      }

      //torta
      if (torta.value) {
        res = await updateDoc({
          'repertorioRicevimento.torta': torta.value,
        })
      }

      //ballo
      if (ballo.value) {
        res = await updateDoc({
          'repertorioRicevimento.ballo': ballo.value,
        })
      }

      //altro
      if (altro.value) {
        res = await updateDoc({
          'repertorioRicevimento.altro': altro.value,
        })
      }

      //artistiSi
      if (artistiSi.value) {
        res = await updateDoc({
          'repertorioRicevimento.artistiSi': artistiSi.value,
        })
      }

      //artistiNo
      if (artistiNo.value) {
        res = await updateDoc({
          'repertorioRicevimento.artistiNo': artistiNo.value,
        })
      }

      isEditingRicevimento.value = !isEditingRicevimento.value
      submitRicevimento.value = true
      console.log('Modifiche salvate correttamente')
    }

    return {
      utente,
      error,
      submitRicevimento,
      isEditingRicevimento,
      arrivo,
      ingresso,
      torta,
      ballo,
      altro,
      artistiSi,
      artistiNo,
      editRicevimento,
    }
  },
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}
input {
  background-color: #e9e9e9;
  border: none;
  border-radius: 2px;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.6rem;
  outline: none;
  padding: 1rem;
}
button {
  margin-right: auto;
}
</style>
