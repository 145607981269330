<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />
    <Bg />
    <section class="pyramid" v-if="utente">
      <div v-for="fascia in fasce" :key="fascia">
        <div
          v-if="
            utente.opStart >= fascia.opStart.min &&
              utente.opStart <= fascia.opStart.max
          "
        >
          <article
            class="step step--new"
            :class="{
              'step--complete':
                utente.opTotal >=
                utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4 +
                  fascia.piramide.step5 +
                  fascia.piramide.step6 +
                  fascia.piramide.step7,
            }"
          >
            <div
              v-if="
                utente.opTotal >=
                  utente.opStart +
                    fascia.piramide.step1 +
                    fascia.piramide.step2 +
                    fascia.piramide.step3 +
                    fascia.piramide.step4 +
                    fascia.piramide.step5 +
                    fascia.piramide.step6 +
                    fascia.piramide.step7
              "
            >
              {{ updateStep8() }}
            </div>

            <div class="step__goal">
              {{
                (traguardo =
                  utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4 +
                  fascia.piramide.step5 +
                  fascia.piramide.step6 +
                  fascia.piramide.step7)
              }}
              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">Sconto 80€</div>
          </article>

          <article
            class="step step--new"
            :class="{
              'step--complete':
                utente.opTotal >=
                utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4 +
                  fascia.piramide.step5 +
                  fascia.piramide.step6,
            }"
          >
            <div
              v-if="
                utente.opTotal >=
                  utente.opStart +
                    fascia.piramide.step1 +
                    fascia.piramide.step2 +
                    fascia.piramide.step3 +
                    fascia.piramide.step4 +
                    fascia.piramide.step5 +
                    fascia.piramide.step6
              "
            >
              {{ updateStep7() }}
            </div>
            <div class="step__goal">
              {{
                (traguardo =
                  utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4 +
                  fascia.piramide.step5 +
                  fascia.piramide.step6)
              }}
              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">sconto 50€ <br />+ 60€ buono acquisto</div>
          </article>

          <article
            class="step step--new"
            :class="{
              'step--complete':
                utente.opTotal >=
                utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4 +
                  fascia.piramide.step5,
            }"
          >
            <div
              v-if="
                utente.opTotal >=
                  utente.opStart +
                    fascia.piramide.step1 +
                    fascia.piramide.step2 +
                    fascia.piramide.step3 +
                    fascia.piramide.step4 +
                    fascia.piramide.step5
              "
            >
              {{ updateStep6() }}
            </div>
            <div class="step__goal">
              {{
                (traguardo =
                  utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4 +
                  fascia.piramide.step5)
              }}
              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">
              sconto 25€<br />
              + 50€ buono acquisto
            </div>
          </article>

          <article
            class="step step--new"
            :class="{
              'step--complete':
                utente.opTotal >=
                utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4,
            }"
          >
            <div
              v-if="
                utente.opTotal >=
                  utente.opStart +
                    fascia.piramide.step1 +
                    fascia.piramide.step2 +
                    fascia.piramide.step3 +
                    fascia.piramide.step4
              "
            >
              {{ updateStep5() }}
            </div>

            <div class="step__goal">
              {{
                (traguardo =
                  utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3 +
                  fascia.piramide.step4)
              }}
              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">40€ Buono Acquisto</div>
          </article>

          <article
            class="step step--new"
            :class="{
              'step--complete':
                utente.opTotal >=
                utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3,
            }"
          >
            <div
              v-if="
                utente.opTotal >=
                  utente.opStart +
                    fascia.piramide.step1 +
                    fascia.piramide.step2 +
                    fascia.piramide.step3
              "
            >
              {{ updateStep4() }}
            </div>

            <div class="step__goal">
              {{
                (traguardo =
                  utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2 +
                  fascia.piramide.step3)
              }}

              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">30€ Buono Acquisto</div>
          </article>

          <article
            class="step step--new"
            :class="{
              'step--complete':
                utente.opTotal >=
                utente.opStart + fascia.piramide.step1 + fascia.piramide.step2,
            }"
          >
            <div
              v-if="
                utente.opTotal >=
                  utente.opStart + fascia.piramide.step1 + fascia.piramide.step2
              "
            >
              {{ updateStep3() }}
            </div>
            <div class="step__goal">
              {{
                (traguardo =
                  utente.opStart +
                  fascia.piramide.step1 +
                  fascia.piramide.step2)
              }}
              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">20€ Buono Acquisto</div>
          </article>

          <article
            class="step step--new"
            :class="{
              'step--complete':
                utente.opTotal >= utente.opStart + fascia.piramide.step1,
            }"
          >
            <div
              v-if="utente.opTotal >= utente.opStart + fascia.piramide.step1"
            >
              {{ updateStep2() }}
            </div>
            <div class="step__goal">
              {{ (traguardo = utente.opStart + fascia.piramide.step1) }}
              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">10€ Buono Acquisto</div>
          </article>

          <article class="step step--complete">
            <div class="step__goal">
              {{ utente.opStart }}
              <div class="step__ommepoint"><i class="bx bxs-heart"></i></div>
            </div>
            <div class="step__pride">saldo iniziale</div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'

import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  components: { Bg, Topbar },
  setup() {
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)
    const { documents: piramide } = getCollection('piramide')
    const { documents: fasce } = getCollection('fasce-prezzo')

    const { document: tutorial } = getDocument('tutorial', 'piramide-obiettivi')

    const updateStep2 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          buoni: firebase.firestore.FieldValue.arrayUnion(10),
        })
    }

    const updateStep3 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          buoni: firebase.firestore.FieldValue.arrayUnion(20),
        })
    }

    const updateStep4 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          buoni: firebase.firestore.FieldValue.arrayUnion(30),
        })
    }

    const updateStep5 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          buoni: firebase.firestore.FieldValue.arrayUnion(40),
        })
    }

    const updateStep6 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          buoni: firebase.firestore.FieldValue.arrayUnion(50),
          sconti: firebase.firestore.FieldValue.arrayUnion(25),
        })
    }

    const updateStep7 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          buoni: firebase.firestore.FieldValue.arrayUnion(60),
          sconti: firebase.firestore.FieldValue.arrayUnion(50),
        })
    }

    const updateStep8 = () => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          sconti: firebase.firestore.FieldValue.arrayUnion(80),
        })
    }

    return {
      utente,
      error,
      piramide,
      fasce,
      updateStep2,
      updateStep3,
      updateStep4,
      updateStep5,
      updateStep6,
      updateStep7,
      updateStep8,
      tutorial,
    }
  },
}
</script>

<style></style>
