<template>
  <div>
    <Topbar
      v-if="tutorial"
      :title="tutorial.title"
      :content="tutorial.content"
    />

    <Bg />
    <!-- RANKING -->
    <div class="ranking" v-if="utente">
      <div class="ranking__heading" v-if="utente">
        <h1 class="ranking__name">
          <span>{{ utente.torneo }}</span>
        </h1>
        <div class="ranking__year">Classifica 2021</div>
        <div class="ranking__deadline">
          Termine torneo:
          <span v-if="utente.torneo === torneo1">30 aprile 2021</span>
          <span v-if="utente.torneo === torneo2">31 luglio 2021</span>
        </div>
      </div>

      <Torneo1 v-if="utente.torneo === 'sogno d\'estate'" />
      <Torneo2 v-else />

      <div class="ranking__btn">
        <router-link :to="{ name: 'CoffersShop' }" class="btn btn--primary"
          >Guadagna ommepoint <span class="ommepoints"></span
        ></router-link>
      </div>

      <!-- <div class="ranking__heading">
      <h1 class="ranking__name">
        <span
          v-if="
            utente.dataMatrimonio.toDate().getMonth() + 1 == 5 ||
              utente.dataMatrimonio.toDate().getMonth() + 1 == 6 ||
              utente.dataMatrimonio.toDate().getMonth() + 1 == 7
          "
          >Sogno d'estate</span
        >
        <span v-else>Vento d'autunno</span>
      </h1>
      <div class="ranking__year">Classifica 2021</div>
      <div class="ranking__deadline">
        Termine torneo:
        <span
          v-if="
            utente.dataMatrimonio.toDate().getMonth() + 1 == 5 ||
              utente.dataMatrimonio.toDate().getMonth() + 1 == 6 ||
              utente.dataMatrimonio.toDate().getMonth() + 1 == 7
          "
          >30 aprile 2021</span
        >
        <span v-else>31 luglio 2021</span>
      </div>
    </div>

    <div class="ranking__table">
      <div class="ranking__head">
        <div>Coppia</div>
        <div>Sconto</div>
        <div>
          Buono<br />
          acquisto
        </div>
        <div>
          Totale<br />
          ommepoint
        </div>
      </div>

      <div class="ranking__body" v-if="classifica">
        <div
          v-for="posizione in classifica"
          :key="posizione"
          class="ranking__row"
        >
          <div class="ranking__position">
            {{ classifica.indexOf(posizione) + 1 }}
          </div>
          <div class="ranking__couple">
            <img class="ranking__couple--thumb" :src="posizione.fotoProfilo" />

            <h3 class="ranking__couple--name">
              {{ posizione.nomeUtente }}
            </h3>
          </div>
          <div class="ranking__discount">
            <div v-if="classifica.indexOf(posizione) + 1 === 1">300€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 2">200€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 3">150€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 4">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 5">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 6">70€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 7">70€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 8">50€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 9">50€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 10">50€</div>
          </div>
          <div class="ranking__tag">
            <div v-if="classifica.indexOf(posizione) + 1 === 1">200€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 2">200€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 3">180€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 4">180€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 5">160€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 6">16€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 7">140€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 8">140€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 9">120€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 10">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 11">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 12">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 13">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 14">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 15">100€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 16">50€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 17">50€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 18">50€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 19">50€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 20">50€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 21">30€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 22">30€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 23">30€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 24">30€</div>
            <div v-if="classifica.indexOf(posizione) + 1 === 25">30€</div>
          </div>
          <div class="ranking__ommepoint">
            <div class="ommepoints">{{ posizione.opTotal }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ranking__btn">
      <router-link :to="{ name: 'Coffers' }" class="btn btn--primary"
        >Guadagna ommepoint <span class="ommepoints"></span
      ></router-link>
    </div> -->
    </div>
  </div>
</template>

<script>
import Torneo1 from '@/components/Torneo1.vue'
import Torneo2 from '@/components/Torneo2.vue'
import Bg from '@/components/Bg.vue'
import Topbar from '@/components/Topbar.vue'

import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'

export default {
  components: { Bg, Torneo1, Torneo2, Topbar },
  setup() {
    const torneo1 = "sogno d'estate"
    const torneo2 = "vento d'autunno"

    const { documents: utenti } = getCollection('utenti')
    const { documents: premi } = getCollection('premi')

    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)

    const { err, document: tutorial } = getDocument(
      'tutorial',
      'classifica-generale'
    )

    const { documents: classifica } = getCollection(
      'utenti',
      ['dataMatrimonio.toDate().getMonth() + 1', 'in', [5, 6, 7]],
      'opStart',
      'desc'
    )

    const utenteId = user.value.uid

    return {
      utenti,
      utente,
      error,
      classifica,
      utenteId,
      premi,
      torneo1,
      torneo2,
      tutorial,
    }
  },
}
</script>
