<template>
  <nav class="nav-profile">
    <router-link class="nav-profile__card" :to="{ name: 'Profile' }">
      <span class="day">
        <span>{{ date.getDate() }}</span>
        <img src="@/assets/images/icons/icon-calendar-heart.svg" />
      </span>

      <h3>
        Il vostro<br />
        matrimonio
      </h3>
    </router-link>

    <router-link class="nav-profile__card" :to="{ name: 'Services' }">
      <img src="@/assets/images/icons/icon-check-list.svg" />

      <h3>
        Servizi <br />
        e sconti
      </h3>
    </router-link>

    <router-link class="nav-profile__card" :to="{ name: 'Playlist' }">
      <img src="@/assets/images/icons/icon-playlist.svg" />

      <h3>
        Repertorio <br />
        musicale
      </h3>
    </router-link>

    <router-link class="nav-profile__card" :to="{ name: 'Instagallery' }">
      <img src="@/assets/images/icons/icon-album.svg" />

      <h3>
        Insta <br />
        Gallery
      </h3>
    </router-link>
  </nav>
</template>

<script>
import getUser from "@/composables/getUser"
import getDocument from "@/composables/getDocument"
import { ref } from "vue"

export default {
  props: ["date"],
  setup(props) {
    const { user } = getUser()
    const { error, document: utente } = getDocument("utenti", user.value.uid)

    const date = ref(props.date.toDate())

    return {
      date,
    }
  },
}
</script>

<style scoped>
.day {
  color: hsl(340, 96%, 46%);
  font-family: "Rift", serif;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
}

.day span {
  left: 50%;
  position: absolute;
  top: 0.9rem;
  transform: translateX(-50%);
}
</style>
