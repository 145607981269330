<template>
  <header class="topbar">
    <div class="topbar__left">
      <div @click="back" v-if="pageRoute !== 'Account'">
        <i class="bx bx-arrow-back"></i>
      </div>
    </div>
    <div class="topbar__center" v-if="pageRoute !== 'SingleMission'">
      <h1
        class="heading"
        v-html="pageTitle"
        v-if="pageRoute !== 'Account'"
      ></h1>
    </div>
    <div class="topbar__right">
      <button class="badge badge--primary" @click="toggleModal">?</button>
    </div>
  </header>

  <div v-if="showModal" class="modal modal--tutorial">
    <div class="modal__overlay" @click.self="showModal = false">
      <div class="modal__card">
        <button class="modal__close" @click="showModal = false">
          <i class="bx bx-x"></i>
        </button>

        <div class="modal__body">
          <h3 class="modal__title">{{ title }}</h3>

          <div class="modal__paragraph" v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'

//import Modal from '@/components/Modal.vue'

export default {
  props: ['title', 'content'],
  setup(props) {
    const router = useRoute()
    const pageRoute = computed(() => router.name)
    const pageTitle = computed(() => router.meta.title)

    const showModal = ref(false)

    const title = ref(props.title)
    const content = ref(props.content)

    const toggleModal = () => {
      showModal.value = !showModal.value
    }

    return {
      pageRoute,
      pageTitle,
      showModal,
      toggleModal,
      title,
      content,
    }
  },
  methods: {
    back() {
      return this.$router.go(-1)
    },
  },
}
</script>

<style></style>
