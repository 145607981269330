import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Account from '../views/user/Account.vue'
import Profile from '../views/user/Profile.vue'
import Settings from '../views/user/Settings.vue'
import Services from '../views/Services.vue'
import Piggybank from '../views/Piggybank.vue'
import Shop from '../views/Shop.vue'
import Ranking from '../views/Ranking.vue'
import Missions from '../views/missions/Missions.vue'
import SingleMission from '../views/missions/SingleMission.vue'
import CardSocial from '../views/missions/CardSocial.vue'
import CardAction from '../views/missions/CardAction.vue'
import CardShopping from '../views/missions/CardShopping.vue'
import PyramidGoals from '../views/PyramidGoals.vue'
import GiftCards from '../views/GiftCards.vue'
import Playlist from '../views/playlist/Playlist.vue'
import Instagallery from '../views/Instagallery.vue'
import PuzzleBonus from '../views/PuzzleBonus.vue'
import Coffers from '../views/Coffers.vue'
import CoffersBronze from '../views/coffers/CoffersBronze.vue'
import CoffersSilver from '../views/coffers/CoffersSilver.vue'
import CoffersGold from '../views/coffers/CoffersGold.vue'
import CoffersShop from '../views/CoffersShop.vue'
import Conditions from '../views/user/Conditions.vue'
import NotFound from '../views/NotFound.vue'
import Test from '../views/Test.vue'
import SendGift from '../views/SendGift.vue'
import SendMediaPics from '../views/SendMediaPics.vue'
import CreateUser from '../views/CreateUser.vue'

// route guard
import { projectAuth } from '../firebase/config'

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Account',
    component: Account,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'test <span>Account</span>',
    },
  },
  {
    path: '/profilo',
    name: 'Profile',
    component: Profile,
    beforeEnter: requireAuth,
  },
  {
    path: '/impostazioni',
    name: 'Settings',
    component: Settings,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: '<span>Impostazioni</span>',
    },
  },
  {
    path: '/servizi',
    name: 'Services',
    component: Services,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'I vostri <span>Servizi</span><br> OMME Music',
    },
  },
  {
    path: '/salvadanaio',
    name: 'Piggybank',
    component: Piggybank,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Salvadanaio<br> <span>ommepoint</span><br>',
    },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'OMME <span>Shop</span>',
    },
  },
  {
    path: '/classifica-generale',
    name: 'Ranking',
    component: Ranking,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: '',
    },
  },
  {
    path: '/missioni',
    name: 'Missions',
    component: Missions,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: '<span>Missioni</span>',
    },
  },
  {
    path: '/missioni/missione-:id',
    name: 'SingleMission',
    component: SingleMission,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      auth: true,
      title: 'Missione/:id',
    },
  },
  {
    path: '/missione-:id/obiettivi-social',
    name: 'CardSocial',
    component: CardSocial,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      auth: true,
      title: 'Obiettivi <span>Social</span>',
    },
  },
  {
    path: '/missione-:id/obiettivi-action',
    name: 'CardAction',
    component: CardAction,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      auth: true,
      title: 'Obiettivi <span>Action</span>',
    },
  },
  {
    path: '/missione-:id/obiettivi-shopping',
    name: 'CardShopping',
    component: CardShopping,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      auth: true,
      title: 'Obiettivi <span>Shopping</span>',
    },
  },
  {
    path: '/piramide-obiettivi',
    name: 'PyramidGoals',
    component: PyramidGoals,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Piramide degli <span>obiettivi</span>',
    },
  },
  {
    path: '/gift-cards',
    name: 'GiftCards',
    component: GiftCards,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Gift <span>Card</span>',
    },
  },
  {
    path: '/repertorio',
    name: 'Playlist',
    component: Playlist,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: '<span>Repertorio</span><br> musicale',
    },
  },
  {
    path: '/instagallery',
    name: 'Instagallery',
    component: Instagallery,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Insta<span>gallery</span>',
    },
  },
  {
    path: '/puzzle-bonus',
    name: 'PuzzleBonus',
    component: PuzzleBonus,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Puzzle dei <span>Bonus</span>',
    },
  },
  {
    path: '/forzieri',
    name: 'Coffers',
    component: Coffers,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Forzieri <span>Ommepoint</span>',
    },
  },
  {
    path: '/forzieri-bronzo',
    name: 'CoffersBronze',
    component: CoffersBronze,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Forzieri <span>Bronzo</span>',
    },
  },
  {
    path: '/forzieri-argento',
    name: 'CoffersSilver',
    component: CoffersSilver,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Forzieri <span>Argento</span>',
    },
  },
  {
    path: '/forzieri-oro',
    name: 'CoffersGold',
    component: CoffersGold,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Forzieri <span>Oro</span>',
    },
  },

  {
    path: '/shop-forzieri',
    name: 'CoffersShop',
    component: CoffersShop,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Shop <span>Forzieri</span>',
    },
  },
  {
    path: '/termini-e-condizioni',
    name: 'Conditions',
    component: Conditions,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Termini e <span>Condizioni</span>',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/crea-profilo',
    name: 'Test',
    component: Test,
    beforeEnter: requireAuth,
  },

  {
    path: '/invia-gift-card',
    name: 'SendGift',
    component: SendGift,
    meta: {
      auth: true,
      title: 'Gift Card e <span>OMME Music</span>',
    },
  },

  {
    path: '/condividi-mediapics',
    name: 'SendMediaPics',
    component: SendMediaPics,
  },

  {
    path: '/crea-utente',
    name: 'CreateUser',
    component: CreateUser,
    beforeEnter: requireAuth,
    meta: {
      auth: true,
      title: 'Crea e <span>Utente</span>',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  document.title = 'Area Sposi OMME Music'
  next()
})

export default router
