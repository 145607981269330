<template>
  <div class="modal">
    <div class="modal__overlay" @click.self="closeModal">
      <div class="modal__card">
        <button class="modal__close" @click="closeModal">
          <i class="bx bx-x"></i>
        </button>

        <div class="modal__content">
          <slot name="tutorial"></slot>
          <slot name="gift"></slot>
          <slot name="goal"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'

export default {
  props: ['op'],
  setup(props) {
    const { emit } = getCurrentInstance()
    const closeModal = () => {
      emit('close', false)
    }

    const op = props.op

    return { closeModal, op }
  },
}
</script>

<style></style>
