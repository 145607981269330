<template>
  <div
    class="goal goal--social"
    :class="{ gold: gold, silver: silver }"
    @click="displayModal(id)"
  >
    <!-- GOAL COMPLETE -->
    <div v-for="usrGoal in usrGoals" :key="usrGoal">
      <div v-if="usrGoal.id === id">
        <div class="goal--complete" v-if="usrGoal.raggiunto">
          <div class="goal__icon">
            <img
              v-if="social === 'Facebook'"
              src="@/assets/images/icons/icon-fb.svg"
            />

            <img v-else src="@/assets/images/icons/icon-instagram.svg" />
          </div>

          <div class="goal__content">
            <h3 class="goal__title">{{ name }}</h3>
            <p class="goal__description">{{ description }}</p>
          </div>

          <i class="bx bx-check"></i>
        </div>
      </div>
    </div>

    <div class="goal__left">
      <div class="goal__icon">
        <img
          v-if="social === 'Facebook'"
          src="@/assets/images/icons/icon-fb.svg"
        />

        <img v-else src="@/assets/images/icons/icon-instagram.svg" />
      </div>

      <div class="goal__content">
        <h3 class="goal__title">{{ name }}</h3>
        <p class="goal__description">{{ description }}</p>
      </div>

      <div class="goal__ommepoints">
        <div v-for="fascia in fasce" :key="fascia">
          <div
            v-if="
              opStart >= fascia.opStart.min && opStart <= fascia.opStart.max
            "
          >
            <span class="ommepoints">{{ (op = fascia.social) }}</span>

            <span v-if="extra" class="goal__extra">
              <span class="ommepoints">+{{ extra }}</span> extra</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="goal__right">
      <i class="bx bx-right-arrow-alt"></i>
    </div>
  </div>

  <div class="modal" v-if="showModal === true">
    <div class="modal__overlay" @click.self="showModal = false">
      <div class="modal__card modal__card--social">
        <!--<button class="modal__close" @click="showModal = false">
          <i class="bx bx-x"></i>
        </button>-->

        <div class="modal__body">
          <h3 class="modal__title">
            {{ name }}
          </h3>
          <div class="modal__subtitle">
            Carta action
          </div>

          <p class="modal__paragraph">
            {{ paragraph }}
          </p>

          <!-- <div class="modal__price">
                100€
              </div> -->

          <!--<p class="modal__paragraph">
                Regalami a una coppia di amici che sta per sposarsi e portali in
                OMME MUSIC. Per te
              </p> -->

          <div class="modal__ommepoints modal__ommepoints--big">
            <span class="ommepoints">+{{ op }}</span>
          </div>

          <!--<div class="modal__ommepoints modal__ommepoints--big">
                <span class="ommepoints">+{{ omme }}</span>
              </div>-->

          <div
            class="modal__extra"
            :class="{
              'modal__extra--gold': gold,
              'modal__extra--silver': silver,
            }"
            v-if="extra"
          >
            <h4>
              Action <span v-if="gold">gold</span
              ><span v-if="silver">silver</span>!
            </h4>

            <p>Se conquisti questa carta guadagnerai un bonus di</p>

            <div>
              <span class="ommepoints"> +{{ extra }}</span>
              extra
            </div>
          </div>

          <div class="modal__btn">
            <a
              :href="azione"
              class="btn btn--primary"
              @click="goalCTA(missionId, op, id, extra)"
              target="_blank"
            >
              {{ btn }} <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="showModalComplete === true">
    <div class="modal__overlay" @click.self="showModalComplete = false">
      <div class="modal__card modal__card--social">
        <!--<button class="modal__close" @click="showModalComplete = false">
          <i class="bx bx-x"></i>
        </button>-->

        <div class="modal__body">
          <h3 class="modal__title">
            {{ name }}
          </h3>
          <div class="modal__subtitle">
            Carta social
          </div>

          <p class="modal__complete">
            <h2>Obiettivo raggiunto!</h2>
          </p>

          <!-- <div class="modal__price">
                100€
              </div> -->

          <!--<p class="modal__paragraph">
                Regalami a una coppia di amici che sta per sposarsi e portali in
                OMME MUSIC. Per te
              </p> -->

          <div class="modal__ommepoints modal__ommepoints--big">
            <span class="ommepoints">+{{ op }}</span>
          </div>

          <!--<div class="modal__ommepoints modal__ommepoints--big">
                <span class="ommepoints">+{{ omme }}</span>
              </div>-->

          <div
            class="modal__extra"
            :class="{
              'modal__extra--gold': gold,
              'modal__extra--silver': silver,
            }"
            v-if="extra"
          >
            <h4>
              Action <span v-if="gold">gold</span
              ><span v-if="silver">silver</span>!
            </h4>

            <div>
              <span class="ommepoints"> +{{ extra }}</span>
              extra
            </div>
          </div>

          <div class="modal__btn">
            <router-link :to="{name: 'Missions'}"
              class="btn btn--primary"
            >
              Continua la missione <i class="bx bx-right-arrow-alt"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//components
import { ref } from 'vue'

//composables
import getCollection from '@/composables/getCollection'
import getDocument from '@/composables/getDocument'
import getSubcollection from '@/composables/getSubcollection'
import getUser from '@/composables/getUser'
import useDocument from '@/composables/useDocument'

//firebase
import firebase from 'firebase/app'
import { projectFirestore } from '@/firebase/config'

export default {
  props: [
    'azione',
    'btn',
    'description',
    'extra',
    'id',
    'gold',
    'missionId',
    'name',
    'opStart',
    'paragraph',
    'silver',
    'social',
  ],

  setup(props) {
    //user
    const { user } = getUser()
    const { error, document: utente } = getDocument('utenti', user.value.uid)
    const { updateDoc } = useDocument('utenti', user.value.uid)

    //fasce
    const { documents: fasce } = getCollection('fasce-prezzo')

    //obiettivi
    const { documents: obiettiviSocial } = getCollection('obiettivi', [
      'categoria',
      '==',
      'social',
    ])

    //obiettivi utente
    const { documents: usrGoals } = getSubcollection(
      'utenti',
      user.value.uid,
      'obiettivi',
      '',
      'raggiunto'
    )

    //props
    const azione = ref(props.azione)
    const btn = ref(props.btn)
    const description = ref(props.description)
    const gold = ref(props.gold)
    const id = ref(props.id)
    const extra = ref(props.extra)
    const missionId = ref(props.missionId)
    const name = ref(props.name)
    const opStart = ref(props.opStart)
    const paragraph = ref(props.paragraph)
    const silver = ref(props.silver)
    const social = ref(props.social)

    //goalCTA
    const goalCTA = async (missionId, op, id, extra) => {
      //update cards
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('missioni')
        .doc(missionId)
        .update({
          action: 'attiva',
          social: 'conquistata',
          carteConquistate: firebase.firestore.FieldValue.increment(1),
        })

      //update opTotal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .update({
          opTotal: firebase.firestore.FieldValue.increment(op),
          obiettiviSocialStandard: firebase.firestore.FieldValue.increment(1),
        })

      if (extra) {
        //update opTotal if extra
        projectFirestore
          .collection('utenti')
          .doc(user.value.uid)
          .update({
            opTotal: firebase.firestore.FieldValue.increment(extra),
          })
      }

      //update goal
      projectFirestore
        .collection('utenti')
        .doc(user.value.uid)
        .collection('obiettivi')
        .doc(id)
        .update({
          raggiunto: true,
        })

      showModal.value = false
      showModalComplete.value = true

      console.log('aggiornato!')
    }

    //modal
    const modal = ref()
    const showModal = ref(false)
    const showModalComplete = ref(false)
    const displayModal = (goal) => {
      modal.value = goal
      showModal.value = true
    }

    return {
      azione,
      btn,
      description,
      displayModal,
      fasce,
      id,
      extra,
      goalCTA,
      gold,
      missionId,
      modal,
      name,
      obiettiviSocial,
      opStart,
      paragraph,
      silver,
      showModal,
      showModalComplete,
      social,
      usrGoals,
      utente,
    }
  },
}
</script>

<style></style>
